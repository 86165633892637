<template>
  <div>
    <!-- <pre>ewus: {{ ewus }}</pre>
    <pre>ewusGuid: {{ ewusGuid }}</pre>
    <pre>trigger: {{ trigger }}</pre>
    <pre>patient: {{ patient }}</pre>
    <pre>me: {{ me }}</pre> -->
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import { Action } from '@/store/config_actions';
import { Mutation } from '@/store/config_mutations';

export default {
  name: 'TestDispatcher',
  mixins: [CommonMixins],

  props: {
  },

  data: function () {
    return {
      interval: null,
      intervalReservation: null,
    }
  },

  mounted() {
    this.interval = setInterval(() => this.update(), 5000);
    this.intervalReservation = setInterval(() => this.updateReservation(), 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval)
    clearInterval(this.intervalReservation)
  },

  computed: {

    ewus() { return this.$store.state.ewus.ewus },
    ewusGuid() { return this.$store.state.ewus.ewusGuid },

    ewusReservation() { return this.$store.state.ewus.ewusReservation },
    ewusReservationGuid() { return this.$store.state.ewus.ewusReservationGuid },

    patient() { return this.$store.state.registration.patient },

    me() { return this.$store.state.employee.me },

    trigger() { return this.$store.state.ewus.trigger }

  },

  methods: {
    update() {
      if (this.patient && this.ewusGuid) {
        if (this.patient.pesel != null && this.patient.pesel != '') {
          this.$store
            .dispatch(Action.EWUS_CHECK_RESPONSE, this.ewusGuid)
            .then((response) => {
              if (response.ewusStatus != 6) {
                this.$store.commit(Mutation.EWUS_SET_EWUS_GUID, null)
                this.$store.commit(Mutation.EWUS_SET_EWUS, response)
                this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING, false)
              } else {
                this.$store.commit(Mutation.EWUS_TRIGGER_UP)
              }
            })
            .catch(error => {
              this.apiProblem(error);
            });
        }
      }
    },

    updateReservation() {
      if (this.ewusReservationGuid) {
          this.$store
            .dispatch(Action.EWUS_CHECK_RESPONSE, this.ewusReservationGuid)
            .then((response) => {
              if (response.ewusStatus != 6) {
                this.$store.commit(Mutation.EWUS_SET_EWUS_GUID_RESERVATION, null)
                this.$store.commit(Mutation.EWUS_SET_EWUS_RESERVATION, response)
                this.$store.commit(Mutation.EWUS_SET_EWUS_PENDING_RESERVATION, false)
              } else {
                this.$store.commit(Mutation.EWUS_TRIGGER_UP_RESERVATION)
              }
            })
            .catch(error => {
              this.apiProblem(error);
            });
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
