<template>
  <div>
    <b-modal
      v-model="modalActive"
      has-modal-card
      aria-role="dialog"
      aria-modal
      class="p-5"
      trap-focus
      scroll="keep"
      :destroy-on-hide="false"
      @after-leave="resetP12"
      @after-enter="focus"
    >
      <template>
        <div
          class="modal-card"
          style="overflow: visible; width: auto"
        >
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-light">
              <b-icon
                icon="lock-alert"
                class="mr-3"
              ></b-icon>Walidacja hasła
              P12
            </p>
          </header>
          <section class="modal-card-body p-5">
            <b-field
              label="Hasło P12"
              label-position="on-border"
            >
              <b-input
                ref="codeField"
                name="p12Password"
                id="p12Password"
                v-model="code"
                type="password"
                password-reveal
                @keydown.native.enter="checkPass"
                aria-autocomplete="none"
                autocomplete="off"
                aria-placeholder="Wpisz hasło"
              ></b-input>
            </b-field>
            <p
              class="mt-5"
              v-if="permits.predefinedP12Password && !permits.testP12PassHidden"
            >
              <b-tag
                type="is-danger"
                class="mr-2 w-100"
              >Admin: WFV6RZqAt3FU</b-tag>
            </p>
          </section>
          <footer class="modal-card-foot">
            <b-button
              icon-left="close"
              @click="modalActive = false"
            >Anuluj</b-button>
            <b-button
              :loading="isLoading"
              @click="checkPass"
              :disabled="code.length < 1"
              type="is-secondary"
              icon-left="lock-open-variant"
              expanded
            >Weryfikuj</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'
import { Mutation } from '../../store/config_mutations'

export default {
  name: 'P12DocPassword',
  mixins: [CommonMixins],

  components: {
  },

  data: function () {
    return {
      modalActive: false,
      isLoading: false,
      code: ''
    }
  },

  watch: {
    pending(val) {
      if (val) {
        this.modalActive = true
      }
    }
  },

  computed: {
    pending() { return this.$store.state.p12.pending },
    me() { return this.$store.state.employee.me },
    permits() { return this.$store.state.employee.permits },
    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },
    facilities() { return this.$store.state.clinic.clinics ?? [] },
    employeeIkz() {
      return this.$store.state.employee.ikz
    },
  },

  mounted() {

    if (this.permits && this.permits.predefinedP12Password) {
      this.code = 'WFV6RZqAt3FU'
    }

    this.modalActive = this.pending
  },

  methods: {
    focus() {
      if (this.$refs.codeField) {
        this.$refs.codeField.focus()
      }
    },

    resetP12() {
      this.$store.commit(Mutation.P12_CODE_REQUEST, false)
    },

    checkPassX() {
      if (this.code.length > 0) {
        this.$store.commit(Mutation.P12_CODE_ADD, this.code)
        this.modalActive = false
      }
    },

    checkPass() {
      this.isLoading = true

      let payload = {
        password: this.code,
        workerGuid: this.me.id.toString()
      }

      this.code = ''

      this.$store
        .dispatch(Action.P12_VERIFY_CODE, payload)
        .then((response) => {
          if (response.p12ResultEnum == 0) {
            this.modalActive = false
            if (this.amIDoctor) {
              this.$store.dispatch(Action.ZUS_CHECK_PASSWORD, payload)
                .then(() => {
                  //
                })
                .catch(error => {
                  this.apiProblem(error)
                })
            }
            //ADD IKZ - WALIDACJA HASŁA P12
            this.addIKZP12Verified()
          } else {
            this.dangerSnackbar(this.getResultDescription(response.p12ResultEnum) + ' (kod błędu: ' + response.p12ResultEnum + ')')
          }

          this.isLoading = false

        }).catch(error => {
          this.isLoading = false
          this.apiProblem(error)
        })
    },

    addIKZP12Verified() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.employeeIkz.find(x => x.internalId == 635)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }


        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    getResultDescription(num) {
      switch (num) {
        case 1:
          return "Nieprawidłowy obiekt"
        case 2:
          return "Brak pracownika"
        case 3:
          return "Brak hasła"
        case 4:
          return "Nie znaleziono pracownika"
        case 5:
          return "Proces w toku"
        case 6:
          return "Nieprawidłowe hasło"
        case 7:
          return "Nie rozpoznano pracownika"
        default:
          return "Nieokreślony"
      }
    },

    amIDoctor() {
      if (this.permits) {
        if (this.permits.officePoz ||
          this.permits.officeLaryngologist ||
          this.permits.officeDermatologist ||
          this.permits.officePediatrician ||
          this.permits.officePulmonologist ||
          this.permits.officePsychiatrist ||
          this.permits.officeCardiologist ||
          this.permits.officeEndocrinologist ||
          this.permits.officeGynecologist ||
          this.permits.officeNeurologist ||
          this.permits.officeRheumatologist ||
          this.permits.officeAllergist ||
          this.permits.officeSurgeonGeneral ||
          this.permits.officeSurgeonOncological ||
          this.permits.officeSurgeonTrauma ||
          this.permits.officeDiabetologist ||
          this.permits.officeUrologist ||
          this.permits.officeOrthopaedist ||
          this.permits.officeInternalDiseases ||
          this.permits.officeNephrologist ||
          this.permits.officeOncologist) {
          return true
        } else return false
      } else return false
    }
  }
}
</script>

<style scoped lang="scss">
</style>