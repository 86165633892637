<template>
  <div>
    <!-- <div class="mb-4">
      <b-field grouped v-for="item in icons" :key="item.index">
        <b-button :icon-left="item" icon-pack="szok" size="is-large" class="ml-1">Test ikon SZOK</b-button>
        <b-button :icon-left="item" icon-pack="szok" size="is-medium" outlined type="is-danger" class="ml-1">Test ikon SZOK</b-button>
        <b-button :icon-left="item" icon-pack="szok" type="is-secondary" disabled class="ml-1">Test ikon SZOK</b-button>

        <b-button icon-left="icon-pin-ticket-visit" icon-pack="szok" size="is-small" class="ml-1" type="is-primary">Test ikon SZOK</b-button>
        <b-icon icon="icon-pin-ticket-visit" pack="szok" size="is-large" type="is-danger" class="ml-1"></b-icon>
        
        <b-icon :icon="item" pack="szok" size="is-medium" type="is-secondary" class="ml-1"></b-icon>
        <b-icon :icon="item" pack="szok" class="ml-1"></b-icon>
        <b-icon :icon="item" pack="szok" size="is-small" class="ml-1" type="is-violet"></b-icon>
      </b-field>
    </div>-->

    <div v-for="(group, index) in logs" :key="group.index">
      <div v-if="index < 5">
        <p class="title has-text-primary is-6 mb-3">
          <b-icon icon="calendar-month" size="is-small" class="mr-2"></b-icon>
          {{group.date}}
        </p>
        <div class="content mb-3">
          <ul v-for="item in group.groups" style="font-size:0.95rem;" :key="item.index" class="mv-0">
            <li v-for="entry in item.items" :key="entry.index" class="mv-0">
              <span v-html="entry" class="mr-1"></span>
              <b-tag rounded style="height:1.1rem;" :style="`color: ${item.color}`">
                <span class="has-text-weight-bold">{{item.name}}</span>
              </b-tag>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <b-button
      icon-left="icon-pin-ticket-visit"
      icon-pack="szok"
      size="is-small"
      type="is-primary"
    >Test ikon SZOK</b-button>

    <b-icon 
      icon="icon-pin-ticket-visit" 
      pack="szok" 
      size="is-large" 
      type="is-danger">
    </b-icon> -->

    <b-collapse :open="false" aria-id="contentIdForA11y1">
      <template #trigger>
        <b-button
          label="Więcej"
          size="is-small"
          type="is-primary"
          class="mb-5"
          aria-controls="contentIdForA11y1"
        />
      </template>
      <div v-for="(group, index) in logs" :key="group.index">
        <div v-if="index > 4">
          <p class="subtitle has-text-primary is-6 mb-3">
            <b-icon icon="calendar-month" size="is-small" class="mr-2"></b-icon>
            {{group.date}}
          </p>
          <div class="content mb-5">
            <ul v-for="item in group.groups" :key="item.index" class="mv-0">
              <li v-for="entry in item.items" :key="entry.index" class="mv-0">
                <span v-html="entry" class="mr-1"></span>
                <b-tag rounded :style="`color: ${item.color}`">
                  <span class="has-text-weight-bold">{{item.name}}</span>
                </b-tag>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import moment from 'moment'

import lk from './LK.json'
import mc from './MC.json'
//import mp from './MP.json'
import ms from './MS.json'
import sb from './SB.json'
import tk from './TK.json'
//import jg from './JG.json'
import pb from './PB.json'

const lkColor = 'orange'
const mcColor = 'green'
//const mpColor = 'pink'
const msColor = 'teal'
const sbColor = '#32105C'
const tkColor = 'purple'
//const jgColor = 'blue'
const pbColor = '#CD99E3'

export default {
  name: 'ChangelogPanel',

  data: function () {
    return {
      logs: [],
      icons: [
        "icon-pin-ticket-visit",
        "icon-assign-patient-to-block",
        "icon-event-blocks",
        "icon-patient-qualification-on-path",
        "icon-path-cycles",
        "icon-patient-programs",
        "icon-dilo",
        "icon-medical-certificate",
        "icon-medical-referrals",
        "icon-medical-referrals-alt",
        "icon-sick-leave",
        "icon-summary",
        "icon-to-sign"
      ]
    }
  },

  mounted() {
    this.wrapLogs()
  },

  methods: {
    wrapLogs() {
      this.logs = []
      let allLogs = []

      lk.forEach(item => {
        allLogs.push({
          name: "LK",
          color: lkColor,
          date: moment(item.date),
          items: item.items
        })
      });

      // mp.forEach(item => {
      //   allLogs.push({
      //     name: "MP",
      //     color: mpColor,
      //     date: moment(item.date),
      //     items: item.items
      //   })
      // });

      ms.forEach(item => {
        allLogs.push({
          name: "MS",
          color: msColor,
          date: moment(item.date),
          items: item.items
        })
      });

      sb.forEach(item => {
        allLogs.push({
          name: "SB",
          color: sbColor,
          date: moment(item.date),
          items: item.items
        })
      });

      tk.forEach(item => {
        allLogs.push({
          name: "TK",
          color: tkColor,
          date: moment(item.date),
          items: item.items
        })
      });

      // jg.forEach(item => {
      //   allLogs.push({
      //     name: "JG",
      //     color: jgColor,
      //     date: moment(item.date),
      //     items: item.items
      //   })
      // });

      pb.forEach(item => {
        allLogs.push({
          name: "PB",
          color: pbColor,
          date: moment(item.date),
          items: item.items
        })
      });

      mc.forEach(item => {
        allLogs.push({
          name: "MC",
          color: mcColor,
          date: moment(item.date),
          items: item.items
        })
      });




      let borderDate = moment("2024-01-01")
      let startDate = moment(moment.utc().add(1, 'hours').format("YYYY-MM-DD"))

      while (startDate.isAfter(borderDate)) {

        let dayLogs = allLogs.filter(d => d.date.isSame(startDate))
        if (dayLogs.length > 0) {
          this.logs.push({ date: startDate.format("YYYY-MM-DD"), groups: dayLogs })
        }

        startDate = startDate.add(-1, 'days')
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
