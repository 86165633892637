<template>
  <div class="fluid" style="min-height: 100vh">
    <b-loading :is-full-page="true" v-model="loggingOut" :can-cancel="true"></b-loading>

    <b-loading is-full-page :active="isUserAuthorized && !initialDataLoaded">
      <div style="margin-bottom:8rem;">
        <b-tooltip multilined type="is-primary">
          <template v-slot:content>
            <div class="content has-text-left">
              Pobieramy aktualne dane niezbędne do działania systemu, w tym:
              <ul class="mt-1 mb-1" style="line-height:0.9rem;">
                <li>dane gabinetów</li>
                <li>listy pracowników</li>
                <li>podjednostki medyczne</li>
                <li>usługi NFZ</li>
              </ul>W przypadku wolnego łącza proces ten może trwać nawet kilkanaście sekund.
            </div>
          </template>
          <div class="has-text-centered" style="padding: 1rem;">
            <p class="mb-4 has-text-szokgrey">
              <b-icon size="is-medium" class="mr-2" icon="hiking"></b-icon>
              <b-icon size="is-medium" class="mr-2" icon="forest"></b-icon>
              <b-icon size="is-medium" class="mr-2" icon="horse-human"></b-icon>
              <b-icon size="is-medium" class="mr-2" icon="flower"></b-icon>
              <b-icon size="is-medium" class="mr-2" icon="run-fast"></b-icon>
              <b-icon size="is-medium" icon="heart-plus"></b-icon>
            </p>
            <b-progress
              :value="initialDataCount"
              :max="11"
              type="is-primary"
              :precision="0"
              :rounded="false"
              show-value
              format="percent"
              class="w-100 mb-3"
            ></b-progress>
            <!-- <p class="pb-0 title is-size-6">Aktualizujemy dane systemowe</p> -->
          </div>
        </b-tooltip>
      </div>
    </b-loading>

    <div v-if="isUserAuthorized" style="min-height: 100vh" class="has-background-szoklight">
      <div class="fluid" style="padding-bottom: 1px">
        <SiteNavigationTele v-if="tele"></SiteNavigationTele>
        <!-- <SiteNavigationTPK v-else-if="tpk" /> -->
        <SiteNavigation v-else />
      </div>
      <div class="omg page-pad has-background-szoklight">
        <div class="fluid router-pad ensured-height" style="max-width: 100vw !important">
          <transition name="fade" mode="out-in" v-if="initialDataLoaded">
            <router-view id="contentViewContainer" />
          </transition>
        </div>
      </div>
      <footer class="ml-4">
        <!-- <span v-if="productionMode == 0 && amIAdmin && !tpk">
          <b-switch disabled size="is-small" class="ml-2" v-model="teleSwitch">TeleUI</b-switch>
        </span>-->
        <div class="has-text-grey">
          <b-tooltip type="is-light" :label="`Aktywność: ${activity}s temu`">
            <b-icon icon="clock-outline" size="is-small"></b-icon>
          </b-tooltip>
          <b-tooltip type="is-light" class="mr-1" :label="`Wersja systemu: ${version}`">
            <b-icon icon="cog" size="is-small"></b-icon>
          </b-tooltip>
          <span class="is-size-7">
            Digitmed
            <span class="has-text-weight-bold has-text-primary">SZOK</span>
            <span>,</span>
            <b-tooltip type="is-light" multilined :label="userAgent" class="ml-1">
              <span>{{ browser }},</span>
            </b-tooltip>
            <b-tooltip type="is-light" multilined :label="device">
              <span class="ml-1">v{{ version }}</span>
            </b-tooltip>
          </span>
          <span
            class="has-text-grey is-size-7"
            v-if="uac2Run && uac2lastUpdate"
          >, UAC: {{mTimeSec(uac2lastUpdate)}}</span>
        </div>
        <div class="is-clearfix"></div>
      </footer>
    </div>

    <div v-else class="background" style="min-height: 100vh; position: relative">
      <router-view id="contentViewContainer" />
      <footer
        style="position: absolute; bottom: 10px; width: 100%"
        class="has-text-centered has-text-light"
      >
        <small>
          Digitmed
          <span class="has-text-weight-semibold has-text-primelight">SZOK</span>

          <b-tooltip type="is-light" multilined :label="device">
            <span class="has-text-primelight">, v{{ version }}</span>
          </b-tooltip>

          <b-tooltip type="is-light" multilined :label="userAgent">
            <span class="has-text-primelight">, {{ browser }}</span>
          </b-tooltip>
        </small>
      </footer>
    </div>

    <CookiePolicy />
  </div>
</template>

<script>

import SiteNavigationTele from './components/navigation/SiteNavigationTele'
import SiteNavigation from './components/navigation/SiteNavigation'
import CookiePolicy from './components/tools/CookiePolicy'
import CommonMixins from './mixins/commons'
import moment from 'moment'

// import { Action } from './store/config_actions'
// import { Mutation } from './store/config_mutations'
// import { Cookies } from './store/config_cookies'

import { RUN_MODE } from './store/config_apis'
import { APP_VERSION } from '@/store/config_apis'

const customIconConfig = {
  customIconPacks: {
    'szok': {
      sizes: {
        'default': 'szok-icon-normal',
        'is-small': 'szok-icon-small',
        'is-medium': 'szok-icon-medium',
        'is-large': 'szok-icon-large'
      }
    }
  }
}

/**
 * SZOK root component. Displays navigation, router view, footer, and manages authentication.
 */
export default {

  name: 'SZOK',
  mixins: [CommonMixins],

  components: {
    CookiePolicy,
    SiteNavigation,
    SiteNavigationTele
  },

  data: function () {
    return {

      // /** 
      //  * UAC/UAC2 mode indicator.
      //  */
      // uac2Run: false,

      /**
       * SZOK Version. This value is created in config_apis.js file.
       */
      version: APP_VERSION,

      /**
       * Authentication validator process.
       */
      authInterval: null,

      /**
       * UAC (old) validation process.
       */
      uacInterval: null,

      /**
       * Amount of miliseconds elapsed since last activity.
       */
      activity: 0,

      /**
       * UAC in progress indicator.
       */
      uacInProgress: false,

      /**
       * Authorization cookie validation in process indicator.
       */
      cookieCheckRunning: false,

      /**
       * TELE navigation active indicator.
       */
      teleSwitch: this.tele,

      /**
       * Current browser name.
       */
      browser: 'Nieznana przeglądarka',

      device: null
    }
  },

  watch: {

    initialDataLoaded(val) {
      if (val) {
        this.setupUserAuthConfiguration()
      }
    },

    /**
     * Event on TeleUI switch change.
     * Saves state to store and redirects user to Home screen if authorized.
     * @param val Switch state value.
     * @author MC
     */
    teleSwitch(val) {
      this.$store.commit(this.mMutations.TELE_SWITCH, val)

      if (this.isUserAuthorized && val) {
        this.$router.push({ name: 'home' })
      }
    },

    isUserAuthorized(val) {
      if (val) {
        //this.setupUserAuthConfiguration()
        // this.uac2connect()
        // this.setupAuthCheck(this)
      }
      else {
        this.$store.commit(this.mMutations.UAC_NULLIFY_CONNECTION)
      }
    },

    $route(to, from) {
      var rFrom = ""
      if (from) rFrom = from.name

      if (this.uac2Run) {
        if (rFrom != 'login') {
          this.sendUac2Signal(true)
        }
      }
    },

    uac2auth(value) {
      if (this.uac2Run) {
        if (value == 1) {
          // cool, youre safe
        }
        else if (value < 0) {
          // Just logout, skip message.
          clearInterval(this.authInterval)
          this.$store.dispatch(this.mActions.IDENTITY_LOGOUT, 'UAC_FAIL')
        }
        else {
          clearInterval(this.authInterval)
          this.$cookies.set(this.mCookies.UAC_ALERT, this.uac2message)
          this.$store.dispatch(this.mActions.IDENTITY_LOGOUT, 'UAC_FAIL')
        }
      }
    }
  },

  /**
   * CREATED
   * Sprawdza język i przeglądarkę.
   * @author MC
   */
  created() {
    this.reloadCookieLanguage()
    this.detectBrowser()
    this.$buefy.config.setOptions(customIconConfig)
  },

  /**
   * DESTROYED
   * Czyści interwały.
   * @author MC
   */
  destroyed() {
    clearInterval(this.authInterval)
    clearInterval(this.uacInterval)
  },

  /**
   * MOUNTED
   * Odpala interwały UAC i Auth.
   * @author MC
   */
  mounted() {
    this.cookieSetup()
    // this.setupUserAuthConfiguration()
  },


  computed: {
    initialDataLoaded() {
      return this.initialDataCount > 10
    },

    initialDataCount() {
      return this.$store.state.initialDataCount
    },

    /**
     * UAC2 connection status.
     */
    uac2Connection() { return this.$store.state.uac2.connection },

    /**
     * Time of last UAC2 update.
     */
    uac2lastUpdate() { return this.$store.state.uac2.lastUpdate },

    /**
     * UAC2 auth mode.
     */
    uac2auth() { return this.$store.state.uac2.auth },

    /**
     * UAC2 user message.
     */
    uac2message() { return this.$store.state.uac2.message },

    /**
     * System run mode:
     * T - Test (T1),
     * K - Klon,
     * P - Production
     * @returns {Number} Run mode.
     */
    runMode() { return RUN_MODE },

    /**
     * Checks for hour between 9pm and 6am.
     * Used for service information display.
     */
    isServiceTime() {
      let now = parseInt(moment().format('HH'))
      return now > 20 || now < 6
    },

    /**
     * Checks for evil browsers.
     */
    badBrowser() {
      if (this.browser) {
        if (this.browser != 'Chrome' && this.browser != 'Edge' && this.browser != 'Opera') {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },

    /**
     * Application enviroment mode.
     * 0 - build testowy, 1 - produkcja, 2 - klon
     * @author MC
     */
    productionMode() {
      let location = window.location.href
      if (location.includes('szok.digitmed')) return 1
      else if (location.includes('klon')) return 2
      else return 0
    },

    /**
     * Current user employee account.
     */
    myData() { return this.$store.state.employee.me },

    /**
     * TELE mode indicator.
     */
    tele() { return this.$store.state.tele },

    /**
     * TPK mode indicator.
     */
    tpk() { return this.$store.state.tpk },

    /**
     * User authorized indicator.
     */
    isUserAuthorized() { return this.$store.getters['isUserAuthorized'] },

    /**
     * Current application URL.
     */
    location() { return window.location.href },

    /**
     * Session data.
     */
    session() { return this.$store.state.identity },

    /**
     * User permissions.
     */
    me() { return this.$store.state.employee.permits },
    uac2Run() { return this.$store.state.employee.permits.uac2 },

    /**
     * Logging out in progress indicator.
     */
    loggingOut() { return this.$store.state.identity.loggingOut },

    /**
     * Is user an admin.
     */
    amIAdmin: function () { return this.$store.getters.isAdmin },


    getProgressType() {
      var type = 'is-grey'

      switch (this.initialDataCount) {
        case 11:
          type = 'is-success'
          break
      }

      return type
    },
  },


  methods: {

    setupUserAuthConfiguration() {
      if (this.isUserAuthorized) {

        // Setup activity listeners.
        setTimeout(() => document.addEventListener('click', this.itemClicked), 0)
        setTimeout(() => document.addEventListener('keydown', this.itemClicked), 0)

        // this.setupAuthCheck(this)

        if (!this.tpk) {
          if (!this.uac2Run) {
            this.setupUAC(true, this)
          }

          this.teleSwitch = this.tele
        }


        if (this.cookieCheckRunning) {
          // ok
        }
        else {
          this.setupAuthCheck(this)
        }


        if (this.uac2Run) {
          this.uac2connect()
        }
      }
    },

    /**
     * Creates UAC2 websocket connection.
     */
    uac2connect() {
      if (this.uac2Run) {
        this.$store.dispatch(this.mActions.UAC_CREATE_CONNECTION)/*.catch(error => { this.apiProblem(error); });*/
      }
    },

    /**
     * Sends UAC2 websocket signal with user activity.
     * @param {Boolean} force Forces signal even if time since previous is below limit.
     */
    sendUac2Signal(force) {
      if (this.uac2Run && this.myData && !this.uacInProgress) {
        let data = {
          force: false,
          screen: this.$route.name
        }

        if (force) {
          data.force = true
        }

        this.uacInProgress = true

        this.$store
          .dispatch(this.mActions.UAC_ACTIVITY_SEND, data)
          .then(() => {
            this.uacInProgress = false
          })
          .catch(() => {
            this.$store.dispatch(this.mActions.UAC_CREATE_CONNECTION).then(() => {
              this.$store
                .dispatch(this.mActions.UAC_ACTIVITY_SEND, data)
                .then(() => {
                  this.uacInProgress = false
                })
                .catch(() => {
                  this.uacInProgress = false
                  //this.apiProblem(error);
                });
            }).catch(() => {
              this.uacInProgress = false
              //this.apiProblem(error);
            });
          });
      }
    },

    /**
     * Detects user browser.
     */
    detectBrowser() {
      this.userAgent = navigator.userAgent;
      //let browserName;

      let userAgentString =
        navigator.userAgent;

      // Detect Chrome
      let chromeAgent =
        userAgentString.indexOf("Chrome") > -1;

      // Detect Internet Explorer
      let IExplorerAgent =
        userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1;

      // Detect Firefox
      let firefoxAgent =
        userAgentString.indexOf("Firefox") > -1;

      // Detect Safari
      let safariAgent =
        userAgentString.indexOf("Safari") > -1;

      // Discard Safari since it also matches Chrome
      if ((chromeAgent) && (safariAgent)) safariAgent = false;

      // Detect Opera
      let operaAgent = userAgentString.indexOf("OP") > -1;

      let edgAgent = userAgentString.indexOf("Edg") > -1;

      // Discard Chrome since it also matches Opera
      if ((chromeAgent) && (operaAgent)) chromeAgent = false;

      if ((chromeAgent) && (edgAgent)) chromeAgent = false;

      if (chromeAgent) this.browser = "Chrome"
      else if (operaAgent) this.browser = "Opera"
      else if (safariAgent) this.browser = "Safari"
      else if (firefoxAgent) this.browser = "Firefox"
      else if (IExplorerAgent) this.browser = "Explorer"
      else if (edgAgent) this.browser = "Edge"

      else {
        this.browser = "Nieznana przeglądarka"
      }








      // if (this.userAgent.match(/chrome|chromium|crios/i)) {
      //   browserName = "Chrome";
      // } else if (this.userAgent.match(/firefox|fxios/i)) {
      //   browserName = "Firefox";
      // } else if (this.userAgent.match(/safari/i)) {
      //   browserName = "Safari";
      // } else if (this.userAgent.match(/opr\//i)) {
      //   browserName = "Opera";
      // } else if (this.userAgent.match(/edg/i)) {
      //   browserName = "Edge";
      // }
      // else {
      //   browserName = "Nieznana przeglądarka"
      // }

      // this.browser = browserName
    },

    /**
     * Checks cookies for possible alerts for user.
     */
    cookieSetup() {
      let uac = this.$cookies.get(this.mCookies.UAC_ALERT);
      let timeout = this.$cookies.get(this.mCookies.TIMEOUT_ALERT);
      let device = this.$cookies.get(this.mCookies.DEVICE_ID);

      if (device && device.length == 24) {
        // cool, extend time for another 24h
        this.$cookies.set(this.mCookies.DEVICE_ID, device, '24h');
      }
      else {
        device = this.mRandomUppercase(24)
        this.$cookies.set(this.mCookies.DEVICE_ID, device, '24h');
      }

      this.device = device

      this.$store.commit(this.mMutations.SET_DEVICE_ID, device)

      this.$cookies.remove(this.mCookies.UAC_ALERT);
      this.$cookies.remove(this.mCookies.TIMEOUT_ALERT);

      if (uac && uac.length > 0) {
        this.$buefy.dialog.alert({ message: 'Zostałeś wylogowany zdalnie z powodu:<br/><br/><b>' + uac + '</b>', type: 'is-danger', hasIcon: true, scroll: 'keep' })
      }
      else if (timeout) {
        this.$cookies.remove(this.mCookies.TIMEOUT_ALERT);
        this.$buefy.dialog.alert({ message: 'Zostałeś wylogowany z powodu braku aktywności.', type: 'is-info', hasIcon: true, scroll: 'keep' })
      } else {
        // Do nothing
        //this.$buefy.dialog.alert({ message: 'Zostałeś wylogowany przez System Kontroli Użytkownika.', type: 'is-danger', hasIcon: true, scroll: 'keep' })
      }
    },

    /**
     * OBSOLETE
     * Sets up timer for UAC (old).
     * @param auth Auth data.
     */
    setupUAC(auth) {
      // clear existing interval
      clearInterval(this.uacInterval);

      // do not setup UAC if currently logging out is in progress
      if (auth && !this.loggingOut) {
        this.uacInterval = setInterval(function () {
          if (this.$store.state.identity.session) {

            // sendu uac check
            this.$store.dispatch(this.mActions.IDENTITY_UAC_CHECK, this.$route.name)
              .then((response) => {
                if (response) {
                  if (response.isActive) {
                    // cool, user verified
                  }
                  else {
                    if (response.skipLogout) {
                      // if user is already logged out in backend, make soft logout only
                      this.$cookies.set(this.mCookies.UAC_ALERT, response);
                      this.$store.dispatch(this.mActions.IDENTITY_LOGOUT_SOFT, 'UAC_FAIL')
                    }
                    else {
                      // logout user
                      this.$cookies.set(this.mCookies.UAC_ALERT, response);
                      this.$store.dispatch(this.mActions.IDENTITY_LOGOUT, 'UAC_FAIL')
                    }
                  }
                }
                else {
                  // no response, hard logout
                  this.$cookies.set(this.mCookies.UAC_ALERT);
                  this.$store.dispatch(this.mActions.IDENTITY_LOGOUT, 'UAC_FAIL')
                }
              })
              .catch((error) => {
                this.apiProblem(error)
              })
          }
          else {
            // nope, no session data, do not check UAC
          }
        }.bind(this), 6000);
      }
    },

    /**
     * Sets up timer for user authentication validation.
     * @param {Vue} instance Current Vue instance.
     */
    setupAuthCheck(instance) {

      // Max inactive second. This gets replaced below with cookie value.
      let maxInactiveSeconds = 60;

      // Timeout in miliseconds.
      let timautValue = 10000;

      instance.cookieCheckRunning = true

      // Set timeout (in this case it's safer than interval).
      setTimeout(function () {

        // Get user auth cookie.
        let cookie = instance.$cookies.get(instance.mCookies.SZOK_USER_AUTH);

        // Get user activity cookie.
        let activityCookie = instance.$cookies.get(instance.mCookies.SZOK_ACTIVITY);

        // Proceed with check if cookie present.
        if (cookie) {

          // Check if cookie account email checks with session email.
          if (instance.session.session.email != cookie.email) {

            // BAD SESSION!!! KILL IT WITH FIRE!
            instance.$cookies.set(instance.mCookies.UAC_ALERT);
            instance.logoutUser(instance, 'EXPIRE_OR_INCATIVE')
          }

          let difference = moment(cookie.until).diff(moment(), 'seconds')
          let noActivity = false
          instance.activity = 0

          maxInactiveSeconds = cookie.expires

          if (activityCookie) {
            instance.activity = moment().diff(moment(activityCookie), 'seconds')
            let remains = maxInactiveSeconds - instance.activity
            instance.$store.commit(instance.mMutations.IDENTITY_SET_TIMER, remains)

            if (instance.activity > maxInactiveSeconds) {
              noActivity = true
            }
            else if (instance.activity > maxInactiveSeconds - 60 && instance.activity < maxInactiveSeconds) {
              instance.dangerSnackbar(`Za ${maxInactiveSeconds - instance.activity - 1}s nastąpi automatyczne wylogowanie z powodu braku aktywności.`)
            }

          }
          else {
            noActivity = true
          }

          if (difference < 2 || noActivity) {
            instance.$cookies.set(instance.mCookies.TIMEOUT_ALERT);
            instance.$store.dispatch(instance.mActions.IDENTITY_LOGOUT, 'EXPIRE_OR_INCATIVE')
          }
          else if (difference < 60) {
            // instance.$store.dispatch(instance.mActions.IDENTITY_SESSION_RENEW_TOKEN)

            instance.$store
              .dispatch(instance.mActions.IDENTITY_SESSION_RENEW_TOKEN)
              .then(() => {
                instance.$store.commit(instance.mMutations.UAC_NULLIFY_CONNECTION)
                instance.uac2connect()
              })
          }

          if (instance.uac2Run) {
            let lastUac2Update = moment().diff(instance.uac2lastUpdate, 'seconds')

            if ((lastUac2Update > 30)) {
              instance.sendUac2Signal()
            }
          }

          instance.setupAuthCheck(instance)
        }
        else {
          instance.cookieCheckRunning = false
          instance.logoutUser(instance, 'NO_COOKIE')
        }
      }, timautValue);
    },

    /**
     * User logout.
     * @param {String} reason Reson for logout.
     * @param {Vue} instance Current Vue instance.
     */
    logoutUser(instance, reason) {
      if (instance.isUserAuthorized) {
        instance.$store.dispatch(this.mActions.IDENTITY_LOGOUT, reason)
      }

      if (instance.$route.name != 'login' && instance.$route.name != 'reset-password' && instance.$route.name != 'reset-password-request') {
        instance.$router.push({ name: 'login' })
      }
      else {
        // Ok, already in allowed page
      }
    },

    // setupAuthCheckOlder() {
    //   let maxInactiveSeconds = 60;

    //   clearInterval(this.authInterval);

    //   this.authInterval = setInterval(function () {
    //     let cookie = this.$cookies.get(this.mCookies.SZOK_USER_AUTH);
    //     let activityCookie = this.$cookies.get(this.mCookies.SZOK_ACTIVITY);

    //     if (cookie) {

    //       if (this.session.session.email != cookie.email) {
    //         // BAD SESSION!!! KILL IT WITH FIRE!
    //         this.$cookies.set(this.mCookies.UAC_ALERT);
    //         this.$store.dispatch(Action.IDENTITY_LOGOUT, 'EXPIRE_OR_INCATIVE')
    //       }

    //       let difference = moment(cookie.until).diff(moment(), 'seconds')
    //       let noActivity = false
    //       this.activity = 0

    //       maxInactiveSeconds = cookie.expires

    //       if (activityCookie) {
    //         this.activity = moment().diff(moment(activityCookie), 'seconds')
    //         let remains = maxInactiveSeconds - this.activity
    //         this.$store.commit(this.mMutations.IDENTITY_SET_TIMER, remains)

    //         if (this.activity > maxInactiveSeconds) {
    //           noActivity = true
    //         }
    //         else if (this.activity > maxInactiveSeconds - 60 && this.activity < maxInactiveSeconds) {
    //           this.dangerSnackbar(`Za ${maxInactiveSeconds - this.activity - 1}s nastąpi automatyczne wylogowanie z powodu braku aktywności`)
    //         }
    //       }
    //       else {
    //         noActivity = true
    //       }

    //       if (difference < 2 || noActivity) {
    //         this.$cookies.set(this.mCookies.TIMEOUT_ALERT);
    //         this.$store.dispatch(Action.IDENTITY_LOGOUT, 'EXPIRE_OR_INCATIVE')
    //       }
    //       else if (difference < 60) {
    //         this.$store.dispatch(Action.IDENTITY_SESSION_RENEW_TOKEN)
    //       }






    //       if (this.uac2Run) {
    //         let lastUac2Update = moment().diff(this.uac2lastUpdate, 'seconds')

    //         if ((lastUac2Update > 10)) {
    //           this.sendUac2Signal()
    //         }
    //       }


    //     }
    //     else {
    //       if (this.isUserAuthorized) {
    //         this.$store.dispatch(Action.IDENTITY_LOGOUT, 'NO_COOKIE')
    //       }

    //       if (this.$route.name != 'login' && this.$route.name != 'reset-password' && this.$route.name != 'reset-password-request') {
    //         this.$router.push({ name: 'login' })
    //       }
    //       else {
    //         // Ok, already in allowed page
    //       }
    //     }
    //   }.bind(this), 3000);
    // },

    /**
     * Item clicked event. Updates user activity log.
     * @param {Object} item Item clicked.
     */
    itemClicked(item) {

      if (this.session && this.isUserAuthorized) {
        this.$cookies.set(this.mCookies.SZOK_ACTIVITY, this.mDateTimeRawZone());

        if (this.uac2Run && item && item.srcElement) {
          this.sendUac2Signal()
          //this.$store.commit(this.mMutations.IDENTITY_SESSION_RESET_TIMER)
        }
      }
      else {
        if (this.$route.name != 'login' && this.$route.name != 'reset-password' && this.$route.name != 'reset-password-request') {
          this.$store.dispatch(this.mActions.IDENTITY_LOGOUT, 'ITEM_CLICKED_NO_SESSION')
          //  this.$router.push({ name: 'login' })
        }
        else {
          // Ok, already in allowed page
        }
      }
    },

    /**
     * Checks for language cookie.
     */
    reloadCookieLanguage() {
      // Change to saved language if found.
      var cookieLanguage = this.$cookies.get(this.mCookies.SZOK_LANGUAGE)
      if (cookieLanguage != null) {
        this.$ml.change(cookieLanguage)
      }
    }
  },

}
</script>

<!-- GLOBAL HELPERS -->
<style lang="scss">
input:autofill {
  border: 2px solid hotpink !important;
}

input:-webkit-autofill {
  border: 2px solid hotpink !important;
}

.background {
  background-image: url("~@/assets/bg3.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.glossy {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.199),
    hsla(0, 0%, 100%, 0) 50%,
    rgba(0, 0, 0, 0.08) 51%,
    rgba(0, 0, 0, 0.04)
  ) !important;
}

.is-toggle .is-active > a,
.button.is-primary,
.button.is-danger,
.button.is-success,
.button:not(.is-text) {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.116),
    hsla(0, 0%, 100%, 0) 40%,
    rgba(0, 0, 0, 0.05) 51%,
    rgba(0, 0, 0, 0.04)
  ) !important;
}

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// COLORS!
$primary: #398cbf;
$secondary: #26a69a;
$kin: #273e55;
$t1: #254715;
$klon: #0f5755;
$szok: #384859;
$ignored: hsl(9, 100%, 96%);
$hot: rgb(255, 235, 247);
$violet: hsl(273, 32%, 46%);
$primaryx: #4697ca;
$primelight: #88d9fe;
$bluelight: #e9f8ff;
$flash: #b7d5e7;
$await: #ffbb00;
$lightawait: #fff8e5;
$analyzed: #e9ffd1;
$szoklight: #f3f4f6; //rgb(236, 237, 238);
$lightgrey: #d9dadb;
$lime: #f3ffe9;
$forest: #006600;
$orange: #fd774e;
$maroon: #7a03b1;
$teal: #0099cc;
$purple: #4747d1;
$szokgrey: #4b4b4b;
$szokgreylight: #969696;
$incomplete: rgb(238, 233, 255);
$gs0: #6c6c64;
$gs1: #4ab4d4;
$gs2: #a5cc3b;
$gs3: #ffbd5b;
$gs4: #b0412d;
$gs5: #b0412d;
$transparent: rgba(255, 235, 247, 0);

// Inverted
$primary-invert: findColorInvert($primary);
$lightawait-invert: findColorInvert($lightawait);
$bluelight-invert: findColorInvert($bluelight);
$secondary-invert: findColorInvert($secondary);
$szok-invert: findColorInvert($szok);
$primelight-invert: findColorInvert($primary);
$flash-invert: findColorInvert($flash);
$violet-invert: findColorInvert($grey);
$t1-invert: findColorInvert($t1);
$incomplete-invert: findColorInvert($incomplete);
$kin-invert: $primary;
$klon-invert: findColorInvert($klon);
$szokgrey-invert: findColorInvert($szokgrey);
$szokgreylight-invert: findColorInvert($szokgreylight);
$szoklight-invert: findColorInvert($szoklight);
$hot-invert: findColorInvert($hot);
$analyzed-invert: findColorInvert($analyzed);
$ignored-invert: findColorInvert($ignored);
$await-invert: findColorInvert($await);
$purple-invert: findColorInvert($purple);
$teal-invert: findColorInvert($teal);
$maroon-invert: findColorInvert($maroon);
$orange-invert: findColorInvert($orange);
$forest-invert: findColorInvert($forest);
$lime-invert: findColorInvert($lime);
$lightgrey-invert: findColorInvert($lightgrey);

$gs0-invert: findColorInvert($gs0);
$gs1-invert: findColorInvert($gs1);
$gs2-invert: findColorInvert($gs2);
$gs3-invert: findColorInvert($gs3);
$gs4-invert: findColorInvert($gs4);
$gs5-invert: findColorInvert($gs5);

// Buefy modifiers
$input-background-color: #f2fafd;
$input-disabled-background-color: #f2fafd;
$input-disabled-color: #b6b9bb;
$input-shadow: inset 0 0px 1px rgb(0 0 0 / 8%);
$input-border-color: #d3dae6;
$box-radius: 10px;
$toast-opacity: 1;
$modal-background-background-color: #0000007b;
$modal-z: 99;
$control-radius: 6px;
$radius: 6px;
$radius-small: 6px;
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$body-line-height: 1.35;

$colors: (
  "szokgreylight": (
    $szokgreylight,
    $szokgreylight-invert,
  ),
  "bluelight": (
    $bluelight,
    $bluelight-invert,
  ),
  "lightawait": (
    $lightawait,
    $lightawait-invert,
  ),
  "purple": (
    $purple,
    $purple-invert,
  ),
  "incomplete": (
    $incomplete,
    $incomplete-invert,
  ),
  "transparent": (
    $transparent,
    $transparent,
  ),
  "lime": (
    $lime,
    $lime-invert,
  ),
  "ignored": (
    $ignored,
    $ignored-invert,
  ),
  "t1": (
    $t1,
    $t1-invert,
  ),
  "klon": (
    $klon,
    $klon-invert,
  ),
  "szokgrey": (
    $szokgrey,
    $szokgrey-invert,
  ),
  "teal": (
    $teal,
    $teal-invert,
  ),
  "maroon": (
    $maroon,
    $maroon-invert,
  ),
  "hot": (
    $hot,
    $hot-invert,
  ),
  "orange": (
    $orange,
    $orange-invert,
  ),
  "forest": (
    $forest,
    $forest-invert,
  ),
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "secondary": (
    $secondary,
    $secondary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "violet": (
    $violet,
    $violet-invert,
  ),
  "kin": (
    $kin,
    $kin-invert,
  ),
  "analyzed": (
    $analyzed,
    $analyzed-invert,
  ),
  "primelight": (
    $primelight,
    $primelight-invert,
  ),
  "await": (
    $await,
    $await-invert,
  ),
  "szok": (
    $szok,
    $szok-invert,
  ),
  "szoklight": (
    $szoklight,
    $szoklight-invert,
  ),
  "greylight": (
    $lightgrey,
    $lightgrey-invert,
  ),
  "flash": (
    $flash,
    $flash-invert,
  ),
  "gs0": (
    $gs0,
    $gs0-invert,
  ),
  "gs1": (
    $gs1,
    $gs1-invert,
  ),
  "gs2": (
    $gs2,
    $gs2-invert,
  ),
  "gs3": (
    $gs3,
    $gs3-invert,
  ),
  "gs4": (
    $gs4,
    $gs4-invert,
  ),
  "gs5": (
    $gs5,
    $gs5-invert,
  ),
);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Import custom styles
@import "css/margins_paddings.css";
@import "css/custom.css";
@import "css/bulma_buefy_modifiers.css";
@import "css/quill.bubble.css";
@import "css/quill.snow.css";
@import "css/quill.core.css";
@import "customs/style.css";
</style>

