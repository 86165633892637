export default {
    data: () => {
        return {
            /**Podstawa składanego oświadczenia o ubezpieczeniu
             * Basis of statement concerning insurance
             */
            mStatementBases1: [
                { id: 0, name: "objęcie ubezpieczeniem zdrowotnym" },
                { id: 1, name: "uzyskanie decyzji, o której mowa w art. 54 ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 2, name: "art. 2 ust. 1 pkt 4 lit. a ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 3, name: "art. 2 ust. 1 pkt 4 lit. b ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 4, name: "art. 67 ust. 4–7 ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" }
            ],
            /**Dodatkowa podstawa składanego oświadczenia o ubezpieczeniu
             * Additional basis of statement concerning insurance
             */
            mStatementBases2: [
                { id: 0, name: "objęcie ubezpieczeniem zdrowotnym" },
                { id: 5, name: "art. 2 ust. 1 pkt 3 lit. a ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 6, name: "art. 2 ust. 1 pkt 3 lit. b ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 7, name: "art. 2 ust. 1 pkt 4 lit. a ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 8, name: "art. 2 ust. 1 pkt 4 lit. b ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" },
                { id: 9, name: "art. 67 ust. 4–7 ustawy z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych" }
            ],
            /**Sposoby identyfikacji pacjenta
             * Methods of patient identification
             */
            mIdentifications: [
                { id: 0, name: "Identyfikacja pacjenta z nadanym numerem PESEL" },
                { id: 1, name: "Dziecko bez nadanego numeru PESEL" },
                { id: 2, name: "Identyfikacja pacjenta uprawnionego do świadczeń na podstawie przepisów o koordynacji bez nadanego numeru PESEL" },
                { id: 3, name: "Identyfikacja cudzoziemców posiadających umowę o dobrowolne ubezpieczenie" },
                { id: 4, name: "Inny identyfikator pacjenta – dokument tożsamości" }
            ],
            mMultiplePregnancyBirthOrder: [
                { id: 1, name: "pierwsze" },
                { id: 1, name: "drugie" },
                { id: 1, name: "trzecie" },
                { id: 1, name: "czwarte" },
                { id: 1, name: "piąte" },
                { id: 1, name: "szóste" },
                { id: 1, name: "siódme" },
                { id: 1, name: "ósme" }
            ],
            /**Dokument identyfikujący pacjenta
             * Patient's identification document
             */
            mIdentityDocuments1: [
                { id: 0, name: "Dowód osobisty" },
                { id: 1, name: "Paszport" },
                { id: 2, name: "Identyfikator" },
                { id: 3, name: "Prawo jazdy" },
                { id: 4, name: "Książeczka żeglarska" },
                { id: 5, name: "Dokument tymczasowy" }
            ],
            mIdentityDocuments2: [
                { id: 0, name: "Dowód osobisty" },
                { id: 1, name: "Paszport" },
                { id: 5, name: "Dokument tymczasowy" }
            ],
            mEntitlementDocuments1: [
                { id: 0, name: "Karta ubezpieczenia zdrowotnego" },
                { id: 1, name: "Decyzja wójta, burmistrza, prezydenta miasta" },
                { id: 2, name: "Dokument uprawniający do świadczeń wydany na podstawie przepisów o koordynacji - poświadczenie" },
                { id: 3, name: "(KB) Karta pobytu" },
                { id: 4, name: "(ZPC) Decyzja o udzieleniu cudzoziemcowi zezwolenia na pobyt" },
                { id: 5, name: "Dokument potwierdzający uprawnienia - tytuły uprawnień: IA, IB, IUA, IBU, UC, CH, EM, BU" },
                { id: 6, name: "Imienny raport miesięczny dla osoby ubezpieczonej" },
                { id: 7, name: "Legitymacja ubezpieczeniowa" },
                { id: 8, name: "Legitymacja rencisty/emeryta" },
                { id: 9, name: "Zgłoszenie do ubezpieczenia z dowodem opłaceniaskładek (ZUA, ZZA, ZCNA)" },
                { id: 10, name: "Zaświadczenie potwierdzające prawo do świadczeń" },
                { id: 11, name: "Inny dokument potwierdzający uprawnienia" },
                { id: 12, name: "Oświadczenie o przysługującym prawie do świadczeń opieki zdrowotnej" },
                { id: 99, name: "Pacjent nie posiada ubezpieczenia" }
            ],
            mEntitlementDocuments2: [
                { id: 13, name: "Poświadczenie" },
                { id: 14, name: "Certyfikat" },
                { id: 15, name: "Karta EKUZ" }
            ],
            mEntitlementDocumentsAll: [
                { id: 0, name: "Karta ubezpieczenia zdrowotnego" },
                { id: 1, name: "Decyzja wójta, burmistrza, prezydenta miasta" },
                { id: 2, name: "Dokument uprawniający do świadczeń wydany na podstawie przepisów o koordynacji - poświadczenie" },
                { id: 3, name: "(KB) Karta pobytu" },
                { id: 4, name: "(ZPC) Decyzja o udzieleniu cudzoziemcowi zezwolenia na pobyt" },
                { id: 5, name: "Dokument potwierdzający uprawnienia - tytuły uprawnień: IA, IB, IUA, IBU, UC, CH, EM, BU" },
                { id: 6, name: "Imienny raport miesięczny dla osoby ubezpieczonej" },
                { id: 7, name: "Legitymacja ubezpieczeniowa" },
                { id: 8, name: "Legitymacja rencisty/emeryta" },
                { id: 9, name: "Zgłoszenie do ubezpieczenia z dowodem opłaceniaskładek (ZUA, ZZA, ZCNA)" },
                { id: 10, name: "Zaświadczenie potwierdzające prawo do świadczeń" },
                { id: 11, name: "Inny dokument potwierdzający uprawnienia" },
                { id: 12, name: "Oświadczenie o przysługującym prawie do świadczeń opieki zdrowotnej" },
                { id: 13, name: "Poświadczenie" },
                { id: 14, name: "Certyfikat" },
                { id: 15, name: "Karta EKUZ" },
                { id: 16, name: "Uchodźca z terenu Ukrainy bez statusu prawnego"},
                { id: 99, name: "Pacjent nie posiada ubezpieczenia" }
            ],
            mGender: [
                { id: 0, code: "F", name: "Kobieta" },
                { id: 1, code: "M", name: "Mężczyzna" },
                { id: 2, code: "U", name: "Nieokreślona" }
            ],
            mInstitutionShortcuts: [
                { id: 0, name: "ZUS" },
                { id: 1, name: "KRUS" },
                { id: 2, name: "MSWiA" },
                { id: 3, name: "MON" },
                { id: 4, name: "SP" },
                { id: 5, name: "MSW" },
            ],
            mStatementTypes: [
                { id: 0, name: "Pacjenta" },
                { id: 1, name: "Opiekuna" },
            ],
            mPhoneNumberOwner: [
                { id: 0, name: "pacjent" },
                { id: 1, name: "krewny" },
                { id: 2, name: "inna osoba bliska" },
                { id: 3, name: "inny" },
            ],
            mPreferredContacts: [
                { id: -1, name: "Nieokreślony" },
                { id: 0, name: "Telefonicznie" },
                { id: 1, name: "SMS" },
                { id: 2, name: "Email" },
                { id: 3, name: "Portal pacjenta" },
                { id: 4, name: "Listownie" },
            ],
            /**NUmery kierunkowe w Polsce
             * Prefixes in Poland
             */
            mTelephonePrefixPoland: [
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '22',
                '23',
                '24',
                '25',
                '29',
                '32',
                '33',
                '34',
                '41',
                '42',
                '43',
                '44',
                '46',
                '48',
                '52',
                '54',
                '55',
                '56',
                '58',
                '59',
                '61',
                '62',
                '63',
                '65',
                '67',
                '68',
                '71',
                '74',
                '75',
                '76',
                '77',
                '81',
                '82',
                '83',
                '84',
                '85',
                '86',
                '87',
                '89',
                '91',
                '94',
                '95',
            ]
        }
    },
    methods: {
        /**Metoda wywoływania typu dokumentu
         * Method for getting type of document
         */
        mGetDocumentType(item) {
            let found = this.mIdentityDocuments1.find(b => b.id == item)
            if (found) return found.name
            else return ''
        },
        /**Metoda wywoływania podstawy oświadczenia o posiadaniu ubezpieczenia
         * Method forr getting basis of the insurance statement
         */
        mGetStatementBases1(item) {
            let found = this.mStatementBases1.find(b => b.id == item)
            if (found) return found.name
            else return ''
        },
        /**Metoda wywoływania dodatkowej podstawy oświadczenia o posiadaniu ubezpieczenia
         * Method for getting addtional basis of the insurance statement
         */
        mGetStatementBases2(item) {
            let found = this.mStatementBases2.find(b => b.id == item)
            if (found) return found.name
            else return ''
        },
        /**Metoda wywoływania rodzaju dokumentu uprawniającego do świadczeń
        * Method for getting type of document for qualifing for services
        */
        mGetEntitlementDocument(item) {
            let found = this.mEntitlementDocumentsAll.find(b => b.id == item)
            if (found) return found.name
            else return ''
        },
        /**Metoda do wywoływania sposobu identyfikacji pacjenta
         * Method for getting type of patient's identification
         */
        mGetIdentification(item) {
            let found = this.mIdentifications.find(b => b.id == item)
            if (found) return found.name
            else return ''
        }
    }
}