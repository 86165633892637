export default {

  data: () => {
    return {
      /**
       * Role w systemie
       * System roles
       */
      mRoles: [

        { id: 0, name: 'Nowy pracownik', areas: [], appointments: [] },
        { id: 10, name: '[POZ] Lekarz rodzinny', areas: [0, 1, 2, 3], appointments: [0, 1, 2, 3, 4, 5, 6] },
        { id: 11, name: '[POZ] Lekarz pediatra', areas: [], appointments: [20, 21, 22, 23, 24, 25, 26, 27] },
        { id: 12, name: '[?] Lekarz medycyny pracy', areas: [], appointments: [] },

        { id: 20, name: '[AOS] Lekarz specjalista', areas: [], appointments: [] },
        { id: 21, name: '[AOS] Lekarz stażysta', areas: [], appointments: [] },
        { id: 22, name: '[AOS] Lekarz alergolog', areas: [], appointments: [] },
        { id: 23, name: '[AOS] Lekarz laryngolog', areas: [], appointments: [] },
        { id: 24, name: '[AOS] Lekarz kardiolog', areas: [], appointments: [] },
        { id: 25, name: '[AOS] Lekarz dermatolog', areas: [], appointments: [] },
        { id: 26, name: '[AOS] Lekarz urolog', areas: [], appointments: [] },
        { id: 27, name: '[AOS] Lekarz ortopeda', areas: [], appointments: [] },
        { id: 28, name: '[AOS] Lekarz neurolog', areas: [], appointments: [] },
        { id: 29, name: '[AOS] Lekarz nefrolog', areas: [], appointments: [] },
        { id: 30, name: '[AOS] Lekarz endokrynolog', areas: [], appointments: [] },
        { id: 31, name: '[AOS] Lekarz ginekolog', areas: [], appointments: [] },
        { id: 32, name: '[AOS] Lekarz reumatolog', areas: [], appointments: [] },
        { id: 33, name: '[AOS] Lekarz specjalista - Konsultacje IPOM', areas: [], appointments: [] },


        { id: 40, name: '[Pielęgniarstwo] Pielęgniarz/Pielęgniarka środowskowa (lic)', areas: [], appointments: [40, 41, 50, 60, 61, 62, 63, 64, 90, 91, 92, 93] },
        { id: 41, name: '[Pielęgniarstwo] Pielęgniarz/Pielęgniarka środowiskowa (mgr)', areas: [], appointments: [40, 41, 50, 60, 61, 62, 63, 64, 90, 91, 92, 93] },
        { id: 42, name: '[Pielęgniarstwo] Pielęgniarz/Pielęgniarka z kursem (RIS-kontrast)', areas: [], appointments: [] },
        { id: 43, name: '[Pielęgniarstwo] Pielęgniarz/Pielęgniarka - wybór rozpoznań do rozliczenia', areas: [], appointments: [] },
        { id: 44, name: '[Pielęgniarstwo] Pielęgniarz/Pielęgniarka - widoczność gabinetów IPOM', areas: [], appointments: [] },

        { id: 50, name: '[Położnictwo] Położna/Położny (mgr)', areas: [], appointments: [110, 111, 112, 113, 114, 115] },
        { id: 51, name: '[Położnictwo] Położna/Położny (lic)', areas: [], appointments: [110, 111, 112, 113, 114, 115] },

        { id: 60, name: '[Rahabilitacja] Fizjoterapeuta (mgr)', areas: [], appointments: [240] },
        { id: 61, name: '[Rahabilitacja] Fizjoterapeuta (lic)', areas: [], appointments: [240] },
        { id: 62, name: '[Rahabilitacja] Masażysta/Masażystka', areas: [], appointments: [] },

        { id: 70, name: '[BOK] Rejestracja', areas: [], appointments: [] },
        { id: 71, name: '[BOK] Operator Call-Center', areas: [], appointments: [] },
        { id: 72, name: '[BOK] Edycja danych identyfikacyjnych', areas: [], appointments: [] },
        { id: 73, name: '[BOK] Konfiguracja stref terminarzy', areas: [], appointments: [] },
        { id: 74, name: '[BOK] Archiwum pacjenta', areas: [], appointments: [] },
        { id: 75, name: '[BOK] Archiwum pacjenta - diagnostyka', areas: [], appointments: [] },
        { id: 76, name: '[BOK] Archiwum pacjenta - dokumentacja zbiorcza', areas: [], appointments: [] },
        { id: 77, name: '[BOK] Rejestracja B1', areas: [], appointments: [] },
        { id: 78, name: '[BOK] Wydruk listy pacjentów z PESEL', areas: [], appointments: [] },
        { id: 79, name: '[BOK] Archiwum pacjenta - wizyty danego lekarza', areas: [], appointments: [] },

        { id: 80, name: '[Wsparcie] Asystent med./Pomoc stom.', areas: [], appointments: [] },
        { id: 81, name: '[Wsparcie] Asystent ginekologiczny', areas: [], appointments: [] },
        { id: 82, name: '[Wsparcie] Asystent lekarza', areas: [], appointments: [] },

        { id: 100, name: '[Wsparcie] Edukator', areas: [], appointments: [] },
        { id: 101, name: '[Wsparcie] Dietetyk', areas: [], appointments: [260] },
        { id: 102, name: '[Wsparcie] Psycholog', areas: [], appointments: [] },
        { id: 103, name: '[Wsparcie] Psychoterapeuta', areas: [], appointments: [] },
        { id: 104, name: '[Wsparcie] Opiekun medyczny', areas: [], appointments: [] },
        { id: 105, name: '[Wsparcie] Terapeuta zajęciowy', areas: [], appointments: [] },
        { id: 106, name: '[Wsparcie] Terapeuta środowiskowy', areas: [], appointments: [] },
        { id: 107, name: '[Wsparcie] Logopeda', areas: [], appointments: [] },
        { id: 108, name: '[Wsparcie] Pedagog', areas: [], appointments: [] },
        { id: 109, name: '[Wsparcie] Gipsiarz', areas: [], appointments: [] },
        { id: 110, name: '[Wsparcie] Pracownik socjalny', areas: [], appointments: [] },
        { id: 111, name: '[Wsparcie] IPOM', areas: [], appointments: [] },
        { id: 112, name: '[Wsparcie] IPOM VER 2', areas: [], appointments: [] },

        { id: 130, name: '[Transport] Ratownik medyczny', areas: [], appointments: [] },
        { id: 131, name: '[Transport] Kierowca', areas: [], appointments: [] },
        { id: 132, name: '[Transport] Pracownik utrzymania', areas: [], appointments: [] },
        { id: 133, name: '[Transport] Mobi Przesyłki', areas: [], appointments: [] },
        { id: 134, name: '[Transport] Mobi - Edycja danych pojazdu', areas: [], appointments: [] },
        { id: 135, name: '[Transport] Mobi - Usuwanie danych pojazdu', areas: [], appointments: [] },
        { id: 136, name: '[Transport] Mobi - statystyki', areas: [], appointments: [] },

        { id: 150, name: '[Diagnostyka] Diagnosta laboratoryjny', areas: [], appointments: [] },
        { id: 151, name: '[Diagnostyka] Technik radiolog', areas: [], appointments: [] },
        { id: 152, name: '[Diagnostyka] Lekarz radiolog', areas: [], appointments: [] },
        { id: 153, name: '[Diagnostyka] Pracownik punktu pobrań COVID', areas: [], appointments: [] },
        { id: 154, name: '[Diagnostyka] Diagnosta laboratoryjny - cytologia', areas: [], appointments: [] },
        { id: 155, name: '[Diagnostyka] Laboratorium - zarządzanie', areas: [], appointments: [] },
        { id: 156, name: '[Diagnostyka] Rescreening - low grade risk', areas: [], appointments: [] },
        { id: 157, name: '[Diagnostyka] Pracownik HPV', areas: [], appointments: [] },
        { id: 158, name: '[Diagnostyka] Wirusologia - ograniczenie dostępu (!)', areas: [], appointments: [] },
        { id: 159, name: '[Diagnostyka] Cytologia - ograniczenie dostępu (!)', areas: [], appointments: [] },
        { id: 160, name: '[Diagnostyka] Cytologia - administrator', areas: [], appointments: [] },
        { id: 161, name: '[Diagnostyka] Radiologia - administrator', areas: [], appointments: [] },
        { id: 162, name: '[Diagnostyka] Rescreening - high grade risk', areas: [], appointments: [] },

        { id: 163, name: '[LAB MINI - Cytologia] Diagnosta laboratoryjny', areas: [], appointments: [] },
        { id: 164, name: '[LAB MINI - Cytologia] Sekretarka', areas: [], appointments: [] },
        { id: 165, name: '[Cytologia] Zarządzanie statywami', areas: [], appointments: [] },

        { id: 166, name: '[Cytologia] Zatwierdzanie wersji roboczej', areas: [], appointments: [] },

        { id: 167, name: '[Diagnostyka] Technik radiolog - brak dodawania skierowania', areas: [], appointments: [] },
        { id: 168, name: '[Diagnostyka] Radiologia - zarządzanie terminarzami', areas: [], appointments: [] },
        { id: 169, name: '[Diagnostyka] Radiologia - konfiguracja stref terminarzy', areas: [], appointments: [] },
        { id: 170, name: '[Diagnostyka] Radiologia - pracownia radiologii - pełen dostęp', areas: [], appointments: [] },
        { id: 171, name: '[Diagnostyka] Radiologia - pracownia radiologii - do odczytu', areas: [], appointments: [] },
        { id: 172, name: '[Diagnostyka] Radiologia - e-księga', areas: [], appointments: [] },
        { id: 173, name: '[Diagnostyka] Radiologia - wysyłka badań', areas: [], appointments: [] },
        { id: 174, name: '[Diagnostyka] Radiologia - urządzenia medyczne - pełen dostęp', areas: [], appointments: [] },
        { id: 175, name: '[Diagnostyka] Radiologia - urządzenia medyczne - do odczytu', areas: [], appointments: [] },
        { id: 176, name: '[Diagnostyka] Radiologia - statystyki badań', areas: [], appointments: [] },
        { id: 177, name: '[Diagnostyka] Radiologia - wysyłka badań, lista robocza w rejestracji', areas: [], appointments: [] },
        { id: 178, name: '[Diagnostyka] Radiologia - wyświetlanie nieukończonych badań', areas: [], appointments: [] },

        { id: 179, name: '[LAB MINI - Cytologia] Administracja', areas: [], appointments: [] },

        { id: 180, name: '[Farmacja] Technik farmaceuta', areas: [], appointments: [140, 141, 142] },
        { id: 181, name: '[Farmacja] Magister farmacji', areas: [], appointments: [140, 141, 142] },

        { id: 185, name: '[Położna] Zarządzanie terminarzem', areas: [], appointments: [] },
        { id: 186, name: '[Położna] Edukacje i plany opieki', areas: [], appointments: [] },
        { id: 187, name: '[Położna] HPV SOLAB', areas: [], appointments: [] },

        { id: 190, name: '[Histopatologia] Pracownik punktu pobrań', areas: [], appointments: [92, 9201] },
        { id: 191, name: '[Histopatologia] Patolog', areas: [], appointments: [] },
        { id: 192, name: '[Histopatologia] Administrator', areas: [], appointments: [] },

        { id: 200, name: '[CUW] Pracownik kadr', areas: [], appointments: [] },
        { id: 201, name: '[CUW] Pracownik rozliczeń z płatnikiem', areas: [], appointments: [] },
        { id: 202, name: '[CUW] Pracownik rozliczeń płac', areas: [], appointments: [] },
        { id: 203, name: '[CUW] Pracownik księgowości', areas: [], appointments: [] },
        { id: 204, name: '[CUW] Pracownik działu IT', areas: [], appointments: [] },
        { id: 205, name: '[CUW] Intendent', areas: [], appointments: [] },
        { id: 206, name: '[CUW] Promocja', areas: [], appointments: [] },
        { id: 207, name: '[CUW] Terminator (zarządzanie terminarzami)', areas: [], appointments: [] },
        { id: 208, name: '[CUW] Administracja (zarządzanie strukturą)', areas: [], appointments: [] },
        { id: 209, name: '[CUW] Administracja (umowy)', areas: [], appointments: [] },
        { id: 210, name: '[CUW] Administracja', areas: [], appointments: [] },
        { id: 211, name: '[CUW] Administracja (umowy) - edycja', areas: [], appointments: [] },
        { id: 212, name: '[CUW] Kadry - widok zdarzeń pracownika', areas: [], appointments: [] },
        { id: 213, name: '[CUW] Rozliczenia NFZ', areas: [], appointments: [] },
        { id: 214, name: '[CUW] Cenniki', areas: [], appointments: [] },
        { id: 215, name: '[CUW] Rozliczenia - deklaracje', areas: [], appointments: [] },
        { id: 216, name: '[CUW] Rozliczenia - zlecenia/bilety', areas: [], appointments: [] },
        { id: 217, name: '[CUW] Rozliczenia - świadczenia', areas: [], appointments: [] },
        { id: 218, name: '[CUW] Rozliczenia - kontrahenci', areas: [], appointments: [] },
        { id: 219, name: '[CUW] Rozliczenia - kasy fiskalne', areas: [], appointments: [] },

        { id: 220, name: '[Kadra zarz.] Zarząd, dyr. finansowy', areas: [], appointments: [] },
        { id: 221, name: '[Kadra zarz.] Menedżer rejonu', areas: [], appointments: [] },
        { id: 222, name: '[Kadra zarz.] Menedżer klastra', areas: [], appointments: [] },
        { id: 223, name: '[Kadra zarz.] Pracownik rozwoju sieci/naukowy', areas: [], appointments: [] },
        { id: 224, name: '[Kadra zarz.] Wdrożeniowiec', areas: [], appointments: [] },
        { id: 225, name: '[Kadra zarz.] Analityk', areas: [], appointments: [] },

        { id: 226, name: '[CUW] Rozliczenia - IKZ', areas: [], appointments: [] },
        { id: 227, name: '[CUW] Administracja - zarządzanie populacją', areas: [], appointments: [] },
        { id: 228, name: '[CUW] Rozliczenia - zestawienia', areas: [], appointments: [] },

        { id: 229, name: '[CUW] Wysyłanie SMS', areas: [], appointments: [] },

        { id: 230, name: '[CUW] Administracja - panel koordynacji', areas: [], appointments: [] },
        { id: 231, name: '[CUW] Rozliczenia - faktury ryczałtowe', areas: [], appointments: [] },
        { id: 232, name: '[CUW] Rozliczenia - drukowanie faktur', areas: [], appointments: [] },

        { id: 233, name: '[CUW] Rozliczenia - zestawienia uniwersalne', areas: [], appointments: [] },


        { id: 250, name: '[NFZ] Raporty LIOCZ - tworzenie i uzupełnianie danych', areas: [], appointments: [] },
        { id: 251, name: '[NFZ] Raporty ZBPOZ - tworzenie i uzupełnianie danych', areas: [], appointments: [] },

        { id: 290, name: '[DPR] Terminarz V2 - rola bez efektu', areas: [], appointments: [] },
        { id: 291, name: '[!] Terminarz V2 Edycja', areas: [], appointments: [] },
        { id: 292, name: '[UWAGA] Terminarz - stara wersja', areas: [], appointments: [] },

        { id: 295, name: '[!] Edycja wizyty', areas: [], appointments: [] },

        { id: 299, name: '[!] Przełączanie swoich domen', areas: [], appointments: [] },

        { id: 300, name: '[Inne] Asystent administracyjny', areas: [], appointments: [] },
        { id: 301, name: '[Inne] Pracownik naukowy', areas: [], appointments: [] },
        { id: 302, name: '[Inne] Rezydent/Stażysta', areas: [], appointments: [] },
        { id: 303, name: '[Inne] E-Learning Edytor', areas: [], appointments: [] },
        { id: 304, name: '[Inne] E-Learning Super Edytor', areas: [], appointments: [] },
        { id: 305, name: '[Inne] Pracownik punktu pobrań', areas: [], appointments: [] },
        { id: 306, name: '[Inne] Inwentaryzator', areas: [], appointments: [] },
        { id: 307, name: '[Inne] Obsługa CRM', areas: [], appointments: [] },
        { id: 308, name: '[Inne] Umawianie wizyt poza obszarami', areas: [], appointments: [] },
        { id: 309, name: '[Inne] Obsługa CRM-IT', areas: [], appointments: [] },
        { id: 310, name: '[Inne] Członek zespołu IT dla CRM-IT', areas: [], appointments: [] },
        { id: 311, name: '[Inne] Obsługa CRM-Digitmed', areas: [], appointments: [] },
        { id: 312, name: '[Inne] Członek zespołu Digitmed dla CRM-Digitmed', areas: [], appointments: [] },
        { id: 313, name: '[Inne] Obsługa CRM-Utrzymanie', areas: [], appointments: [] },
        { id: 314, name: '[Inne] Członek zespołu Utrzymanie dla CRM-Utrzymanie', areas: [], appointments: [] },
        { id: 315, name: '[Inne] Obsługa CRM-RODO', areas: [], appointments: [] },
        { id: 316, name: '[Inne] Członek zespołu Rodo dla CRM-RODO', areas: [], appointments: [] },
        { id: 317, name: '[Inne] Obsługa CRM-BOK', areas: [], appointments: [] },
        { id: 318, name: '[Inne] Członek zespołu BOK dla CRM-BOK', areas: [], appointments: [] },
        { id: 319, name: '[Inne] Obsługa CRM-HR', areas: [], appointments: [] },
        { id: 320, name: '[Inne] Członek zespołu HR dla CRM-HR', areas: [], appointments: [] },
        { id: 321, name: '[Inne] Obsługa CRM-Promocja', areas: [], appointments: [] },
        { id: 322, name: '[Inne] Członek zespołu Promocja dla CRM-Promocja', areas: [], appointments: [] },
        { id: 323, name: '[Inne] Supermoc do usuwania badań w tabeli', areas: [], appointments: [] },
        { id: 324, name: '[Inne] Bez wyboru placówki', areas: [], appointments: [] },
        { id: 325, name: '[Inne] Księga e-skierowań', areas: [], appointments: [] },
        { id: 326, name: '[Inne] Moje IKZ', areas: [], appointments: [] },
        { id: 327, name: '[Inne] Możliwość zapisu krótszej wizyty', areas: [], appointments: [] },
        { id: 328, name: '[Inne] Omijanie limitu wizyt bez godziny', areas: [], appointments: [] },
        { id: 329, name: '[Inne] Portal pacjenta', areas: [], appointments: [] },
        { id: 330, name: '[Inne] Widoczność pacjentów APKolce', areas: [], appointments: [] },
        { id: 331, name: '[Inne] Dodawanie umów UMX', areas: [], appointments: [] },


        { id: 332, name: '[BOK] Omijanie obowiązkowych pól dla skierowania APKolce', areas: [], appointments: [] },
        { id: 333, name: '[BOK] Omijanie obowiązkowych pól dla danych APKolce', areas: [], appointments: [] },
        { id: 334, name: '[BOK] Omijanie obowiązkowych pól dla wizyty z kartą DILO', areas: [], appointments: [] },

        { id: 335, name: '[Inne] Radiologia - nagrywanie badań dla CMJ', areas: [], appointments: [] },
        { id: 336, name: '[Inne] Radiologia - panel badań do wyjaśnienia', areas: [], appointments: [] },
        { id: 337, name: '[Inne] Radiologia - filtrowanie po birads', areas: [], appointments: [] },

        { id: 338, name: '[Inne] Punkt pobrań - bez wysyłki informacji o CITO', areas: [], appointments: [] },
        { id: 339, name: '[Inne] Radiologia - wybór opisu ostatecznego', areas: [], appointments: [] },
        { id: 340, name: '[Inne] Radiologia - E-księga ver2', areas: [], appointments: [] },
        { id: 341, name: '[Inne] Koordynacja - rejestr świadczeń', areas: [], appointments: [] },



        { id: 350, name: '[BLK] Całkowite ograniczenie danych osobowych', areas: [], appointments: [] },
        { id: 351, name: '[BLK] Nocne ograniczenie danych osobowych', areas: [], appointments: [] },

        { id: 360, name: '[IKZ] IKZ dla Pharma', areas: [], appointments: [] },
        { id: 361, name: '[IKZ] IKZ - Wersja V2', areas: [], appointments: [] },
        { id: 362, name: '[IKZ] IKZ - Wersja V2 - Statystyki', areas: [], appointments: [] },
        { id: 363, name: '[IKZ] IKZ - dodawanie i edycja IKZ w panelu konfiguracji', areas: [], appointments: [] },

        { id: 400, name: '[Edycja bazy leków] Wersja robocza', areas: [], appointments: [] },
        { id: 401, name: '[Edycja bazy leków] Zatwierdzanie zmian', areas: [], appointments: [] },

        { id: 450, name: '[RIGET] Dostęp do archiwum Riget', areas: [], appointments: [] },
        { id: 451, name: '[RIGET] !!! Dostęp do archiwum Riget (PSYCH) NIEAKTYWNA', areas: [], appointments: [] },

        { id: 470, name: '[Inne] Statystyki czasu pracowników', areas: [], appointments: [] },
        { id: 471, name: '[Inne] Rejestracja Edeklaracje', areas: [], appointments: [] },
        { id: 472, name: '[Inne] Limitowanie świadczeń w gabinecie', areas: [], appointments: [] },
        { id: 473, name: '[Inne] Wybór świadczenia przez rejestrację', areas: [], appointments: [] },
        { id: 474, name: '[Inne] Wysyłanie ZM do CEZ (40+)', areas: [], appointments: [] },

        { id: 475, name: '[Inne] Wersja V1 panelu rozpoznań w gabinecie lekarza', areas: [], appointments: [] },
        { id: 476, name: '[Inne] Dostęp do panelu JGP w wersji 2', areas: [], appointments: [] },
        { id: 477, name: '[Inne] Dostęp do nowego panelu szczepień w gabinecie lekarza i pielęgniarki (także skrócone pielęgniarki)', areas: [], appointments: [] },

        { id: 478, name: '[Inne] Nowa zakładka z wynikami badań w gabinecie lekarza', areas: [], appointments: [] },

        { id: 485, name: '[Inne] Dostęp do panelu wystawiania recept w gabinecie pielęgniarki', areas: [], appointments: [] },

        { id: 490, name: '[Inne] Dostęp do instrukcji obsługi', areas: [], appointments: [] },
        { id: 491, name: '[Inne] Ukryte testowe hasło do certyfikatu P12', areas: [], appointments: [] },

        { id: 500, name: '[Podmiot] Pracownia rehabilitacji', areas: [], appointments: [] },

        { id: 550, name: '[Podmiot] Laboratorium - TYLKO odbiór, blokuje dostęp do menu i mojego konta', areas: [], appointments: [] },
        { id: 551, name: '[Podmiot] Laboratorium - dystrybucja', areas: [], appointments: [] },
        { id: 552, name: '[Podmiot] Laboratorium - analiza', areas: [], appointments: [] },

        { id: 580, name: '[Zarządzanie] Laboratorium - odrzucanie badań', areas: [], appointments: [] },
        { id: 581, name: '[Zarządzanie] Wyszukiwarka badań - drukowanie etykiet', areas: [], appointments: [] },

        { id: 601, name: '[TPK] Lekarz', areas: [], appointments: [] },
        { id: 602, name: '[TPK] Pielęgniarka', areas: [], appointments: [] },
        { id: 603, name: '[TPK] Położna', areas: [], appointments: [] },
        { id: 604, name: '[TPK] Menedżer wyższego szczebla', areas: [], appointments: [] },
        { id: 605, name: '[TPK] Menedżer operacyjny', areas: [], appointments: [] },
        { id: 606, name: '[TPK] Opiekun', areas: [], appointments: [] },
        { id: 607, name: '[TPK] Trener', areas: [], appointments: [] },
        { id: 608, name: '[TPK] Admin archiwum organizacji', areas: [], appointments: [] },

        { id: 620, name: '[Projekty] Zarządzanie projektami', areas: [], appointments: [] },
        { id: 621, name: '[Projekty] Projekty czasowe - Osteoporoza', areas: [], appointments: [] },
        { id: 622, name: '[Projekty] Projekty czasowe - Tomografia płuc', areas: [], appointments: [] },
        { id: 623, name: '[Projekty] Profilaktyka 40 Plus', areas: [], appointments: [] },

        { id: 666, name: '[TEST] -> Lekarz specjalista 2 <-', areas: [], appointments: [] },
        { id: 667, name: '[TEST] -> Wdrożenie nowych - prototypy', areas: [], appointments: [] },
        { id: 668, name: '[TEST] -> Tester', areas: [], appointments: [] },
        { id: 669, name: '[TEST] -> Test skanera', areas: [], appointments: [] },

        { id: 700, name: '[TESTY] -> Nowa wyszukiwarka badań laboratoryjnych', areas: [], appointments: [] },
        { id: 701, name: '[TESTY] -> Wysyłka HPV Solab', areas: [], appointments: [] },


        { id: 800, name: '[SESJA] Sesja 5 minut', areas: [], appointments: [] },
        { id: 801, name: '[SESJA] Sesja 10 minut', areas: [], appointments: [] },
        { id: 802, name: '[SESJA] Sesja 20 minut', areas: [], appointments: [] },
        { id: 803, name: '[SESJA] Sesja 30 minut', areas: [], appointments: [] },
        { id: 804, name: '[SESJA] Sesja 40 minut', areas: [], appointments: [] },
        { id: 805, name: '[SESJA] Sesja 50 minut', areas: [], appointments: [] },
        { id: 806, name: '[SESJA] Sesja 60 minut', areas: [], appointments: [] },
        { id: 807, name: '[SESJA] Sesja 120 minut', areas: [], appointments: [] },


        { id: 901, name: '[TEST] Przywileje pacjenta', areas: [], appointments: [] },

        { id: 950, name: '[2FA] Uwierzytelnianie dwuskładnikowe (4 godziny)', areas: [], appointments: [] },
        { id: 951, name: '[2FA] Uwierzytelnianie dwuskładnikowe przy każdym logowaniu', areas: [], appointments: [] },
        { id: 952, name: '[2FA CMJ] Uwierzytelnianie dwuskładnikowe CMD', areas: [], appointments: [] },

        { id: 996, name: '[!] UAC 2.0', areas: [], appointments: [] },
        { id: 997, name: '[!] Subadmin', areas: [], appointments: [] },
        { id: 998, name: '[TEST] -> Lekarz testowy <-', areas: [], appointments: [] },
        { id: 999, name: '[!!!] Administrator', areas: [], appointments: [] },
      ],
      /**Specjalizacji lekarskie
       * Physician's specializations
       */
      mSpecializations: [
        { id: 731, code: '0731', name: 'alergologia', },
        { id: 701, code: '0701', name: 'anestezjologia i intensywna terapia', },
        { id: 732, code: '0732', name: 'angiologia', },
        { id: 733, code: '0733', name: 'audiologia i foniatria', },
        { id: 734, code: '0734', name: 'balneologia i medycyna fizykalna', },
        { id: 702, code: '0702', name: 'chirurgia dziecięca', },
        { id: 735, code: '0735', name: 'chirurgia klatki piersiowej', },
        { id: 736, code: '0736', name: 'chirurgia naczyniowa', },
        { id: 703, code: '0703', name: 'chirurgia ogólna', },
        { id: 737, code: '0737', name: 'chirurgia onkologiczna', },
        { id: 738, code: '0738', name: 'chirurgia plastyczna', },
        { id: 704, code: '0704', name: 'chirurgia szczękowo-twarzowa', },
        { id: 739, code: '0739', name: 'choroby płuc', },
        { id: 792, code: '0792', name: 'choroby płuc dzieci', },
        { id: 705, code: '0705', name: 'choroby wewnętrzne', },
        { id: 706, code: '0706', name: 'choroby zakaźne', },
        { id: 707, code: '0707', name: 'dermatologia i wenerologia', },
        { id: 740, code: '0740', name: 'diabetologia', },
        { id: 708, code: '0708', name: 'diagnostyka labolatoryjna', },
        { id: 741, code: '0741', name: 'endokrynologia', },
        { id: 799, code: '0799', name: 'endokrynologia ginekologiczna i rozrodczość', },
        { id: 796, code: '0796', name: 'endokrynologia i diabetologia dziecięca', },
        { id: 710, code: '0710', name: 'epidemiologia', },
        { id: 742, code: '0742', name: 'fakmakologia kliniczna', },
        { id: 743, code: '0743', name: 'gastroenterologia', },
        { id: 797, code: '0797', name: 'gastroenterologia dziecięca', },
        { id: 709, code: '0709', name: 'genetyka kliniczna', },
        { id: 744, code: '0744', name: 'geriatria', },
        { id: 787, code: '0787', name: 'ginekologia onkologiczna', },
        { id: 745, code: '0745', name: 'hematologia', },
        { id: 788, code: '0788', name: 'hipertensjologia', },
        { id: 746, code: '0746', name: 'immunologia kliniczna', },
        { id: 801, code: '0801', name: 'intensywna terapia', },
        { id: 747, code: '0747', name: 'kardiochirurgia', },
        { id: 748, code: '0748', name: 'kardiologia', },
        { id: 762, code: '0762', name: 'kardiologia dziecięca', },
        { id: 793, code: '0793', name: 'medycyna lotnicza', },
        { id: 794, code: '0794', name: 'medycyna morska i tropikalna', },
        { id: 749, code: '0749', name: 'medycyna nuklearna', },
        { id: 750, code: '0750', name: 'medycyna paliatywna', },
        { id: 711, code: '0711', name: 'medycyna pracy', },
        { id: 712, code: '0712', name: 'medycyna ratunkowa', },
        { id: 713, code: '0713', name: 'medycyna rodzinna', },
        { id: 714, code: '0714', name: 'medycyna sądowa', },
        { id: 751, code: '0751', name: 'medycyna sportowa', },
        { id: 716, code: '0716', name: 'mikrobiologia lekarska', },
        { id: 752, code: '0752', name: 'nefrologia', },
        { id: 798, code: '0798', name: 'nefrologia dziecięca', },
        { id: 753, code: '0753', name: 'neonatologia', },
        { id: 717, code: '0717', name: 'neurochirurgia', },
        { id: 718, code: '0718', name: 'neurologia', },
        { id: 763, code: '0763', name: 'neurologia dziecięca', },
        { id: 789, code: '0789', name: 'neuropatologia', },
        { id: 719, code: '0719', name: 'okulistyka', },
        { id: 755, code: '0755', name: 'onkologia i hematologia dziecięca', },
        { id: 754, code: '0754', name: 'onkologia kliniczna', },
        { id: 720, code: '0720', name: 'ortopedia i traumatologia narządu ruchu', },
        { id: 721, code: '0721', name: 'otorynolaryngologia', },
        { id: 790, code: '0790', name: 'otorynolaryngologia dziecięca', },
        { id: 722, code: '0722', name: 'patomorfologia', },
        { id: 723, code: '0723', name: 'pediatria', },
        { id: 795, code: '0795', name: 'pediatria metaboliczna', },
        { id: 800, code: '0800', name: 'perinatologia', },
        { id: 724, code: '0724', name: 'położnictwo i ginekologia', },
        { id: 725, code: '0725', name: 'psychiatria', },
        { id: 756, code: '0756', name: 'psychiatria dzieci i młodzieży', },
        { id: 726, code: '0726', name: 'radiologia i diagnostyka obrazowa', },
        { id: 727, code: '0727', name: 'radioterapia onkologiczna', },
        { id: 728, code: '0728', name: 'rehabilitacja medyczna', },
        { id: 757, code: '0757', name: 'reumatologia', },
        { id: 758, code: '0758', name: 'seksuologia', },
        { id: 759, code: '0759', name: 'toksykologia ', },
        { id: 760, code: '0760', name: 'transfuzjologia kliniczna', },
        { id: 761, code: '0761', name: 'transplantologia kliniczna', },
        { id: 729, code: '0729', name: 'urologia', },
        { id: 791, code: '0791', name: 'urologia dziecięca', },
        { id: 730, code: '0730', name: 'zdrowie publiczne', },
      ]
    }
  },

  methods: {
    mGetRole(id) {
      let pos = this.mRoles.find(s => s.id === id)
      if (pos) return pos.name
      else return `Brak roli [${id}]`
    },
    mGetRoleObject(id) {
      let pos = this.mRoles.find(s => s.id === id)
      if (pos) return pos
      else return null
    },
    mGetSpecialization(id) {
      let spec = this.mSpecializations.find(s => s.id === id)
      if (spec) return spec.name
      else return `Brak specjalizacji [${id}]`
    },
    mGetSpecializationObject(id) {
      let spec = this.mSpecializations.find(s => s.id === id)
      if (spec) return spec
      else return { id: id, code: id, name: `Brak [${id}]`, }
    }
  }
}
