import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        isInitialSet: false,
        resultConfig: null,
        isApkolceError: false,
        isApkolceWarning: false,
        apkolceLoginError: null,
        isCheckPassword: false,
    },

    mutations: {
        [Mutation.APKOLCE_SET_INITIAL](currentState) {
            currentState.isInitialSet = true
        },

        [Mutation.APKOLCE_SET_INITIAL_CONFIG](currentState, payload) {
            currentState.resultConfig = payload
        },

        [Mutation.APKOLCE_SET_CHECK_ERROR_STATUS](currentState, payload) {
            currentState.isApkolceError = payload
            currentState.isApkolceWarning = false
            if (!payload) {
                currentState.apkolceLoginError = null
            }
        },

        [Mutation.APKOLCE_SET_CHECK_ERROR](currentState, payload) {
            currentState.isApkolceError = true
            currentState.isApkolceWarning = false
            currentState.apkolceLoginError = payload
        },

        [Mutation.APKOLCE_SET_CHECK_WARNING](currentState, payload) {
            currentState.isApkolceError = false
            currentState.isApkolceWarning = true
            currentState.apkolceLoginError = payload
        },

        [Mutation.APKOLCE_SET_CHECK_LOGIN](currentState, payload) {
            currentState.isCheckPassword = payload
        },
    },

    actions: {
        [Action.APKOLCE_WORKERCREDS_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_CREATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_UPDATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_ADD_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_ADD_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_UPDATE_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_UPDATE_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_GET](context, workerGuid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_GET,
                        headers: { Authorization: "Bearer " + token },
                        params: {
                            workerId: workerGuid
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.APKOLCE_WORKERCREDS_GET_BY_WORKER.replace('{workerId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG
        [Action.APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG.replace('{workerId}', payload.workerId).replace('{domain}', payload.domainId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.APKOLCE_SET_INITIAL_CONFIG, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_UPDATE_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_UPDATE_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_WORKERCREDS_UPDATE_CONF_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_WORKERCREDS_UPDATE_CONF_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_ACTION_GET_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.APKOLCE_ACTION_GET_RESULT,
                        headers: { Authorization: "Bearer " + token },
                        params: {
                            id: id
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.APKOLCE_ACTION_CHECK_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.APKOLCE_ACTION_CHECK_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}