<template>
  <div>
    <b-button
      expanded
      @click="isActiveModal = true"
      type="is-orange"
      icon-left="plus-thick"
    >{{ buttonText }}</b-button>

    <b-modal
      :active.sync="isActiveModal"
      :destroy-on-hide="true"
      scroll="keep"
      has-modal-card
      :can-cancel="false"
      style="z-index: 100"
    >
      <div class="modal-card" style="overflow: visible">
        <header class="modal-card-head has-background-kin">
          <p class="subtitle has-text-primelight">
            Dodawanie badania radiologicznego - {{ clinicTypePropsName }},
            {{ officeFacilityName }}
          </p>
        </header>

        <section class="modal-card-body" style="color: black">
          <ContentPanel title="Usługa" icon="format-list-bulleted-square">
            <div style="min-height: 2.5rem">
              <template slot="addon">
                <b-tag v-if="ikzAdded.length < 1" type="is-danger" rounded>Wymagane</b-tag>
              </template>
              <b-field
                label="Szukaj usługi"
                label-position="on-border"
                open-on-focus
                v-if="ikzAdded.length == 0"
                :type="ikzAdded.length < 1 ? 'is-danger' : ''"
              >
                <b-autocomplete
                  :open-on-focus="true"
                  :data="filteredIkz"
                  clear-on-select
                  field="pharse"
                  placeholder="Zacznij pisać, aby wyświetlić sugerowane usługis"
                  @select="ikzSelected"
                  v-model="ikzFilter"
                >
                  <template slot-scope="props">
                    <div style="white-space: pre-wrap">
                      <b-tag
                        v-if="props.option.icd9"
                        type="is-secondary"
                        class="mr-2"
                      >{{ props.option.icd9 }}</b-tag>
                      <span style="has-text-weight-semibold">{{ props.option.name }}</span>
                      <small class="ml-1">{{ props.option.code }}</small>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
              <div v-for="(item, index) in ikzAdded" :key="item.index">
                <table class="w-100">
                  <tr>
                    <td style="width: 1rem; padding-top: 0.1rem">
                      <b-tag v-if="item.icd9" type="is-secondary" class="ml-1 mr-2">{{ item.icd9 }}</b-tag>
                    </td>
                    <td style="padding-top: 0.1rem">
                      <span style="has-text-weight-semibold">{{ item.name }}</span>
                    </td>
                    <td class="has-text-right">
                      <!-- <b-button
                                  icon-left="delete"
                                  type="is-danger"
                                  size="is-small"
                                  @click="removeIkz(index)"
                                  >Usuń</b-button
                      >-->
                      <b-tooltip label="Usuń" type="is-danger">
                        <b-icon
                          @click.native="removeIkz(index)"
                          icon="close-circle-outline"
                          type="is-danger"
                          style="cursor: pointer"
                        ></b-icon>
                      </b-tooltip>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ContentPanel>

          <RISWorkshopAddExamination
            v-if="selectClinicType != null"
            :selectClinicType="selectClinicType"
            @add-procedure="addExamination"
            :readonly="false"
            :examSuggestion="suggestedExaminationAdd"
            :deviceType="deviceType"
            :payerType="0"
            :referralType="{}"
          />

          <ContentPanel title="Dodane badania" icon="plus-outline">
            <b-table :data="referralProcedures" narrowed hoverable>
              <b-table-column v-slot="props" sortable field="description" label="Nazwa badania">
                <b-tag
                  v-if="props.row.icd9"
                  type="is-secondary"
                  class="ml-1 mr-2"
                >{{ props.row.icd9 }}</b-tag>
                {{ props.row.description }}
                <span v-if="props.row.bodySide">
                  , strona ciała:
                  <span class="has-text-weight-bold">{{ props.row.bodySide }}</span>
                </span>
              </b-table-column>
              <b-table-column v-slot="props" width="1" label="NFZ">
                <b-icon v-if="props.row.isNfz" type="is-success" icon="check-circle-outline"></b-icon>
                <b-icon size="is-small" v-else type="is-danger" icon="close-circle-outline"></b-icon>
              </b-table-column>
              <b-table-column v-slot="props" width="1">
                <b-tooltip label="Usuń" type="is-danger">
                  <b-icon
                    @click.native="referralProcedures.splice(props.index, 1)"
                    icon="close-circle-outline"
                    type="is-danger"
                    style="cursor: pointer"
                  ></b-icon>
                </b-tooltip>
                <!-- <b-button
                          icon-left="delete"
                          type="is-danger"
                          size="is-small"
                          @click="referralProcedures.splice(props.index, 1)"
                          >Usuń</b-button
                >-->
              </b-table-column>

              <template slot="empty">
                <p class="has-text-danger">Brak dodanych badań</p>
              </template>
            </b-table>
          </ContentPanel>
        </section>
        <footer class="modal-card-foot buttons is-right p-3">
          <b-button type="button" icon-left="close" @click="closeWindow">Anuluj</b-button>

          <b-button
            type="is-primary"
            :disabled="ikzAdded.length < 1 || referralProcedures.length < 1"
            @click="generateReferralTicket"
          >Realizuj</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import OrdersMixins from "@/mixins/orders/poz_orders";
import DateHelpers from "@/mixins/date_helpers";
import RISWorkshopAddExamination from "./RISWorkshopAddExaminationVer2";
import moment from "moment";
import ReferralMixins from "@/mixins/referrals.js";
import IkzExam from "@/mixins/ikz_exams.js";
import risMixins from "@/mixins/ris";

export default {
  name: "RISWorkshopAddVisitScheduleExaminations",
  mixins: [
    CommonHelpers,
    OrdersMixins,
    ReferralMixins,
    IkzExam,
    risMixins,
    DateHelpers,
  ],
  components: {
    RISWorkshopAddExamination,
  },

  props: {
    clinicType: { type: Number, required: true },
    deviceType: { type: Number, required: true },
    appointmentType: { type: Object, required: true },
    officeId: { type: String, required: false },
    appointmentPayer: { type: Number, required: false },
    officeType: { type: Object, required: false },
    patient: { type: Object, required: true },
  },

  data: function () {
    return {
      isActiveModal: false,

      buttonText: "... lub dodaj usługę i badania",

      ikzFilter: "",
      ikzAdded: [],
      ikzIncluded: [],

      suggestedExaminationAdd: [],

      referralProcedure: this.proceduresObject,
      selectClinicType: null,

      referralProcedures: [],

      ticketsToSend: [],
    };
  },

  mounted() {
    //this.getTypeClinics();

    if (this.clinicType) {
      let found = this.configClinics.find(
        (clinic) => clinic.id == this.clinicType
      );
      if (found) {
        this.selectClinicType = found;
      }
    }

    if (this.appointmentType) {
      this.ikzIncluded = this.mGetServicesForDevice(
        this.appointmentType.defaultDeviceType
      );
    }
  },

  watch: {
    currentClinicType() {
      this.selectClinicType = this.currentClinicType;
    },

    appointmentType(val) {
      if (val) {
        this.ikzIncluded = this.mGetServicesForDevice(
          this.appointmentType.defaultDeviceType
        );
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },
    offices() {
      return this.$store.state.offices.offices;
    },

    filteredIkz() {
      if (this.ikzFilter.length < 1) {
        return this.ikzs;
      } else {
        this.ikzs.forEach((element) => {
          element.apc = element.pharse
            .toString()
            .toLowerCase()
            .indexOf(this.ikzFilter.toLowerCase());
        });

        var nonfiltered = this.ikzs.filter((option) => {
          return option.apc >= 0;
        });
        var sorted = nonfiltered.sort((a, b) => {
          return a.apc - b.apc;
        });

        return sorted;
      }
    },

    ikzs() {
      if (this.appointmentType) {
        let selected = [];
        let all = this.$store.state.finance.ikz;

        all.forEach((element) => {
          if (this.ikzIncluded.includes(element.internalId)) {
            selected.push(element);
          }
        });

        return selected;
      } else {
        return this.$store.state.finance.ikz;
      }
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    currentClinicType() {
      if (this.clinicType) {
        let found = this.configClinics.find(
          (clinic) => clinic.id == this.clinicType
        );
        if (found) {
          return found;
        }
      }
      return null;
    },

    facilities() {
      return this.$store.state.clinic.clinics;
    },

    employeesAll() {
      return this.$store.state.employee.all ?? [];
    },

    clinicTypePropsName() {
      let found = this.configClinics.find(
        (clinic) => clinic.id == this.clinicType
      );
      if (found) {
        return found.shortName;
      }

      return "Brak informacji";
    },

    officeFacilityName() {
      let off = this.offices.find((office) => office.id == this.officeId);

      if (off) {
        return this.getFacility(off.facilityId);
      }

      return "Brak informacji";
    },

    filteredBodyAreas() {
      return this.mBodyAreas.filter((option) => {
        if (option.name) {
          return (
            option.name.toLowerCase().indexOf(this.bodyArea.toLowerCase()) >=
            0 ||
            option.codeOKC
              .toString()
              .toLowerCase()
              .indexOf(this.bodyArea.toLowerCase()) >= 0
          );
        } else return false;
      });
    },
  },

  methods: {
    ikzSelected(item) {
      this.ikzAdded.push(item);
      this.suggestedExaminationAdd = this.mGetIkzExam(item.internalId);
    },

    removeIkz(index) {
      this.ikzFilter = "";
      this.ikzAdded.splice(index, 1);
      this.referralProcedures = [];
      this.suggestedExaminationAdd = [-1];
    },

    closeWindow() {
      this.finishReferralP1();
    },

    finishReferralP1() {
      this.isActiveModal = false;
      this.referralProcedures = [];
      this.ikzAdded = [];
    },

    addExamination(exam) {
      if (!this.referralProcedures.includes(exam)) {
        this.referralProcedures.push(exam);
      } else {
        this.dangerSnackbar("Nie można dodać takiego samego badania");
      }
    },

    generateReferralTicket() {
      let ticket = JSON.parse(JSON.stringify(this.ticketObject));

      ticket.ikz = this.ikzAdded;
      ticket.procedures = [];
      ticket.patientId = this.patient.id;
      ticket.releaseDate = this.mNow();
      ticket.startDate = this.mNow();
      ticket.created = this.mNow();
      ticket.expiryDate = moment().add(2, "year"); // "2021-12-31T00:00:00.000Z";
      ticket.ticketType = 7230; //change to clinicId -> selectClinisType.code
      ticket.minMinutes = 10;
      ticket.isTimeless = false;
      ticket.ticketOffice = this.selectClinicType
        ? this.selectClinicType.code
        : "";
      ticket.payerType = this.appointmentPayer;
      ticket.isPaid = false;
      ticket.deviceType = this.deviceType;

      ticket.referral = null;

      this.ticketsToSend.length = 0;

      this.referralProcedures.forEach((exam) => {
        ticket.procedures.push(JSON.parse(JSON.stringify(exam)));

      });

      let copy = JSON.parse(JSON.stringify(ticket));
      this.ticketsToSend.push(copy);

      this.$emit("awaiting-create", this.ticketsToSend);
      this.isActiveModal = false;
      this.buttonText = "Edytuj usługę i badania";
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found != null ? found.name : `Brak (${id})`;
      } else return `Brak placówki (${id})`;
    },
  },
};
</script>

<style lang="scss">
</style>
