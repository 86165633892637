//import moment from 'moment'
import NationMixins from "@/mixins/registration/nationality_mixin.js";
import DataValidator from "@/mixins/dataValidators.js";

export default {
    mixins: [NationMixins, DataValidator],
    data: function () {
        return {
            /**Dane Er
             * Er Data
             */
            mErDataRequestObject: {
                "domain": null,
                "subDomain": null,
                "appointmentId": -1,
                "p1PatientData": {
                    "patientId": null,
                    "pesel_pacjenta": null,
                    "country": null,
                    "zip": null,
                    "city": null,
                    "street": null,
                    "housenumber": null,
                    "apartmentnumber": null,
                    "simc": null,
                    "firstname": null,
                    "secondname": null,
                    "lastname": null,
                    "gender": -1,
                    "birthdate": "0001-01-01T00:00:00",
                    "uprawnieniaDodatkowe": [],
                    "ewusCode": null,
                    "nfZ_code": null,
                    "isEwus": true,
                    "isChild": true,
                    "guardianFirstName": null,
                    "guardianLastName": null,
                    "identityType": -1,
                    "identityNumber": null,
                    "identityCountryISOCode": -1,
                    "dodatkoweDokumentyRefundacyjne": [],
                    "telephoneNumber": null
                },
                "p1ERejestracjaAction": -1,
                "p1ERejestracjaStatus": 0,
                "p1Wizyta": null,
                "p1ERejestracjaStatusChange": "0001-01-01T00:00:00",
                "workerGuid": null
            },

            mErP1Wizyta: {
                "dataCzasDo": "0001-01-01T00:00:00",
                "dataCzasOd": "0001-01-01T00:00:00",
                "identyfikatorHarmonogramu": {
                    "extension": null,
                    "root": null
                },
                "identyfikatorWizyty": {
                    "extension": null,
                    "root": null
                },
                "kodStatusuWizyty": null,
                "comment": null
            }
        }
    },
    methods: {
        /**Metoda tworzenia obiektu ZM
         * Method for creating ZM object
         */
        mCreateErClosDataObject(appointment, subunit) {
            let result = JSON.parse(JSON.stringify(this.mErDataRequestObject));
            result.appointmentId = appointment.id
            result.domain = appointment.domainId.toString();
            result.subDomain = subunit.departmentalCodes?.identifierCode7th ?? null;
            result.p1ERejestracjaAction = 28;
            result.p1Wizyta = JSON.parse(JSON.stringify(this.mErP1Wizyta));

            result.p1Wizyta.dataCzasDo = appointment.stop;
            result.p1Wizyta.dataCzasOd = appointment.start;
            result.p1Wizyta.identyfikatorHarmonogramu.extension = appointment.erHarmId;
            result.p1Wizyta.identyfikatorWizyty.extension = appointment.erId;
            result.p1Wizyta.kodStatusuWizyty = "06"
            result.p1Wizyta.comment = "Wizyta zrealizowana"

            return result;
        }
    }
}