export default {
  data: function () {
    return {
      /**Usługi powiązane z badaniami i urządzeniami w RIS */
      mIkzExams: [
        { ikz: 1, exams: [1368, 1370, 1371, 2192], devices: [3] },
        { ikz: 2, exams: [1396], devices: [3] },
        { ikz: 3, exams: [-1], devices: [3] },
        { ikz: 4, exams: [2189], devices: [6] },
        { ikz: 5, exams: [2192], devices: [3] },
        { ikz: 6, exams: [1366], devices: [3] },
        { ikz: 7, exams: [-1], devices: [3] },
        { ikz: 8, exams: [1345], devices: [3] },
        { ikz: 9, exams: [1757], devices: [3] },
        { ikz: 10, exams: [1494], devices: [3] },
        { ikz: 11, exams: [1436, 1437, 1438, 1439, 1440, 1441, 1442, 1443, 1444, 1445, 1446, 1752, 1753, 1754, 1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766, 1767, 1768, 2199, 2200], devices: [3] },
        { ikz: 12, exams: [1442, 1760, 1761], devices: [3] },
        { ikz: 13, exams: [1336], devices: [3] },
        { ikz: 14, exams: [1345], devices: [3] },
        { ikz: 15, exams: [2194], devices: [3] },
        { ikz: 16, exams: [1434], devices: [3] },
        { ikz: 17, exams: [1331, 1332, 1333, 1334, 1335, 1336, 1337, 1730, 1731, 1732, 1733, 1847], devices: [3] },
        { ikz: 18, exams: [1329, 1330, 1334, 1335, 2182], devices: [3] },
        { ikz: 19, exams: [1494], devices: [3] },
        { ikz: 20, exams: [1494], devices: [3] },
        { ikz: 21, exams: [1494], devices: [3] },
        { ikz: 22, exams: [1846, 1494], devices: [3] },
        { ikz: 23, exams: [1494], devices: [3] },
        { ikz: 24, exams: [1494], devices: [3] },
        { ikz: 25, exams: [1494], devices: [3] },
        { ikz: 26, exams: [-1], devices: [3] },
        { ikz: 27, exams: [-1], devices: [3] },
        { ikz: 28, exams: [-1], devices: [3] },
        { ikz: 29, exams: [1343, 1738, 1739, 2186], devices: [3] },
        { ikz: 30, exams: [2190], devices: [3] },
        { ikz: 31, exams: [1365, 2190], devices: [3] },
        { ikz: 32, exams: [1365], devices: [3] },
        { ikz: 33, exams: [1367], devices: [3] },
        { ikz: 34, exams: [1478], devices: [3] },
        { ikz: 35, exams: [1752], devices: [3] },
        { ikz: 36, exams: [1752], devices: [3] },
        { ikz: 37, exams: [1752], devices: [3] },
        { ikz: 38, exams: [1851], devices: [3] },
        { ikz: 39, exams: [1851], devices: [3] },
        { ikz: 40, exams: [1761], devices: [3] },
        { ikz: 41, exams: [1761], devices: [3] },
        { ikz: 42, exams: [1761], devices: [3] },
        { ikz: 43, exams: [1761], devices: [3] },
        { ikz: 44, exams: [1438, 1756, 1753, 1755], devices: [3] },
        { ikz: 45, exams: [1494], devices: [3] },
        { ikz: 46, exams: [1384], devices: [3] },
        { ikz: 47, exams: [1382], devices: [3] },
        { ikz: 48, exams: [1345], devices: [3] },
        { ikz: 49, exams: [1352], devices: [4] },
        { ikz: 50, exams: [1329], devices: [3] },
        { ikz: 51, exams: [1352, 1353, 1354, 2187], devices: [4] },
        { ikz: 52, exams: [-1], devices: [3] },
        { ikz: 53, exams: [1735, 1737, 1739], devices: [3] },
        { ikz: 54, exams: [-1], devices: [3] },
        { ikz: 55, exams: [1428, 1730, 1733, 1734, 17361738, 1758, 1767, 1846], devices: [3] },
        { ikz: 56, exams: [1399], devices: [3] },
        { ikz: 57, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 58, exams: [1494], devices: [3] },
        { ikz: 59, exams: [1478], devices: [3] },
        { ikz: 60, exams: [1752], devices: [3] },
        { ikz: 61, exams: [1372, 1373, 1375, 1376, 1377], devices: [3] },
        { ikz: 62, exams: [-1], devices: [3] },
        { ikz: 63, exams: [1368, 1369, 2191], devices: [3] },
        { ikz: 64, exams: [-1], devices: [3] },
        { ikz: 65, exams: [1444, 1765, 1766], devices: [3] },
        { ikz: 66, exams: [1353], devices: [4] },
        { ikz: 67, exams: [1763], devices: [3] },
        { ikz: 68, exams: [1339, 2184], devices: [3] },
        { ikz: 69, exams: [1341, 2185], devices: [3] },
        { ikz: 70, exams: [1343, 2186], devices: [3] },
        { ikz: 71, exams: [-1], devices: [3] },
        { ikz: 72, exams: [1439, 1752], devices: [3] },
        { ikz: 73, exams: [1442, 1760, 1761], devices: [3] },
        { ikz: 74, exams: [1757], devices: [3] },
        { ikz: 75, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 76, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 77, exams: [1443, 1762, 1763, 1764], devices: [3] },
        { ikz: 78, exams: [1325], devices: [3] },
        { ikz: 79, exams: [1330], devices: [3] },
        { ikz: 80, exams: [1481], devices: [3] },
        { ikz: 81, exams: [1483], devices: [3] },
        { ikz: 82, exams: [1368, 1369, 2191], devices: [3] },
        { ikz: 83, exams: [1413, 1415, 1416, 1423], devices: [6] },
        { ikz: 84, exams: [1368, 2191, 2192], devices: [3] },
        { ikz: 85, exams: [1436, 1437, 1439, 1440, 1753, 1754, 1755, 1758, 1759], devices: [3] },
        { ikz: 86, exams: [1343, 1738, 1739], devices: [3] },
        { ikz: 87, exams: [1444, 1765, 1766], devices: [3] },
        { ikz: 88, exams: [1757, 1438, 1756], devices: [3] },
        { ikz: 89, exams: [1443, 1764], devices: [3] },
        { ikz: 90, exams: [1446, 2200], devices: [3] },
        { ikz: 91, exams: [1440, 2199], devices: [3] },
        { ikz: 92, exams: [1350, 1352, 1353, 1354, 2187], devices: [4] },
        { ikz: 93, exams: [1353, 1354, 2187], devices: [4] },
        { ikz: 94, exams: [1494], devices: [3] },
        { ikz: 95, exams: [1494], devices: [3] },
        { ikz: 96, exams: [1385, 2194], devices: [3] },
        { ikz: 97, exams: [1396], devices: [3] },
        { ikz: 98, exams: [2192, 2191, 1368], devices: [3] },
        { ikz: 99, exams: [1434], devices: [3] },
        { ikz: 100, exams: [1354], devices: [4] },
        { ikz: 101, exams: [1317], devices: [3] },
        { ikz: 102, exams: [1318], devices: [3] },
        { ikz: 103, exams: [1319], devices: [3] },
        { ikz: 104, exams: [1320], devices: [3] },
        { ikz: 105, exams: [1321], devices: [3] },
        { ikz: 106, exams: [1322], devices: [3] },
        { ikz: 107, exams: [1323], devices: [3] },
        { ikz: 108, exams: [1334], devices: [3] },
        { ikz: 109, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 110, exams: [1336], devices: [3] },
        { ikz: 111, exams: [1337, 1733], devices: [3] },
        { ikz: 112, exams: [1341], devices: [3] },
        { ikz: 113, exams: [1342, 1736], devices: [3] },
        { ikz: 114, exams: [1339], devices: [3] },
        { ikz: 115, exams: [1340, 1734], devices: [3] },
        { ikz: 116, exams: [1343], devices: [3] },
        { ikz: 117, exams: [1738], devices: [3] },
        { ikz: 118, exams: [1352], devices: [4] },
        { ikz: 119, exams: [1370], devices: [3] },
        { ikz: 120, exams: [1371], devices: [3] },
        { ikz: 121, exams: [1403], devices: [3] },
        { ikz: 122, exams: [1427], devices: [3] },
        { ikz: 123, exams: [1428], devices: [3] },
        { ikz: 124, exams: [1434], devices: [3] },
        { ikz: 125, exams: [1439, 1758], devices: [3] },
        { ikz: 126, exams: [1445, 1767], devices: [3] },
        { ikz: 127, exams: [1317], devices: [3] },
        { ikz: 128, exams: [1732, 1337], devices: [3] },
        { ikz: 129, exams: [1753], devices: [3] },
        { ikz: 130, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 131, exams: [1329], devices: [3] },
        { ikz: 132, exams: [-1], devices: [3] },
        { ikz: 133, exams: [1442, 1760, 1761], devices: [3] },
        { ikz: 134, exams: [1763], devices: [3] },
        { ikz: 135, exams: [-1], devices: [3] },
        { ikz: 136, exams: [1735], devices: [3] },
        { ikz: 137, exams: [1339, 1340, 1734], devices: [3] },
        { ikz: 138, exams: [1341], devices: [3] },
        { ikz: 139, exams: [1341, 1342, 1736], devices: [3] },
        { ikz: 140, exams: [1341, 1342, 1736], devices: [3] },
        { ikz: 141, exams: [1341], devices: [3] },
        { ikz: 142, exams: [1341], devices: [3] },
        { ikz: 143, exams: [1343], devices: [3] },
        { ikz: 144, exams: [1343], devices: [3] },
        { ikz: 145, exams: [1343], devices: [3] },
        { ikz: 146, exams: [1739], devices: [3] },
        { ikz: 147, exams: [1343, 1738], devices: [3] },
        { ikz: 148, exams: [1850], devices: [3] },
        { ikz: 149, exams: [1850], devices: [3] },
        { ikz: 150, exams: [1850], devices: [3] },
        { ikz: 151, exams: [1850], devices: [3] },
        { ikz: 152, exams: [1850], devices: [3] },
        { ikz: 153, exams: [1368, 2191, 2192], devices: [3] },
        { ikz: 154, exams: [1368, 2191, 2192], devices: [3] },
        { ikz: 155, exams: [1368, 2191, 2192], devices: [3] },
        { ikz: 156, exams: [1350], devices: [4] },
        { ikz: 157, exams: [1721], devices: [12] },
        { ikz: 158, exams: [1723], devices: [12] },
        { ikz: 159, exams: [1368, 2192], devices: [3] },
        { ikz: 160, exams: [1368, 2191, 2192], devices: [3] },
        { ikz: 161, exams: [1365], devices: [3] },
        { ikz: 162, exams: [1365], devices: [3] },
        { ikz: 163, exams: [1365], devices: [3] },
        { ikz: 164, exams: [1752], devices: [3] },
        { ikz: 165, exams: [1752], devices: [3] },
        { ikz: 166, exams: [1752], devices: [3] },
        { ikz: 167, exams: [1478], devices: [3] },
        { ikz: 168, exams: [1478], devices: [3] },
        { ikz: 169, exams: [1442], devices: [3] },
        { ikz: 170, exams: [1761], devices: [3] },
        { ikz: 171, exams: [1761], devices: [3] },
        { ikz: 172, exams: [1761], devices: [3] },
        { ikz: 173, exams: [1761], devices: [3] },
        { ikz: 174, exams: [1761], devices: [3] },
        { ikz: 175, exams: [1761], devices: [3] },
        { ikz: 176, exams: [1761], devices: [3] },
        { ikz: 177, exams: [1851], devices: [3] },
        { ikz: 178, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 179, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 180, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 181, exams: [1763], devices: [3] },
        { ikz: 182, exams: [1763], devices: [3] },
        { ikz: 183, exams: [1368], devices: [3] },
        { ikz: 184, exams: [1336, 1337, 1733], devices: [3] },
        { ikz: 185, exams: [1847], devices: [3] },
        { ikz: 186, exams: [1847], devices: [3] },
        { ikz: 187, exams: [1334, 1335, 1730], devices: [3] },
        { ikz: 188, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 189, exams: [1847], devices: [3] },
        { ikz: 190, exams: [1733], devices: [3] },
        { ikz: 191, exams: [1733], devices: [3] },
        { ikz: 192, exams: [1733], devices: [3] },
        { ikz: 193, exams: [1733], devices: [3] },
        { ikz: 194, exams: [1733], devices: [3] },
        { ikz: 195, exams: [1846], devices: [3] },
        { ikz: 196, exams: [1339], devices: [3] },
        { ikz: 197, exams: [1339], devices: [3] },
        { ikz: 198, exams: [1339], devices: [3] },
        { ikz: 199, exams: [1763], devices: [3] },
        { ikz: 200, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 201, exams: [1763], devices: [3] },
        { ikz: 202, exams: [1763], devices: [3] },
        { ikz: 203, exams: [1763], devices: [3] },
        { ikz: 204, exams: [1763], devices: [3] },
        { ikz: 205, exams: [1764], devices: [3] },
        { ikz: 206, exams: [1764], devices: [3] },
        { ikz: 207, exams: [1764], devices: [3] },
        { ikz: 208, exams: [1329], devices: [3] },
        { ikz: 209, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 210, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 211, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 212, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 213, exams: [1445, 1767, 1768, 2200], devices: [3] },
        { ikz: 214, exams: [1766], devices: [3] },
        { ikz: 215, exams: [1766], devices: [3] },
        { ikz: 216, exams: [1766], devices: [3] },
        { ikz: 217, exams: [1766], devices: [3] },
        { ikz: 218, exams: [1766], devices: [3] },
        { ikz: 219, exams: [1766], devices: [3] },
        { ikz: 220, exams: [1766], devices: [3] },
        { ikz: 221, exams: [1766], devices: [3] },
        { ikz: 222, exams: [1766], devices: [3] },
        { ikz: 223, exams: [1753], devices: [3] },
        { ikz: 224, exams: [1753], devices: [3] },
        { ikz: 225, exams: [1754], devices: [3] },
        { ikz: 226, exams: [1754], devices: [3] },
        { ikz: 227, exams: [-1], devices: [3] },
        { ikz: 228, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 229, exams: [1755], devices: [3] },
        { ikz: 230, exams: [1755], devices: [3] },
        { ikz: 231, exams: [1438, 1756, 1757], devices: [3] },
        { ikz: 232, exams: [1756], devices: [3] },
        { ikz: 233, exams: [1756], devices: [3] },
        { ikz: 234, exams: [1756], devices: [3] },
        { ikz: 235, exams: [1756], devices: [3] },
        { ikz: 236, exams: [1439, 1440], devices: [3] },
        { ikz: 237, exams: [1439, 1440], devices: [3] },
        { ikz: 238, exams: [1439, 1440], devices: [3] },
        { ikz: 239, exams: [1439, 1440], devices: [3] },
        { ikz: 240, exams: [1439, 1440], devices: [3] },
        { ikz: 241, exams: [1757], devices: [3] },
        { ikz: 242, exams: [1757], devices: [3] },
        { ikz: 243, exams: [1757], devices: [3] },
        { ikz: 244, exams: [1851], devices: [3] },
        { ikz: 245, exams: [1368], devices: [3] },
        { ikz: 246, exams: [-1], devices: [3] },
        { ikz: 247, exams: [-1], devices: [3] },
        { ikz: 248, exams: [-1], devices: [3] },
        { ikz: 249, exams: [-1], devices: [3] },
        { ikz: 250, exams: [1331, 1332, 1333, 1336, 1337, 1732, 1733], devices: [3] },
        { ikz: 251, exams: [1329], devices: [3] },
        { ikz: 252, exams: [1434], devices: [3] },
        { ikz: 253, exams: [1445, 1767, 1768, 1763], devices: [3] },
        { ikz: 254, exams: [1484], devices: [6] },
        { ikz: 255, exams: [1350, 1352, 1353, 1354], devices: [4] },
        { ikz: 256, exams: [1484], devices: [6] },
        { ikz: 257, exams: [1305], devices: [6] },
        { ikz: 258, exams: [1437, 1754], devices: [3] },
        { ikz: 259, exams: [1437, 1755], devices: [3] },
        { ikz: 260, exams: [1436], devices: [3] },
        { ikz: 261, exams: [1847], devices: [3] },
        { ikz: 262, exams: [1365, 1366, 1367], devices: [3] },
        { ikz: 263, exams: [1343], devices: [3] },
        { ikz: 264, exams: [1341], devices: [3] },
        { ikz: 265, exams: [1339], devices: [3] },
        { ikz: 266, exams: [1331, 1332, 1333, 1336, 1337, 1732, 1733], devices: [3] },
        { ikz: 267, exams: [1445, 1768], devices: [3] },
        { ikz: 268, exams: [1439, 1759], devices: [3] },
        { ikz: 269, exams: [1739], devices: [3] },
        { ikz: 270, exams: [1342, 1737], devices: [3] },
        { ikz: 271, exams: [1340, 1735], devices: [3] },
        { ikz: 272, exams: [1434, 1435], devices: [3] },
        { ikz: 273, exams: [1436], devices: [3] },
        { ikz: 274, exams: [1437, 1754, 1755], devices: [3] },
        { ikz: 275, exams: [1350, 1352, 1353, 1354], devices: [4] },
        { ikz: 276, exams: [1329], devices: [3] },
        { ikz: 277, exams: [1350, 1352, 1353, 1354], devices: [4] },
        { ikz: 278, exams: [1739], devices: [3] },
        { ikz: 279, exams: [1302], devices: [6] },
        { ikz: 280, exams: [1448], devices: [6] },
        { ikz: 281, exams: [1449], devices: [6] },
        { ikz: 282, exams: [1450], devices: [6] },
        { ikz: 283, exams: [1451], devices: [6] },
        { ikz: 284, exams: [1452], devices: [6] },
        { ikz: 285, exams: [1453], devices: [6] },
        { ikz: 286, exams: [1329], devices: [3] },
        { ikz: 287, exams: [1329], devices: [3] },
        { ikz: 288, exams: [1330], devices: [3] },
        { ikz: 289, exams: [1367], devices: [3] },
        { ikz: 290, exams: [1367], devices: [3] },
        { ikz: 291, exams: [1367], devices: [3] },
        { ikz: 292, exams: [1367], devices: [3] },
        { ikz: 293, exams: [1367], devices: [3] },
        { ikz: 294, exams: [1367], devices: [3] },
        { ikz: 295, exams: [1367], devices: [3] },
        { ikz: 296, exams: [1478], devices: [3] },
        { ikz: 297, exams: [1478], devices: [3] },
        { ikz: 298, exams: [1478], devices: [3] },
        { ikz: 299, exams: [1478], devices: [3] },
        { ikz: 300, exams: [1324], devices: [3] },
        { ikz: 301, exams: [1334], devices: [3] },
        { ikz: 302, exams: [1334], devices: [3] },
        { ikz: 303, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 304, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 305, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 306, exams: [1335, 1730, 1731, 1847], devices: [3] },
        { ikz: 307, exams: [1336], devices: [3] },
        { ikz: 308, exams: [1337, 1732, 1733], devices: [3] },
        { ikz: 309, exams: [1337, 1732, 1733], devices: [3] },
        { ikz: 310, exams: [1337, 1732, 1733], devices: [3] },
        { ikz: 311, exams: [1337, 1732, 1733], devices: [3] },
        { ikz: 312, exams: [1337, 1732, 1733], devices: [3] },
        { ikz: 313, exams: [1365], devices: [3] },
        { ikz: 314, exams: [1365], devices: [3] },
        { ikz: 315, exams: [1365], devices: [3] },
        { ikz: 316, exams: [1366], devices: [3] },
        { ikz: 317, exams: [1366], devices: [3] },
        { ikz: 318, exams: [1366], devices: [3] },
        { ikz: 319, exams: [1371], devices: [3] },
        { ikz: 320, exams: [1427], devices: [3] },
        { ikz: 321, exams: [1427], devices: [3] },
        { ikz: 322, exams: [1427], devices: [3] },
        { ikz: 323, exams: [1428], devices: [3] },
        { ikz: 324, exams: [1428], devices: [3] },
        { ikz: 325, exams: [1428], devices: [3] },
        { ikz: 326, exams: [1434], devices: [3] },
        { ikz: 327, exams: [1434], devices: [3] },
        { ikz: 328, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 329, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 330, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 331, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 332, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 333, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 334, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 335, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 336, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 337, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 338, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 339, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 340, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 341, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 342, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 343, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 344, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 345, exams: [1439, 1758, 1759], devices: [3] },
        { ikz: 346, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 347, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 348, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 349, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 350, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 351, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 352, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 353, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 354, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 355, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 356, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 357, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 358, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 359, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 360, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 361, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 362, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 363, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 364, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 365, exams: [1445, 1767, 1768], devices: [3] },
        { ikz: 366, exams: [1335, 1730, 1731], devices: [3] },
        { ikz: 367, exams: [1335, 1730, 1731], devices: [3] },
        { ikz: 368, exams: [1340, 1734, 1735], devices: [3] },
        { ikz: 369, exams: [1340, 1734, 1735], devices: [3] },
        { ikz: 370, exams: [1340, 1734, 1735], devices: [3] },
        { ikz: 371, exams: [1340, 1734, 1735], devices: [3] },
        { ikz: 372, exams: [1341], devices: [3] },
        { ikz: 373, exams: [1341], devices: [3] },
        { ikz: 374, exams: [1342, 1736, 1737], devices: [3] },
        { ikz: 375, exams: [1342, 1736, 1737], devices: [3] },
        { ikz: 376, exams: [1738, 1739], devices: [3] },
        { ikz: 377, exams: [1738, 1739], devices: [3] },
        { ikz: 378, exams: [1738, 1739], devices: [3] },
        { ikz: 379, exams: [1738, 1739], devices: [3] },
        { ikz: 380, exams: [1738, 1739], devices: [3] },
        { ikz: 381, exams: [1738, 1739], devices: [3] },
        { ikz: 382, exams: [1738, 1739], devices: [3] },
        { ikz: 383, exams: [1738, 1739], devices: [3] },
        { ikz: 384, exams: [1343], devices: [3] },
        { ikz: 385, exams: [1343], devices: [3] },
        { ikz: 386, exams: [1339], devices: [3] },
        { ikz: 387, exams: [1339], devices: [3] },
        { ikz: 388, exams: [1357], devices: [6] },
        { ikz: 389, exams: [1415], devices: [6] },
        { ikz: 390, exams: [1294], devices: [6] },
        { ikz: 391, exams: [1304], devices: [6] },
        { ikz: 392, exams: [1305], devices: [6] },
        { ikz: 393, exams: [1487], devices: [6] },
        { ikz: 394, exams: [1488], devices: [6] },
        { ikz: 395, exams: [1489], devices: [6] },
        { ikz: 396, exams: [1490], devices: [6] },
        { ikz: 397, exams: [1491], devices: [6] },
        { ikz: 398, exams: [1492], devices: [6] },
        { ikz: 399, exams: [1289], devices: [6] },
        { ikz: 400, exams: [1358], devices: [6] },
        { ikz: 401, exams: [1305], devices: [6] },
        { ikz: 402, exams: [2189, 1412], devices: [6] },
        { ikz: 403, exams: [1358, 1359, 1413, 1414, 1420], devices: [6] },
        { ikz: 404, exams: [1358], devices: [6] },
        { ikz: 405, exams: [1293], devices: [6] },
        { ikz: 406, exams: [1290], devices: [6] },
        { ikz: 407, exams: [1302], devices: [6] },
        { ikz: 408, exams: [1291], devices: [6] },
        { ikz: 409, exams: [1292], devices: [6] },
        { ikz: 410, exams: [1303], devices: [6] },
        { ikz: 411, exams: [1295], devices: [6] },
        { ikz: 412, exams: [1296], devices: [6] },
        { ikz: 413, exams: [1410], devices: [6] },
        { ikz: 414, exams: [1411], devices: [6] },
        { ikz: 415, exams: [1416], devices: [6] },
        { ikz: 416, exams: [1414], devices: [6] },
        { ikz: 417, exams: [1299], devices: [6] },
        { ikz: 418, exams: [1300], devices: [6] },
        { ikz: 419, exams: [1301], devices: [6] },
        { ikz: 420, exams: [1362], devices: [6] },
        { ikz: 421, exams: [1422], devices: [6] },
        { ikz: 422, exams: [1448], devices: [6] },
        { ikz: 423, exams: [1449], devices: [6] },
        { ikz: 424, exams: [1450], devices: [6] },
        { ikz: 425, exams: [1452], devices: [6] },
        { ikz: 426, exams: [-1], devices: [6] },
        { ikz: 427, exams: [1720], devices: [-1] },
        { ikz: 428, exams: [1455], devices: [6] },
        { ikz: 429, exams: [1456], devices: [6] },
        { ikz: 430, exams: [1457], devices: [6] },
        { ikz: 431, exams: [1459], devices: [6] },
        { ikz: 432, exams: [1460], devices: [6] },
        { ikz: 433, exams: [1486], devices: [6] },
        { ikz: 434, exams: [1462], devices: [6] },
        { ikz: 435, exams: [1463], devices: [6] },
        { ikz: 436, exams: [1464], devices: [6] },
        { ikz: 437, exams: [1465], devices: [6] },
        { ikz: 438, exams: [1466], devices: [6] },
        { ikz: 439, exams: [1467], devices: [6] },
        { ikz: 440, exams: [1289, 1357], devices: [6] },
        { ikz: 441, exams: [1289, 1489], devices: [6] },
        { ikz: 442, exams: [1469], devices: [6] },
        { ikz: 443, exams: [1470], devices: [6] },
        { ikz: 444, exams: [1471], devices: [6] },
        { ikz: 445, exams: [1472], devices: [6] },
        { ikz: 446, exams: [1473], devices: [6] },
        { ikz: 447, exams: [1474], devices: [6] },
        { ikz: 448, exams: [1412], devices: [6] },
        { ikz: 449, exams: [1489, 1491], devices: [6] },
        { ikz: 450, exams: [1487, 1491], devices: [6] },
        { ikz: 451, exams: [1478, 1489], devices: [6] },
        { ikz: 452, exams: [1487, 1289], devices: [6] },
        { ikz: 453, exams: [1289, 1491], devices: [6] },
        { ikz: 454, exams: [1289, 1357], devices: [6] },
        { ikz: 455, exams: [1416, 1492], devices: [6] },
        { ikz: 456, exams: [-1], devices: [6] },
        { ikz: 457, exams: [1293, 1305], devices: [6] },
        { ikz: 458, exams: [1358, 1416, 1293], devices: [6] },
        { ikz: 459, exams: [1358, 1294], devices: [6] },
        { ikz: 460, exams: [2244], devices: [6] },
        // TK PROGRAM "TOMOGRAFIA PŁUC"
        { ikz: 1336, exams: [2244], devices: [6] },

        { ikz: 461, exams: [1837], devices: [5] },
        { ikz: 462, exams: [1838], devices: [5] },
        { ikz: 859, exams: [1838], devices: [5] },
        { ikz: 856, exams: [1840], devices: [5] },
        { ikz: 726, exams: [], devices: [6] },
        { ikz: 727, exams: [], devices: [6] },
        { ikz: 728, exams: [], devices: [6] },
        { ikz: 729, exams: [], devices: [6] },
        { ikz: 730, exams: [], devices: [6] },
        { ikz: 731, exams: [], devices: [6] },
        { ikz: 732, exams: [], devices: [6] },
        // { ikz: 733, exams: [], devices: [6] },
        // { ikz: 734, exams: [], devices: [6] },
        { ikz: 735, exams: [], devices: [6] },
        { ikz: 736, exams: [], devices: [6] },
        { ikz: 737, exams: [], devices: [6] },
        { ikz: 1006, exams: [], devices: [6] },
        { ikz: 1007, exams: [], devices: [6] },
        
        // USG - devices [0]
        { ikz: 1058, exams: [1542, 1539, 1540], devices: [0] },
        // { ikz: 1059, exams: [], devices: [0] },
        { ikz: 1297, exams: [1556], devices: [0] },
        { ikz: 1320, exams: [1579, 1593, 1594], devices: [0] },
        // { ikz: 1321, exams: [], devices: [0] },
        { ikz: 1330, exams: [1541, 3254, 1545, 1586], devices: [0] },
        // { ikz: 1331, exams: [1545], devices: [0] }, // ????
        // { ikz: 1332, exams: [1566, 1567, 1568], devices: [0] }, // ????
        { ikz: 1333, exams: [1577, 1569, 1572, 1566, 1567, 1568], devices: [0] },
        // { ikz: 1334, exams: [1586], devices: [0] }, // ????
        { ikz: 1335, exams: [1570, 1595], devices: [0] },
        
        { ikz: 1352, exams: [1556, 1586, 1588, 1585, 1581], devices: [0], showGynecologistUSG: true },

        { ikz: 1435, exams: [1567, 1569, 1572, 1568], devices: [0], showNephrologistUSG: true },

      ]
    }
  },

  methods: {
    mGetIkzExam(ikz) {
      if (ikz) {
        let spec = this.mIkzExams.find(x => x.ikz === ikz)
        return spec ? spec.exams : []
      }
      else return []
    },

    mGetServicesForDevice(element) {
      return this.mIkzExams.filter(x => x.devices.includes(element)).map(function (v) { return v.ikz; });
    },

    mGetServicesForGynecologistUSG() {
      return this.mIkzExams.filter(x => x.showGynecologistUSG && x.devices.includes(0)).map(function (v) { return v.ikz; });
    },

    mGetServicesForNephrologistUSG() {
      return this.mIkzExams.filter(x => x.showNephrologistUSG && x.devices.includes(0)).map(function (v) { return v.ikz; });
    },

    mGetServicesForDevices(devs) {
      let services = []

      if (devs && devs.length > 0) {
        devs.forEach(element => {
          let spec = this.mIkzExams.find(x => x.devices.includes(element))

          if (spec) {
            if (services.includes(spec)) {
              // ignore
            }
            else {
              services.push(spec)
            }
          }
        });
      }

      return services
    }
  }
}
