<template>
  <div>
    <b-tooltip label="Zmień hasło w systemie EZWM" position="is-left"><b-button
        type="is-primary"
        icon-left="account-key-outline"
        size="is-small"
        @click="openModal()"
        >Zmień hasło w EZWM</b-button
      ></b-tooltip>
    <b-modal
        :active.sync="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
        scroll="keep"
      >
        <div class="modal-card" style="overflow: visible;">
          <ValidationObserver ref="observer" v-slot="{ invalid }">
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-light">
              Zmiana hasła EZWM
            </p>
          </header>
          <section class="modal-card-body m-0">
            <div>
              <b-message v-if="isError" type="is-danger">
                <div v-if="timeOut">
                  Przesyłanie przekroczyło założony czas oczekiwania.
                  <b-button v-if="timeOut" size="is-small" icon-left="refresh" @click="refreshResult">Odśwież</b-button>
                </div>
                <div v-else style="font-size: 0.8rem" v-html="errorMessage"></div>            
              </b-message>
              <b-message v-if="isSuccess" type="is-success">
                <div style="font-size: 0.8rem">Hasło zmienione</div> 
                <div v-if="resultRequest && resultRequest.ezwmMessages && resultRequest.ezwmMessages.length>0">
                  <p  v-for="(message, error_index) in resultRequest.ezwmMessages" :key="error_index" style="font-size: 0.8rem;white-space: pre-wrap;word-break: break-word;">
                    {{message.text}}
                  </p> 
                </div>          
              </b-message>
              <form v-if="isComponentModalActive">
                
                  <b-field v-if="!isExpired" class="mb-2">
                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                      <b-field
                        :label="t('common.currentPassword')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="t(errors[0], { min:6, max:32 })"
                        label-position="on-border"
                      >
                        <b-input
                          v-model="oldPassword"
                          :disabled="requestInProgress"
                          type="password"
                          icon="eye-off-outline"
                          expanded
                          password-reveal
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>
                  <b-field class="mb-2">
                    <ValidationProvider
                      rules="required|userpassEzwm|lengthMinMax:14,120"
                      v-slot="{ errors, valid }"
                      vid="confirmation"
                    >
                      <b-field
                        :label="t('common.newPassword')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="t(errors[0], { min:14, max:120 })"
                        label-position="on-border"
                      >
                        <b-input
                          v-model="password"
                          :disabled="requestInProgress"
                          type="password"
                          icon="eye-off-outline"
                          expanded
                          password-reveal
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>
                  <b-field class="mb-2">
                    <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors, valid }">
                      <b-field
                        :label="t('common.repeatPassword')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="t(errors[0])"
                        label-position="on-border"
                      >
                        <b-input
                          v-model="passwordRepeat"
                          :disabled="requestInProgress"
                          type="password"
                          icon="eye-off-outline"
                          expanded
                          password-reveal
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </b-field>

                  <div class="mb-1">
                    <p style="font-size: 0.8rem" v-if="loadingAction">
                      [{{ status.status }}] {{ getStatus(status.status) }}
                      {{ mDateTime(status.date) }}
                    </p>
                    <p
                      v-for="item in errors"
                      :key="item.index"
                      class="has-text-danger has-text-centered"
                    >{{ item }}</p>
                  </div>                
              </form>
            </div>
          </section>
          <footer class="modal-card-foot buttons is-right">
            <div>
              <b-button
                class="button"
                type="button"
                @click="closeModal"
                >Zamknij</b-button
              >
               <b-button
                  class
                  type="is-primary"
                  :loading="requestInProgress || loadingAction || timeOut"
                  :disabled="invalid || isSuccess"
                  @click="ezwmPasswordChange"
                >Wyślij</b-button>
            </div>
          </footer>
          </ValidationObserver>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { Action } from '../../store/config_actions'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CommonMixins from '../../mixins/commons'

export default {
  name: 'MyAccountChangeEZWMPassword',

  mixins: [CommonMixins],

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    domain: { type: String, required: true },
    configurationId: { type: Number, required: true },
    isExpired: {type: Boolean, required: false, default: false},
    isCloseClear: {type: Boolean, required: false, default: true},
  },

  data: function () {
    return {
      oldPassword: null,
      password: null,
      passwordRepeat: null,
      requestInProgress: false,
      errors: [],

      isComponentModalActive:false,
      resultRequest: null,
      currentRequestId: null,
      isSuccess: false,
      isError: false,
      errorMessage: null,
      timeOut: false,
      loadingAction:false,
      status: {
        status: 1,
        date: this.mNow(),
      },
    }
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },
  },

  mounted() {
  },

  methods: {
    openModal() {
      this.isComponentModalActive = true;
    },

    closeModal(){
      this.isComponentModalActive = false
      if(this.isCloseClear){
        this.clearProps()
      }
    },

    clearProps(){
      
      this.loadingAction = false
      this.resultRequest = null
      this.errorMessage = null
      this.isError = false
      this.status = {
        status: 0,
        date: this.mNow(),
      },
      this.timeOut =false;
      this.isSuccess = false;
      this.currentRequestId =null;
      this.oldPassword = null;
      this.password= null;
      this.passwordRepeat = null;
      this.requestInProgress = false;
      this.errors =[];
       // Reset observer to ignore validation on empty form.
       // requestAnimationFrame(() => { this.$refs.observer.reset() })
    },

    async ezwmPasswordChange() {
      this.timeOut = false;
      this.currentRequestId = null;
      this.isSuccess = false;

      this.loadingAction = true;
      this.clearError();

      const action = this.isExpired ? 8 : 7

      let ezwmObject2 = {
          "workerGuid": this.me.id + "",
          "domain": this.domain,
          "appointmentId": -1,
          "patientId": -1,
          "workerConfigurationId": this.configurationId,
          "ezwmAction": action,
          "ezwmStatus": 0,
          "ezwmStatusChange": "0001-01-01T00:00:00",
          "orderEzwm": null,
          "valueChange": this.password,
          "valueOld": this.oldPassword
        }

      let payload = JSON.parse(JSON.stringify(ezwmObject2));

      try {
        const requestId = await this.sendRequest(payload);
        
        this.resultRequest = null;

        if (requestId) {
          this.currentRequestId = JSON.parse(JSON.stringify(requestId))
          this.resultRequest = await this.getFinalResult(requestId);
          if (this.resultRequest != null) {
            if (this.resultRequest.ezwmStatus == 3) {
              this.successSnackbar("Utworzone");
              this.isSuccess = true;
              this.isError = false;
            } else {
              this.errorMessage = this.getErrorMessage(true);
              this.isError = true;
            }
          } else {
            this.errorMessage = "Błąd wysyłania";
            this.isError = true;
          }
        }
      } catch (error) {
        this.errorMessage = "Błąd przetwarzania zapytania";
        this.isError = true;
      } finally {
        this.loadingAction = false;
      }
    },

    async refreshResult(){
      this.loadingAction = true;
      this.timeOut = false;
      this.isError = false;
      try{
        if(this.currentRequestId != null){
          this.resultRequest = await this.getFinalResult(this.currentRequestId);
            if (this.resultRequest != null) {
              if (this.resultRequest.ezwmStatus == 3) {
                this.successSnackbar("Hasło zmienione");
                this.isError = false;
                this.isSuccess = true;
              } else {
                this.errorMessage = this.getErrorMessage(true);
                this.isError = true;
              }
            } else {
              this.errorMessage = "Błąd wysyłania";
              this.isError = true;
            }
        }
      } catch (error) {
        this.errorMessage = "Błąd przetwarzania zapytania";
        this.isError = true;
      } finally {
        this.loadingAction = false;
      }
    },

    sendRequest(payload) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(Action.EZWM_SEND, payload)
          .then((response) => {
            this.status.status = 1;
            this.status.date = new Date();
            resolve(response);
          })
          .catch((error) => {
            this.apiProblem(error);
            reject(error);
          });
      });
    },

    getFinalResult(id) {
      return new Promise((resolve, reject) => {
        let triesLeft = 5;
        const interval = setInterval(async () => {
          let payload = {
                id: id,
          };
          let result = await this.getResult(payload);

          if (result != null) {
            this.status.status = result.ezwmStatus;
            this.status.date = result.ezwmStatusChange;
          }

          if (result != null && this.isEndResult(result.ezwmStatus)) { 
            clearInterval(interval);           
            this.timeOut = false
            resolve(result);
            
          } else if (triesLeft <= 1) {
            clearInterval(interval);
            this.timeOut = true
            reject(result);            
          }
          triesLeft--;
        }, 7000);
      });
    },

    getResult(id) {
      //
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(Action.EZWM_GET, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.apiProblem(error);
            reject(error);
          });
      });
    },

    getErrorMessage(joinStatus) {
      if (this.resultRequest) {
        let message = "";
        if (joinStatus) {
          message = `[${this.resultRequest.ezwmStatus}] ${this.getStatus(
            this.resultRequest.ezwmStatus
          )} ${this.mDateTime(this.resultRequest.ezwmStatusChange)}`;
        }

        if (
          this.resultRequest != null &&
          this.resultRequest.ezwmErrors != null &&
          this.resultRequest.ezwmErrors.length > 0
        ) {
          if (joinStatus) message += `<br />`;
          this.resultRequest.ezwmErrors.forEach((element) => {
            message += `${element.errorMessage}[${element.errorCode}] <br />`;
          });
        }

        return message;
      } else {
        return "Błąd";
      }
    },

    clearError() {
      this.isError = false;
      this.errorMessage = null;
    },

    getStatus(stat) {
      switch (stat) {
        case 0:
          return "Brak informacji";
        case 1:
          return "Kolejka";
        case 2:
          return "Przetwarzanie";
        case 3:
          return "Ukończono";
        case 4:
          return "Błąd";
        case 5:
          return "Utworzono zawartość";
        case 6:
          return "Podpisano zawartość";
        case 7:
          return "Nie znaleziono pracownika";
        case 8:
          return "Łączenie";
        case 9:
          return "Błąd połączenia";
        case 10:
          return "Błędne hasło";
        case 11:
          return "Błąd domeny";
        default:
          return "Brak informacji";
      }
    },

    isEndResult(status) {
      if (
        status == 7 ||
        status == 3 ||
        status == 4 ||
        status == 9 ||
        status == 10 ||
        status == 11
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
