export default {
    data: () => {
        return {
            /**Prefixy i id państw
             * Prefixes and id of countries
             */
            nationalityMixinArray:[
                {
                  "id": 160,
                  "code": "POL",
                  "isoCode": "PL",
                  "country": "Polska",
                  "numberCode": 616,
                  "prefix": "48",
                  "zlkId": 179
                },
                {
                  "id": 0,
                  "code": "AFG",
                  "isoCode": "AF",
                  "country": "Afganistan",
                  "numberCode": 4,
                  "prefix": "93",
                  "zlkId": 3
                },
                {
                  "id": 1,
                  "code": "ALB",
                  "isoCode": "AL",
                  "country": "Albania",
                  "numberCode": 8,
                  "prefix": "355",
                  "zlkId": 6
                },
                {
                  "id": 2,
                  "code": "DZA",
                  "isoCode": "DZ",
                  "country": "Algieria",
                  "numberCode": 12,
                  "prefix": "213",
                  "zlkId": 62
                },
                {
                  "id": 3,
                  "code": "AND",
                  "isoCode": "AD",
                  "country": "Andora",
                  "numberCode": 20,
                  "prefix": "376",
                  "zlkId": 1
                },
                {
                  "id": 4,
                  "code": "AGO",
                  "isoCode": "AO",
                  "country": "Angola",
                  "numberCode": 24,
                  "prefix": "244",
                  "zlkId": 8
                },
                {
                  "id": 5,
                  "code": "AIA",
                  "isoCode": "AI",
                  "country": "Anguilla",
                  "numberCode": 660,
                  "prefix": "",
                  "zlkId": 5
                },
                {
                  "id": 6,
                  "code": "ATA",
                  "isoCode": "AQ",
                  "country": "Antarktyda",
                  "numberCode": 10,
                  "prefix": "",
                  "zlkId": 9
                },
                {
                  "id": 7,
                  "code": "ATG",
                  "isoCode": "AG",
                  "country": "Antigua i Barbuda",
                  "numberCode": 28,
                  "prefix": "",
                  "zlkId": 4
                },
                {
                  "id": 8,
                  "code": "ANT",
                  "isoCode": "AN",
                  "country": "Antyle Holenderskie",
                  "numberCode": 530,
                  "prefix": "599",
                  "zlkId": 0
                },
                {
                  "id": 9,
                  "code": "SAU",
                  "isoCode": "SA",
                  "country": "Arabia Saudyjska",
                  "numberCode": 682,
                  "prefix": "966",
                  "zlkId": 193
                },
                {
                  "id": 10,
                  "code": "ARG",
                  "isoCode": "AR",
                  "country": "Argentyna",
                  "numberCode": 32,
                  "prefix": "54",
                  "zlkId": 10
                },
                {
                  "id": 11,
                  "code": "ARM",
                  "isoCode": "AM",
                  "country": "Armenia",
                  "numberCode": 51,
                  "prefix": "374",
                  "zlkId": 7
                },
                {
                  "id": 12,
                  "code": "ABW",
                  "isoCode": "AW",
                  "country": "Aruba",
                  "numberCode": 533,
                  "prefix": "",
                  "zlkId": 14
                },
                {
                  "id": 13,
                  "code": "AUS",
                  "isoCode": "AU",
                  "country": "Australia",
                  "numberCode": 36,
                  "prefix": "61",
                  "zlkId": 13
                },
                {
                  "id": 14,
                  "code": "AUT",
                  "isoCode": "AT",
                  "country": "Austria",
                  "numberCode": 40,
                  "prefix": "43",
                  "zlkId": 12
                },
                {
                  "id": 15,
                  "code": "AZE",
                  "isoCode": "AZ",
                  "country": "Azerbejdżan",
                  "numberCode": 31,
                  "prefix": "994",
                  "zlkId": 16
                },
                {
                  "id": 16,
                  "code": "BHS",
                  "isoCode": "BS",
                  "country": "Wyspy Bahama",
                  "numberCode": 44,
                  "prefix": "",
                  "zlkId": 32
                },
                {
                  "id": 17,
                  "code": "BHR",
                  "isoCode": "BH",
                  "country": "Bahrajn",
                  "numberCode": 48,
                  "prefix": "973",
                  "zlkId": 23
                },
                {
                  "id": 18,
                  "code": "BGD",
                  "isoCode": "BD",
                  "country": "Bangladesz",
                  "numberCode": 50,
                  "prefix": "880",
                  "zlkId": 19
                },
                {
                  "id": 19,
                  "code": "BRB",
                  "isoCode": "BB",
                  "country": "Barbados",
                  "numberCode": 52,
                  "prefix": "",
                  "zlkId": 18
                },
                {
                  "id": 20,
                  "code": "BEL",
                  "isoCode": "BE",
                  "country": "Belgia",
                  "numberCode": 56,
                  "prefix": "32",
                  "zlkId": 20
                },
                {
                  "id": 21,
                  "code": "BLZ",
                  "isoCode": "BZ",
                  "country": "Belize",
                  "numberCode": 84,
                  "prefix": "",
                  "zlkId": 37
                },
                {
                  "id": 22,
                  "code": "BEN",
                  "isoCode": "BJ",
                  "country": "Benin",
                  "numberCode": 204,
                  "prefix": "229",
                  "zlkId": 25
                },
                {
                  "id": 23,
                  "code": "BMU",
                  "isoCode": "BM",
                  "country": "Bermudy",
                  "numberCode": 60,
                  "prefix": "",
                  "zlkId": 27
                },
                {
                  "id": 24,
                  "code": "BTN",
                  "isoCode": "BT",
                  "country": "Bhutan",
                  "numberCode": 64,
                  "prefix": "",
                  "zlkId": 33
                },
                {
                  "id": 25,
                  "code": "BLR",
                  "isoCode": "BY",
                  "country": "Białoruś",
                  "numberCode": 112,
                  "prefix": "375",
                  "zlkId": 36
                },
                {
                  "id": 26,
                  "code": "MMR",
                  "isoCode": "MM",
                  "country": "Birma (Myanmar)",
                  "numberCode": 104,
                  "prefix": "95",
                  "zlkId": 146
                },
                {
                  "id": 27,
                  "code": "BOL",
                  "isoCode": "BO",
                  "country": "Boliwia",
                  "numberCode": 68,
                  "prefix": "591",
                  "zlkId": 29
                },
                {
                  "id": 28,
                  "code": "BIH",
                  "isoCode": "BA",
                  "country": "Bośnia i Hercegowina",
                  "numberCode": 70,
                  "prefix": "387",
                  "zlkId": 17
                },
                {
                  "id": 29,
                  "code": "BWA",
                  "isoCode": "BW",
                  "country": "Botswana",
                  "numberCode": 72,
                  "prefix": "267",
                  "zlkId": 35
                },
                {
                  "id": 30,
                  "code": "BRA",
                  "isoCode": "BR",
                  "country": "Brazylia",
                  "numberCode": 76,
                  "prefix": "55",
                  "zlkId": 31
                },
                {
                  "id": 31,
                  "code": "BRN",
                  "isoCode": "BN",
                  "country": "Brunei",
                  "numberCode": 96,
                  "prefix": "673",
                  "zlkId": 28
                },
                {
                  "id": 32,
                  "code": "IOT",
                  "isoCode": "IO",
                  "country": "Brytyjskie Terytorium Oceanu Indyjskiego",
                  "numberCode": 86,
                  "prefix": "",
                  "zlkId": 106
                },
                {
                  "id": 33,
                  "code": "VGB",
                  "isoCode": "VG",
                  "country": "Brytyjskie Wyspy Dziewicze",
                  "numberCode": 92,
                  "prefix": "",
                  "zlkId": 239
                },
                {
                  "id": 34,
                  "code": "BFA",
                  "isoCode": "BF",
                  "country": "Burkina Faso",
                  "numberCode": 854,
                  "prefix": "226",
                  "zlkId": 21
                },
                {
                  "id": 35,
                  "code": "BDI",
                  "isoCode": "BI",
                  "country": "Burundi",
                  "numberCode": 108,
                  "prefix": "257",
                  "zlkId": 24
                },
                {
                  "id": 36,
                  "code": "CHL",
                  "isoCode": "CL",
                  "country": "Chile",
                  "numberCode": 152,
                  "prefix": "56",
                  "zlkId": 46
                },
                {
                  "id": 37,
                  "code": "CHN",
                  "isoCode": "CN",
                  "country": "Chiny",
                  "numberCode": 156,
                  "prefix": "86",
                  "zlkId": 48
                },
                {
                  "id": 38,
                  "code": "HRV",
                  "isoCode": "HR",
                  "country": "Chorwacja",
                  "numberCode": 191,
                  "prefix": "385",
                  "zlkId": 98
                },
                {
                  "id": 39,
                  "code": "CYP",
                  "isoCode": "CY",
                  "country": "Cypr",
                  "numberCode": 196,
                  "prefix": "357",
                  "zlkId": 55
                },
                {
                  "id": 40,
                  "code": "TCD",
                  "isoCode": "TD",
                  "country": "Czad",
                  "numberCode": 148,
                  "prefix": "235",
                  "zlkId": 215
                },
                {
                  "id": 41,
                  "code": "MNE",
                  "isoCode": "ME",
                  "country": "Czarnogóra",
                  "numberCode": 499,
                  "prefix": "",
                  "zlkId": 140
                },
                {
                  "id": 42,
                  "code": "UMI",
                  "isoCode": "UM",
                  "country": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
                  "numberCode": 581,
                  "prefix": "",
                  "zlkId": 232
                },
                {
                  "id": 43,
                  "code": "DNK",
                  "isoCode": "DK",
                  "country": "Dania",
                  "numberCode": 208,
                  "prefix": "45",
                  "zlkId": 59
                },
                {
                  "id": 44,
                  "code": "COD",
                  "isoCode": "CD",
                  "country": "Demokratyczna Republika Konga",
                  "numberCode": 180,
                  "prefix": "234",
                  "zlkId": 40
                },
                {
                  "id": 45,
                  "code": "DMA",
                  "isoCode": "DM",
                  "country": "Dominika",
                  "numberCode": 212,
                  "prefix": "",
                  "zlkId": 60
                },
                {
                  "id": 46,
                  "code": "DJI",
                  "isoCode": "DJ",
                  "country": "Dżibuti",
                  "numberCode": 262,
                  "prefix": "253",
                  "zlkId": 58
                },
                {
                  "id": 47,
                  "code": "EGY",
                  "isoCode": "EG",
                  "country": "Egipt",
                  "numberCode": 818,
                  "prefix": "20",
                  "zlkId": 65
                },
                {
                  "id": 48,
                  "code": "ECU",
                  "isoCode": "EC",
                  "country": "Ekwador",
                  "numberCode": 218,
                  "prefix": "593",
                  "zlkId": 63
                },
                {
                  "id": 49,
                  "code": "ERI",
                  "isoCode": "ER",
                  "country": "Erytrea",
                  "numberCode": 232,
                  "prefix": "291",
                  "zlkId": 67
                },
                {
                  "id": 50,
                  "code": "EST",
                  "isoCode": "EE",
                  "country": "Estonia",
                  "numberCode": 233,
                  "prefix": "372",
                  "zlkId": 64
                },
                {
                  "id": 51,
                  "code": "ETH",
                  "isoCode": "ET",
                  "country": "Etiopia",
                  "numberCode": 231,
                  "prefix": "251",
                  "zlkId": 69
                },
                {
                  "id": 52,
                  "code": "FLK",
                  "isoCode": "FK",
                  "country": "Falklandy (Malwiny)",
                  "numberCode": 238,
                  "prefix": "500",
                  "zlkId": 72
                },
                {
                  "id": 53,
                  "code": "FJI",
                  "isoCode": "FJ",
                  "country": "Fidżi",
                  "numberCode": 242,
                  "prefix": "679",
                  "zlkId": 71
                },
                {
                  "id": 54,
                  "code": "PHL",
                  "isoCode": "PH",
                  "country": "Filipiny",
                  "numberCode": 608,
                  "prefix": "63",
                  "zlkId": 177
                },
                {
                  "id": 55,
                  "code": "FIN",
                  "isoCode": "FI",
                  "country": "Finlandia",
                  "numberCode": 246,
                  "prefix": "358",
                  "zlkId": 70
                },
                {
                  "id": 56,
                  "code": "FRA",
                  "isoCode": "FR",
                  "country": "Francja",
                  "numberCode": 250,
                  "prefix": "33",
                  "zlkId": 75
                },
                {
                  "id": 57,
                  "code": "ATF",
                  "isoCode": "TF",
                  "country": "Francuskie Terytoria Południowe",
                  "numberCode": 260,
                  "prefix": "",
                  "zlkId": 216
                },
                {
                  "id": 58,
                  "code": "GAB",
                  "isoCode": "GA",
                  "country": "Gabon",
                  "numberCode": 266,
                  "prefix": "241",
                  "zlkId": 76
                },
                {
                  "id": 59,
                  "code": "GMB",
                  "isoCode": "GM",
                  "country": "Gambia",
                  "numberCode": 270,
                  "prefix": "220",
                  "zlkId": 85
                },
                {
                  "id": 60,
                  "code": "SGS",
                  "isoCode": "GS",
                  "country": "Georgia Południowa i Sandwich Południowy",
                  "numberCode": 239,
                  "prefix": "",
                  "zlkId": 90
                },
                {
                  "id": 61,
                  "code": "GHA",
                  "isoCode": "GH",
                  "country": "Ghana",
                  "numberCode": 288,
                  "prefix": "233",
                  "zlkId": 82
                },
                {
                  "id": 62,
                  "code": "GIB",
                  "isoCode": "GI",
                  "country": "Gibraltar",
                  "numberCode": 292,
                  "prefix": "350",
                  "zlkId": 83
                },
                {
                  "id": 63,
                  "code": "GRC",
                  "isoCode": "GR",
                  "country": "Grecja",
                  "numberCode": 300,
                  "prefix": "30",
                  "zlkId": 89
                },
                {
                  "id": 64,
                  "code": "GRD",
                  "isoCode": "GD",
                  "country": "Grenada",
                  "numberCode": 308,
                  "prefix": "",
                  "zlkId": 78
                },
                {
                  "id": 65,
                  "code": "GRL",
                  "isoCode": "GL",
                  "country": "Grenlandia",
                  "numberCode": 304,
                  "prefix": "299",
                  "zlkId": 84
                },
                {
                  "id": 66,
                  "code": "GEO",
                  "isoCode": "GE",
                  "country": "Gruzja",
                  "numberCode": 268,
                  "prefix": "995",
                  "zlkId": 79
                },
                {
                  "id": 67,
                  "code": "GUM",
                  "isoCode": "GU",
                  "country": "Guam",
                  "numberCode": 316,
                  "prefix": "1671",
                  "zlkId": 92
                },
                {
                  "id": 68,
                  "code": "GGY",
                  "isoCode": "GG",
                  "country": "Guernsey",
                  "numberCode": 831,
                  "prefix": "",
                  "zlkId": 81
                },
                {
                  "id": 69,
                  "code": "GUY",
                  "isoCode": "GY",
                  "country": "Gujana",
                  "numberCode": 328,
                  "prefix": "592",
                  "zlkId": 94
                },
                {
                  "id": 70,
                  "code": "GUF",
                  "isoCode": "GF",
                  "country": "Gujana Francuska",
                  "numberCode": 254,
                  "prefix": "594",
                  "zlkId": 80
                },
                {
                  "id": 71,
                  "code": "GLP",
                  "isoCode": "GP",
                  "country": "Gwadelupa",
                  "numberCode": 312,
                  "prefix": "",
                  "zlkId": 87
                },
                {
                  "id": 72,
                  "code": "GTM",
                  "isoCode": "GT",
                  "country": "Gwatemala",
                  "numberCode": 320,
                  "prefix": "",
                  "zlkId": 91
                },
                {
                  "id": 73,
                  "code": "GIN",
                  "isoCode": "GN",
                  "country": "Gwinea",
                  "numberCode": 324,
                  "prefix": "224",
                  "zlkId": 86
                },
                {
                  "id": 74,
                  "code": "GNB",
                  "isoCode": "GW",
                  "country": "Gwinea Bissau",
                  "numberCode": 624,
                  "prefix": "245",
                  "zlkId": 93
                },
                {
                  "id": 75,
                  "code": "GNQ",
                  "isoCode": "GQ",
                  "country": "Gwinea Równikowa",
                  "numberCode": 226,
                  "prefix": "240",
                  "zlkId": 88
                },
                {
                  "id": 76,
                  "code": "HTI",
                  "isoCode": "HT",
                  "country": "Haiti",
                  "numberCode": 332,
                  "prefix": "",
                  "zlkId": 99
                },
                {
                  "id": 77,
                  "code": "ESP",
                  "isoCode": "ES",
                  "country": "Hiszpania",
                  "numberCode": 724,
                  "prefix": "34",
                  "zlkId": 68
                },
                {
                  "id": 78,
                  "code": "NLD",
                  "isoCode": "NL",
                  "country": "Holandia (Niderlandy)",
                  "numberCode": 528,
                  "prefix": "31",
                  "zlkId": 166
                },
                {
                  "id": 79,
                  "code": "HND",
                  "isoCode": "HN",
                  "country": "Honduras",
                  "numberCode": 340,
                  "prefix": "",
                  "zlkId": 97
                },
                {
                  "id": 80,
                  "code": "HKG",
                  "isoCode": "HK",
                  "country": "Hongkong",
                  "numberCode": 344,
                  "prefix": "852",
                  "zlkId": 95
                },
                {
                  "id": 81,
                  "code": "IND",
                  "isoCode": "IN",
                  "country": "Indie",
                  "numberCode": 356,
                  "prefix": "91",
                  "zlkId": 105
                },
                {
                  "id": 82,
                  "code": "IDN",
                  "isoCode": "ID",
                  "country": "Indonezja",
                  "numberCode": 360,
                  "prefix": "62",
                  "zlkId": 101
                },
                {
                  "id": 83,
                  "code": "IRQ",
                  "isoCode": "IQ",
                  "country": "Irak",
                  "numberCode": 368,
                  "prefix": "964",
                  "zlkId": 107
                },
                {
                  "id": 84,
                  "code": "IRN",
                  "isoCode": "IR",
                  "country": "Iran",
                  "numberCode": 364,
                  "prefix": "98",
                  "zlkId": 108
                },
                {
                  "id": 85,
                  "code": "ISL",
                  "isoCode": "IS",
                  "country": "Islandia",
                  "numberCode": 352,
                  "prefix": "354",
                  "zlkId": 109
                },
                {
                  "id": 86,
                  "code": "ISR",
                  "isoCode": "IL",
                  "country": "Izrael",
                  "numberCode": 376,
                  "prefix": "972",
                  "zlkId": 103
                },
                {
                  "id": 87,
                  "code": "JAM",
                  "isoCode": "JM",
                  "country": "Jamajka",
                  "numberCode": 388,
                  "prefix": "",
                  "zlkId": 112
                },
                {
                  "id": 88,
                  "code": "JPN",
                  "isoCode": "JP",
                  "country": "Japonia",
                  "numberCode": 392,
                  "prefix": "81",
                  "zlkId": 114
                },
                {
                  "id": 89,
                  "code": "YEM",
                  "isoCode": "YE",
                  "country": "Jemen",
                  "numberCode": 887,
                  "prefix": "967",
                  "zlkId": 245
                },
                {
                  "id": 90,
                  "code": "JEY",
                  "isoCode": "JE",
                  "country": "Jersey",
                  "numberCode": 832,
                  "prefix": "",
                  "zlkId": 111
                },
                {
                  "id": 91,
                  "code": "JOR",
                  "isoCode": "JO",
                  "country": "Jordania",
                  "numberCode": 400,
                  "prefix": "962",
                  "zlkId": 113
                },
                {
                  "id": 92,
                  "code": "CYM",
                  "isoCode": "KY",
                  "country": "Kajmany",
                  "numberCode": 136,
                  "prefix": "",
                  "zlkId": 124
                },
                {
                  "id": 93,
                  "code": "KHM",
                  "isoCode": "KH",
                  "country": "Kambodża",
                  "numberCode": 116,
                  "prefix": "588",
                  "zlkId": 117
                },
                {
                  "id": 94,
                  "code": "CMR",
                  "isoCode": "CM",
                  "country": "Kamerun",
                  "numberCode": 120,
                  "prefix": "237",
                  "zlkId": 47
                },
                {
                  "id": 95,
                  "code": "CAN",
                  "isoCode": "CA",
                  "country": "Kanada",
                  "numberCode": 124,
                  "prefix": "1",
                  "zlkId": 38
                },
                {
                  "id": 96,
                  "code": "QAT",
                  "isoCode": "QA",
                  "country": "Katar",
                  "numberCode": 634,
                  "prefix": "974",
                  "zlkId": 187
                },
                {
                  "id": 97,
                  "code": "KAZ",
                  "isoCode": "KZ",
                  "country": "Kazachstan",
                  "numberCode": 398,
                  "prefix": "7",
                  "zlkId": 125
                },
                {
                  "id": 98,
                  "code": "KEN",
                  "isoCode": "KE",
                  "country": "Kenia",
                  "numberCode": 404,
                  "prefix": "254",
                  "zlkId": 115
                },
                {
                  "id": 99,
                  "code": "KGZ",
                  "isoCode": "KG",
                  "country": "Kirgistan",
                  "numberCode": 417,
                  "prefix": "996",
                  "zlkId": 116
                },
                {
                  "id": 100,
                  "code": "KIR",
                  "isoCode": "KI",
                  "country": "Kiribati",
                  "numberCode": 296,
                  "prefix": "686",
                  "zlkId": 118
                },
                {
                  "id": 101,
                  "code": "COL",
                  "isoCode": "CO",
                  "country": "Kolumbia",
                  "numberCode": 170,
                  "prefix": "57",
                  "zlkId": 49
                },
                {
                  "id": 102,
                  "code": "COM",
                  "isoCode": "KM",
                  "country": "Komory",
                  "numberCode": 174,
                  "prefix": "269",
                  "zlkId": 119
                },
                {
                  "id": 103,
                  "code": "COG",
                  "isoCode": "CG",
                  "country": "Kongo",
                  "numberCode": 178,
                  "prefix": "242",
                  "zlkId": 42
                },
                {
                  "id": 104,
                  "code": "KOR",
                  "isoCode": "KR",
                  "country": "Korea Południowa",
                  "numberCode": 410,
                  "prefix": "82",
                  "zlkId": 122
                },
                {
                  "id": 105,
                  "code": "PRK",
                  "isoCode": "KP",
                  "country": "Koreańska Republika Ludowo-Demokratyczna",
                  "numberCode": 408,
                  "prefix": "850",
                  "zlkId": 121
                },
                {
                  "id": 106,
                  "code": "CRI",
                  "isoCode": "CR",
                  "country": "Kostaryka",
                  "numberCode": 188,
                  "prefix": "506",
                  "zlkId": 50
                },
                {
                  "id": 107,
                  "code": "CUB",
                  "isoCode": "CU",
                  "country": "Kuba",
                  "numberCode": 192,
                  "prefix": "53",
                  "zlkId": 51
                },
                {
                  "id": 108,
                  "code": "KWT",
                  "isoCode": "KW",
                  "country": "Kuwejt",
                  "numberCode": 414,
                  "prefix": "965",
                  "zlkId": 123
                },
                {
                  "id": 109,
                  "code": "LAO",
                  "isoCode": "LA",
                  "country": "Laos",
                  "numberCode": 418,
                  "prefix": "856",
                  "zlkId": 126
                },
                {
                  "id": 110,
                  "code": "LSO",
                  "isoCode": "LS",
                  "country": "Lesotho",
                  "numberCode": 426,
                  "prefix": "266",
                  "zlkId": 132
                },
                {
                  "id": 111,
                  "code": "LBN",
                  "isoCode": "LB",
                  "country": "Liban",
                  "numberCode": 422,
                  "prefix": "961",
                  "zlkId": 127
                },
                {
                  "id": 112,
                  "code": "LBR",
                  "isoCode": "LR",
                  "country": "Liberia",
                  "numberCode": 430,
                  "prefix": "231",
                  "zlkId": 131
                },
                {
                  "id": 113,
                  "code": "LBY",
                  "isoCode": "LY",
                  "country": "Libia",
                  "numberCode": 434,
                  "prefix": "218",
                  "zlkId": 136
                },
                {
                  "id": 114,
                  "code": "LTU",
                  "isoCode": "LT",
                  "country": "Litwa",
                  "numberCode": 440,
                  "prefix": "370",
                  "zlkId": 133
                },
                {
                  "id": 115,
                  "code": "LUX",
                  "isoCode": "LU",
                  "country": "Luksemburg",
                  "numberCode": 442,
                  "prefix": "352",
                  "zlkId": 134
                },
                {
                  "id": 116,
                  "code": "LVA",
                  "isoCode": "LV",
                  "country": "Łotwa",
                  "numberCode": 428,
                  "prefix": "371",
                  "zlkId": 135
                },
                {
                  "id": 117,
                  "code": "MKD",
                  "isoCode": "MK",
                  "country": "Macedonia",
                  "numberCode": 807,
                  "prefix": "389",
                  "zlkId": 144
                },
                {
                  "id": 118,
                  "code": "MDG",
                  "isoCode": "MG",
                  "country": "Madagaskar",
                  "numberCode": 450,
                  "prefix": "261",
                  "zlkId": 142
                },
                {
                  "id": 119,
                  "code": "MYT",
                  "isoCode": "YT",
                  "country": "Majotta",
                  "numberCode": 175,
                  "prefix": "",
                  "zlkId": 246
                },
                {
                  "id": 120,
                  "code": "MAC",
                  "isoCode": "MO",
                  "country": "Makau",
                  "numberCode": 446,
                  "prefix": "853",
                  "zlkId": 148
                },
                {
                  "id": 121,
                  "code": "MWI",
                  "isoCode": "MW",
                  "country": "Malawi",
                  "numberCode": 454,
                  "prefix": "265",
                  "zlkId": 156
                },
                {
                  "id": 122,
                  "code": "MDV",
                  "isoCode": "MV",
                  "country": "Malediwy",
                  "numberCode": 462,
                  "prefix": "960",
                  "zlkId": 155
                },
                {
                  "id": 123,
                  "code": "MYS",
                  "isoCode": "MY",
                  "country": "Malezja",
                  "numberCode": 458,
                  "prefix": "60",
                  "zlkId": 158
                },
                {
                  "id": 124,
                  "code": "MLI",
                  "isoCode": "ML",
                  "country": "Mali",
                  "numberCode": 466,
                  "prefix": "223",
                  "zlkId": 145
                },
                {
                  "id": 125,
                  "code": "MLT",
                  "isoCode": "MT",
                  "country": "Malta",
                  "numberCode": 470,
                  "prefix": "356",
                  "zlkId": 153
                },
                {
                  "id": 126,
                  "code": "MNP",
                  "isoCode": "MP",
                  "country": "Mariany Północne",
                  "numberCode": 582,
                  "prefix": "1670",
                  "zlkId": 149
                },
                {
                  "id": 127,
                  "code": "MAR",
                  "isoCode": "MA",
                  "country": "Maroko",
                  "numberCode": 504,
                  "prefix": "212",
                  "zlkId": 137
                },
                {
                  "id": 128,
                  "code": "MTQ",
                  "isoCode": "MQ",
                  "country": "Martynika",
                  "numberCode": 474,
                  "prefix": "",
                  "zlkId": 150
                },
                {
                  "id": 129,
                  "code": "MRT",
                  "isoCode": "MR",
                  "country": "Mauretania",
                  "numberCode": 478,
                  "prefix": "222",
                  "zlkId": 151
                },
                {
                  "id": 130,
                  "code": "MUS",
                  "isoCode": "MU",
                  "country": "Mauritius",
                  "numberCode": 480,
                  "prefix": "230",
                  "zlkId": 154
                },
                {
                  "id": 131,
                  "code": "MEX",
                  "isoCode": "MX",
                  "country": "Meksyk",
                  "numberCode": 484,
                  "prefix": "52",
                  "zlkId": 157
                },
                {
                  "id": 132,
                  "code": "FSM",
                  "isoCode": "FM",
                  "country": "Mikronezja",
                  "numberCode": 583,
                  "prefix": "",
                  "zlkId": 73
                },
                {
                  "id": 133,
                  "code": "MDA",
                  "isoCode": "MD",
                  "country": "Mołdawia",
                  "numberCode": 498,
                  "prefix": "373",
                  "zlkId": 139
                },
                {
                  "id": 134,
                  "code": "MCO",
                  "isoCode": "MC",
                  "country": "Monako",
                  "numberCode": 492,
                  "prefix": "377",
                  "zlkId": 138
                },
                {
                  "id": 135,
                  "code": "MNG",
                  "isoCode": "MN",
                  "country": "Mongolia",
                  "numberCode": 596,
                  "prefix": "976",
                  "zlkId": 147
                },
                {
                  "id": 136,
                  "code": "MSR",
                  "isoCode": "MS",
                  "country": "Montserrat",
                  "numberCode": 500,
                  "prefix": "",
                  "zlkId": 152
                },
                {
                  "id": 137,
                  "code": "MOZ",
                  "isoCode": "MZ",
                  "country": "Mozambik",
                  "numberCode": 508,
                  "prefix": "258",
                  "zlkId": 159
                },
                {
                  "id": 138,
                  "code": "NAM",
                  "isoCode": "NA",
                  "country": "Namibia",
                  "numberCode": 516,
                  "prefix": "264",
                  "zlkId": 160
                },
                {
                  "id": 139,
                  "code": "NRU",
                  "isoCode": "NR",
                  "country": "Nauru",
                  "numberCode": 520,
                  "prefix": "674",
                  "zlkId": 169
                },
                {
                  "id": 140,
                  "code": "NPL",
                  "isoCode": "NP",
                  "country": "Nepal",
                  "numberCode": 524,
                  "prefix": "977",
                  "zlkId": 168
                },
                {
                  "id": 141,
                  "code": "DEU",
                  "isoCode": "DE",
                  "country": "Niemcy",
                  "numberCode": 276,
                  "prefix": "49",
                  "zlkId": 57
                },
                {
                  "id": 142,
                  "code": "NER",
                  "isoCode": "NE",
                  "country": "Niger",
                  "numberCode": 562,
                  "prefix": "227",
                  "zlkId": 162
                },
                {
                  "id": 143,
                  "code": "NGA",
                  "isoCode": "NG",
                  "country": "Nigeria",
                  "numberCode": 566,
                  "prefix": "234",
                  "zlkId": 164
                },
                {
                  "id": 144,
                  "code": "NIC",
                  "isoCode": "NI",
                  "country": "Nikaragua",
                  "numberCode": 558,
                  "prefix": "505",
                  "zlkId": 165
                },
                {
                  "id": 145,
                  "code": "NIU",
                  "isoCode": "NU",
                  "country": "Niue",
                  "numberCode": 570,
                  "prefix": "683",
                  "zlkId": 170
                },
                {
                  "id": 146,
                  "code": "NFK",
                  "isoCode": "NF",
                  "country": "Norfolk",
                  "numberCode": 574,
                  "prefix": "672",
                  "zlkId": 163
                },
                {
                  "id": 147,
                  "code": "NOR",
                  "isoCode": "NO",
                  "country": "Norwegia",
                  "numberCode": 578,
                  "prefix": "47",
                  "zlkId": 167
                },
                {
                  "id": 148,
                  "code": "NCL",
                  "isoCode": "NC",
                  "country": "Nowa Kaledonia",
                  "numberCode": 540,
                  "prefix": "687",
                  "zlkId": 161
                },
                {
                  "id": 149,
                  "code": "NZL",
                  "isoCode": "NZ",
                  "country": "Nowa Zelandia",
                  "numberCode": 554,
                  "prefix": "64",
                  "zlkId": 171
                },
                {
                  "id": 150,
                  "code": "OMN",
                  "isoCode": "OM",
                  "country": "Oman",
                  "numberCode": 512,
                  "prefix": "968",
                  "zlkId": 172
                },
                {
                  "id": 151,
                  "code": "PAK",
                  "isoCode": "PK",
                  "country": "Pakistan",
                  "numberCode": 586,
                  "prefix": "92",
                  "zlkId": 178
                },
                {
                  "id": 152,
                  "code": "PLW",
                  "isoCode": "PW",
                  "country": "Palau",
                  "numberCode": 585,
                  "prefix": "680",
                  "zlkId": 185
                },
                {
                  "id": 153,
                  "code": "PSE",
                  "isoCode": "PS",
                  "country": "Palestyna",
                  "numberCode": 275,
                  "prefix": "970",
                  "zlkId": 183
                },
                {
                  "id": 154,
                  "code": "PAN",
                  "isoCode": "PA",
                  "country": "Panama",
                  "numberCode": 591,
                  "prefix": "507",
                  "zlkId": 173
                },
                {
                  "id": 155,
                  "code": "PNG",
                  "isoCode": "PG",
                  "country": "Papua-Nowa Gwinea",
                  "numberCode": 598,
                  "prefix": "675",
                  "zlkId": 176
                },
                {
                  "id": 156,
                  "code": "PRY",
                  "isoCode": "PY",
                  "country": "Paragwaj",
                  "numberCode": 600,
                  "prefix": "595",
                  "zlkId": 186
                },
                {
                  "id": 157,
                  "code": "PER",
                  "isoCode": "PE",
                  "country": "Peru",
                  "numberCode": 604,
                  "prefix": "51",
                  "zlkId": 174
                },
                {
                  "id": 158,
                  "code": "PCN",
                  "isoCode": "PN",
                  "country": "Pitcairn",
                  "numberCode": 612,
                  "prefix": "",
                  "zlkId": 181
                },
                {
                  "id": 159,
                  "code": "PYF",
                  "isoCode": "PF",
                  "country": "Polinezja Francuska",
                  "numberCode": 258,
                  "prefix": "689",
                  "zlkId": 175
                },
                {
                  "id": 161,
                  "code": "PRI",
                  "isoCode": "PR",
                  "country": "Portoryko",
                  "numberCode": 630,
                  "prefix": "1787",
                  "zlkId": 182
                },
                {
                  "id": 162,
                  "code": "PRT",
                  "isoCode": "PT",
                  "country": "Portugalia",
                  "numberCode": 620,
                  "prefix": "351",
                  "zlkId": 184
                },
                {
                  "id": 163,
                  "code": "ZAF",
                  "isoCode": "ZA",
                  "country": "Republika Południowej Afryki",
                  "numberCode": 710,
                  "prefix": "27",
                  "zlkId": 247
                },
                {
                  "id": 164,
                  "code": "CAF",
                  "isoCode": "CF",
                  "country": "Republika Środkowoafrykańska",
                  "numberCode": 140,
                  "prefix": "236",
                  "zlkId": 41
                },
                {
                  "id": 165,
                  "code": "CPV",
                  "isoCode": "CV",
                  "country": "Republika Zielonego Przylądka",
                  "numberCode": 132,
                  "prefix": "238",
                  "zlkId": 52
                },
                {
                  "id": 166,
                  "code": "REU",
                  "isoCode": "RE",
                  "country": "Reunion",
                  "numberCode": 638,
                  "prefix": "262",
                  "zlkId": 188
                },
                {
                  "id": 167,
                  "code": "RUS",
                  "isoCode": "RU",
                  "country": "Rosja",
                  "numberCode": 643,
                  "prefix": "7",
                  "zlkId": 191
                },
                {
                  "id": 168,
                  "code": "RWA",
                  "isoCode": "RW",
                  "country": "Rwanda",
                  "numberCode": 646,
                  "prefix": "250",
                  "zlkId": 192
                },
                {
                  "id": 169,
                  "code": "ESH",
                  "isoCode": "EH",
                  "country": "Sahara Zachodnia",
                  "numberCode": 732,
                  "prefix": "",
                  "zlkId": 66
                },
                {
                  "id": 170,
                  "code": "BLM",
                  "isoCode": "BL",
                  "country": "Saint Barthelemy",
                  "numberCode": 652,
                  "prefix": "",
                  "zlkId": 26
                },
                {
                  "id": 171,
                  "code": "KNA",
                  "isoCode": "KN",
                  "country": "Saint Kitts i Nevis",
                  "numberCode": 659,
                  "prefix": "1869",
                  "zlkId": 120
                },
                {
                  "id": 172,
                  "code": "LCA",
                  "isoCode": "LC",
                  "country": "Saint Lucia",
                  "numberCode": 662,
                  "prefix": "1758",
                  "zlkId": 128
                },
                {
                  "id": 173,
                  "code": "VCT",
                  "isoCode": "VC",
                  "country": "Saint Vincent i Grenadyny",
                  "numberCode": 670,
                  "prefix": "1809",
                  "zlkId": 237
                },
                {
                  "id": 174,
                  "code": "SPM",
                  "isoCode": "PM",
                  "country": "Saint Pierre i Miquelon",
                  "numberCode": 666,
                  "prefix": "",
                  "zlkId": 180
                },
                {
                  "id": 175,
                  "code": "SLV",
                  "isoCode": "SV",
                  "country": "Salwador",
                  "numberCode": 222,
                  "prefix": "503",
                  "zlkId": 210
                },
                {
                  "id": 176,
                  "code": "WSM",
                  "isoCode": "WS",
                  "country": "Samoa",
                  "numberCode": 882,
                  "prefix": "684",
                  "zlkId": 244
                },
                {
                  "id": 177,
                  "code": "ASM",
                  "isoCode": "AS",
                  "country": "Samoa Amerykańskie",
                  "numberCode": 16,
                  "prefix": "685",
                  "zlkId": 11
                },
                {
                  "id": 178,
                  "code": "SMR",
                  "isoCode": "SM",
                  "country": "San Marino",
                  "numberCode": 674,
                  "prefix": "378",
                  "zlkId": 204
                },
                {
                  "id": 179,
                  "code": "SEN",
                  "isoCode": "SN",
                  "country": "Senegal",
                  "numberCode": 686,
                  "prefix": "221",
                  "zlkId": 205
                },
                {
                  "id": 180,
                  "code": "SRB",
                  "isoCode": "RS",
                  "country": "Serbia",
                  "numberCode": 688,
                  "prefix": "",
                  "zlkId": 190
                },
                {
                  "id": 181,
                  "code": "SYC",
                  "isoCode": "SC",
                  "country": "Seszele",
                  "numberCode": 690,
                  "prefix": "248",
                  "zlkId": 195
                },
                {
                  "id": 182,
                  "code": "SLE",
                  "isoCode": "SL",
                  "country": "Sierra Leone",
                  "numberCode": 694,
                  "prefix": "232",
                  "zlkId": 203
                },
                {
                  "id": 183,
                  "code": "SGP",
                  "isoCode": "SG",
                  "country": "Singapur",
                  "numberCode": 702,
                  "prefix": "65",
                  "zlkId": 198
                },
                {
                  "id": 184,
                  "code": "SVK",
                  "isoCode": "SK",
                  "country": "Słowacja",
                  "numberCode": 703,
                  "prefix": "421",
                  "zlkId": 202
                },
                {
                  "id": 185,
                  "code": "SVN",
                  "isoCode": "SI",
                  "country": "Słowenia",
                  "numberCode": 705,
                  "prefix": "386",
                  "zlkId": 200
                },
                {
                  "id": 186,
                  "code": "SOM",
                  "isoCode": "SO",
                  "country": "Somalia",
                  "numberCode": 705,
                  "prefix": "252",
                  "zlkId": 206
                },
                {
                  "id": 187,
                  "code": "LKA",
                  "isoCode": "LK",
                  "country": "Sri Lanka",
                  "numberCode": 144,
                  "prefix": "94",
                  "zlkId": 130
                },
                {
                  "id": 188,
                  "code": "USA",
                  "isoCode": "US",
                  "country": "Stany Zjednoczone",
                  "numberCode": 840,
                  "prefix": "1",
                  "zlkId": 233
                },
                {
                  "id": 189,
                  "code": "SWZ",
                  "isoCode": "SZ",
                  "country": "Suazi",
                  "numberCode": 748,
                  "prefix": "268",
                  "zlkId": 213
                },
                {
                  "id": 190,
                  "code": "SUR",
                  "isoCode": "SR",
                  "country": "Surinam",
                  "numberCode": 770,
                  "prefix": "597",
                  "zlkId": 207
                },
                {
                  "id": 191,
                  "code": "SJM",
                  "isoCode": "SJ",
                  "country": "Svalbard i Jan Mayen (wyspa)",
                  "numberCode": 744,
                  "prefix": "",
                  "zlkId": 201
                },
                {
                  "id": 192,
                  "code": "SYR",
                  "isoCode": "SY",
                  "country": "Syria",
                  "numberCode": 760,
                  "prefix": "963",
                  "zlkId": 212
                },
                {
                  "id": 193,
                  "code": "CHE",
                  "isoCode": "CH",
                  "country": "Szwajcaria",
                  "numberCode": 756,
                  "prefix": "41",
                  "zlkId": 43
                },
                {
                  "id": 194,
                  "code": "SWE",
                  "isoCode": "SE",
                  "country": "Szwecja",
                  "numberCode": 752,
                  "prefix": "46",
                  "zlkId": 197
                },
                {
                  "id": 195,
                  "code": "SHN",
                  "isoCode": "SH",
                  "country": "Święta Helena",
                  "numberCode": 654,
                  "prefix": "290",
                  "zlkId": 199
                },
                {
                  "id": 196,
                  "code": "TJK",
                  "isoCode": "TJ",
                  "country": "Tadżykistan",
                  "numberCode": 762,
                  "prefix": "992",
                  "zlkId": 219
                },
                {
                  "id": 197,
                  "code": "THA",
                  "isoCode": "TH",
                  "country": "Tajlandia",
                  "numberCode": 764,
                  "prefix": "66",
                  "zlkId": 218
                },
                {
                  "id": 198,
                  "code": "TWN",
                  "isoCode": "TW",
                  "country": "Tajwan",
                  "numberCode": 158,
                  "prefix": "886",
                  "zlkId": 228
                },
                {
                  "id": 199,
                  "code": "TZA",
                  "isoCode": "TZ",
                  "country": "Tanzania",
                  "numberCode": 834,
                  "prefix": "255",
                  "zlkId": 229
                },
                {
                  "id": 200,
                  "code": "TLS",
                  "isoCode": "TL",
                  "country": "Timor Wschodni",
                  "numberCode": 626,
                  "prefix": "",
                  "zlkId": 221
                },
                {
                  "id": 201,
                  "code": "TGO",
                  "isoCode": "TG",
                  "country": "Togo",
                  "numberCode": 768,
                  "prefix": "228",
                  "zlkId": 217
                },
                {
                  "id": 202,
                  "code": "TKL",
                  "isoCode": "TK",
                  "country": "Tokelau",
                  "numberCode": 772,
                  "prefix": "690",
                  "zlkId": 220
                },
                {
                  "id": 203,
                  "code": "TON",
                  "isoCode": "TO",
                  "country": "Tonga",
                  "numberCode": 776,
                  "prefix": "676",
                  "zlkId": 224
                },
                {
                  "id": 204,
                  "code": "TTO",
                  "isoCode": "TT",
                  "country": "Trynidad i Tobago",
                  "numberCode": 780,
                  "prefix": "",
                  "zlkId": 226
                },
                {
                  "id": 205,
                  "code": "TUN",
                  "isoCode": "TN",
                  "country": "Tunezja",
                  "numberCode": 788,
                  "prefix": "216",
                  "zlkId": 223
                },
                {
                  "id": 206,
                  "code": "TUR",
                  "isoCode": "TR",
                  "country": "Turcja",
                  "numberCode": 792,
                  "prefix": "90",
                  "zlkId": 225
                },
                {
                  "id": 207,
                  "code": "TKM",
                  "isoCode": "TM",
                  "country": "Turkmenistan",
                  "numberCode": 795,
                  "prefix": "993",
                  "zlkId": 222
                },
                {
                  "id": 208,
                  "code": "TUV",
                  "isoCode": "TV",
                  "country": "Tuvalu",
                  "numberCode": 798,
                  "prefix": "688",
                  "zlkId": 227
                },
                {
                  "id": 209,
                  "code": "UGA",
                  "isoCode": "UG",
                  "country": "Uganda",
                  "numberCode": 800,
                  "prefix": "256",
                  "zlkId": 231
                },
                {
                  "id": 210,
                  "code": "UKR",
                  "isoCode": "UA",
                  "country": "Ukraina",
                  "numberCode": 804,
                  "prefix": "380",
                  "zlkId": 230
                },
                {
                  "id": 211,
                  "code": "URY",
                  "isoCode": "UY",
                  "country": "Urugwaj",
                  "numberCode": 858,
                  "prefix": "598",
                  "zlkId": 234
                },
                {
                  "id": 212,
                  "code": "UZB",
                  "isoCode": "UZ",
                  "country": "Uzbekistan",
                  "numberCode": 860,
                  "prefix": "998",
                  "zlkId": 235
                },
                {
                  "id": 213,
                  "code": "VUT",
                  "isoCode": "VU",
                  "country": "Vanuatu",
                  "numberCode": 548,
                  "prefix": "678",
                  "zlkId": 242
                },
                {
                  "id": 214,
                  "code": "WLF",
                  "isoCode": "WF",
                  "country": "Wallis i Futuna",
                  "numberCode": 876,
                  "prefix": "681",
                  "zlkId": 243
                },
                {
                  "id": 215,
                  "code": "VAT",
                  "isoCode": "VA",
                  "country": "Watykan",
                  "numberCode": 336,
                  "prefix": "3906",
                  "zlkId": 236
                },
                {
                  "id": 216,
                  "code": "VEN",
                  "isoCode": "VE",
                  "country": "Wenezuela",
                  "numberCode": 862,
                  "prefix": "58",
                  "zlkId": 238
                },
                {
                  "id": 217,
                  "code": "HUN",
                  "isoCode": "HU",
                  "country": "Węgry",
                  "numberCode": 348,
                  "prefix": "36",
                  "zlkId": 100
                },
                {
                  "id": 218,
                  "code": "GBD",
                  "isoCode": "GB",
                  "country": "Wielka Brytania obywatel terytoriów zależnych",
                  "numberCode": 826,
                  "prefix": "",
                  "zlkId": 77
                },
                {
                  "id": 219,
                  "code": "GBN",
                  "isoCode": "GB",
                  "country": "Wielka Brytania (Terytoria zamorskie)",
                  "numberCode": 826,
                  "prefix": "",
                  "zlkId": 77
                },
                {
                  "id": 220,
                  "code": "GBO",
                  "isoCode": "GB",
                  "country": "Wielka Brytania obywatel zamorski",
                  "numberCode": 826,
                  "prefix": "",
                  "zlkId": 77
                },
                {
                  "id": 221,
                  "code": "GBP",
                  "isoCode": "GB",
                  "country": "Wielka Brytania osoba chroniona",
                  "numberCode": 826,
                  "prefix": "",
                  "zlkId": 77
                },
                {
                  "id": 222,
                  "code": "GBS",
                  "isoCode": "GB",
                  "country": "Wielka Brytania (Terytoria podległe)",
                  "numberCode": 826,
                  "prefix": "",
                  "zlkId": 77
                },
                {
                  "id": 223,
                  "code": "VNM",
                  "isoCode": "VN",
                  "country": "Wietnam",
                  "numberCode": 704,
                  "prefix": "84",
                  "zlkId": 241
                },
                {
                  "id": 224,
                  "code": "ITA",
                  "isoCode": "IT",
                  "country": "Włochy",
                  "numberCode": 380,
                  "prefix": "39",
                  "zlkId": 110
                },
                {
                  "id": 225,
                  "code": "CIV",
                  "isoCode": "CI",
                  "country": "Wybrzeże Kości Słoniowej",
                  "numberCode": 384,
                  "prefix": "225",
                  "zlkId": 44
                },
                {
                  "id": 226,
                  "code": "BVT",
                  "isoCode": "BV",
                  "country": "Wyspa Bouveta",
                  "numberCode": 74,
                  "prefix": "",
                  "zlkId": 34
                },
                {
                  "id": 227,
                  "code": "CXR",
                  "isoCode": "CX",
                  "country": "Wyspa Bożego Narodzenia",
                  "numberCode": 162,
                  "prefix": "",
                  "zlkId": 54
                },
                {
                  "id": 228,
                  "code": "IMN",
                  "isoCode": "IM",
                  "country": "Wyspa Man",
                  "numberCode": 833,
                  "prefix": "",
                  "zlkId": 104
                },
                {
                  "id": 229,
                  "code": "ALA",
                  "isoCode": "AX",
                  "country": "Wyspy Alandzkie",
                  "numberCode": 248,
                  "prefix": "",
                  "zlkId": 15
                },
                {
                  "id": 230,
                  "code": "COK",
                  "isoCode": "CK",
                  "country": "Wyspy Cooka",
                  "numberCode": 184,
                  "prefix": "682",
                  "zlkId": 45
                },
                {
                  "id": 231,
                  "code": "VIR",
                  "isoCode": "VI",
                  "country": "Wyspy Dziewicze Stanów Zjednoczonych",
                  "numberCode": 850,
                  "prefix": "",
                  "zlkId": 240
                },
                {
                  "id": 232,
                  "code": "HMD",
                  "isoCode": "HM",
                  "country": "Wyspy Heard i McDonalda",
                  "numberCode": 334,
                  "prefix": "",
                  "zlkId": 96
                },
                {
                  "id": 233,
                  "code": "CCK",
                  "isoCode": "CC",
                  "country": "Wyspy Kokosowe",
                  "numberCode": 166,
                  "prefix": "",
                  "zlkId": 39
                },
                {
                  "id": 234,
                  "code": "MHL",
                  "isoCode": "MH",
                  "country": "Wyspy Marshalla",
                  "numberCode": 584,
                  "prefix": "692",
                  "zlkId": 143
                },
                {
                  "id": 235,
                  "code": "FRO",
                  "isoCode": "FO",
                  "country": "Wyspy Owcze",
                  "numberCode": 234,
                  "prefix": "298",
                  "zlkId": 74
                },
                {
                  "id": 236,
                  "code": "SLB",
                  "isoCode": "SB",
                  "country": "Wyspy Salomona",
                  "numberCode": 90,
                  "prefix": "677",
                  "zlkId": 194
                },
                {
                  "id": 237,
                  "code": "STP",
                  "isoCode": "ST",
                  "country": "Wyspy Świętego Tomasza i Książęca",
                  "numberCode": 678,
                  "prefix": "239",
                  "zlkId": 209
                },
                {
                  "id": 238,
                  "code": "TCA",
                  "isoCode": "TC",
                  "country": "Wyspy Turks i Caicos",
                  "numberCode": 796,
                  "prefix": "",
                  "zlkId": 214
                },
                {
                  "id": 239,
                  "code": "ZMB",
                  "isoCode": "ZM",
                  "country": "Zambia",
                  "numberCode": 894,
                  "prefix": "260",
                  "zlkId": 248
                },
                {
                  "id": 240,
                  "code": "ZWE",
                  "isoCode": "ZW",
                  "country": "Zimbabwe",
                  "numberCode": 716,
                  "prefix": "263",
                  "zlkId": 249
                },
                {
                  "id": 241,
                  "code": "ARE",
                  "isoCode": "AE",
                  "country": "Zjednoczone Emiraty Arabskie",
                  "numberCode": 784,
                  "prefix": "",
                  "zlkId": 2
                },
                {
                  "id": 242,
                  "code": "XUU",
                  "isoCode": "XU",
                  "country": "Obywatele Unii Europejskiej",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 243,
                  "code": "XXX",
                  "isoCode": "NN",
                  "country": "Nieokreślone / Inne",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 244,
                  "code": "ZAR",
                  "isoCode": "ZR",
                  "country": "Zair",
                  "numberCode": 180,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 245,
                  "code": "XXT",
                  "isoCode": "XT",
                  "country": "Tybet",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 246,
                  "code": "UNR",
                  "isoCode": "UN",
                  "country": "UNHCR",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 247,
                  "code": "XXM",
                  "isoCode": "XM",
                  "country": "Morze",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 248,
                  "code": "XXL",
                  "isoCode": "XL",
                  "country": "Lotniska",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 249,
                  "code": "XOM",
                  "isoCode": "ZK",
                  "country": "Zakon Kawalerów Maltańskich",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 250,
                  "code": "XXB",
                  "isoCode": "XB",
                  "country": "Uchodźca (Art. 1 Konwencji z 1951 r.)",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 251,
                  "code": "Uchodźca",
                  "isoCode": "XX",
                  "country": "Uchodźca (inne)",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 252,
                  "code": "FXX",
                  "isoCode": "FX",
                  "country": "Francuskie Terytoria Zamorskie",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 253,
                  "code": "999",
                  "isoCode": "99",
                  "country": "Dowolne obywatelstwo",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 254,
                  "code": "VVV",
                  "isoCode": "VV",
                  "country": "Rodzaj dokumentu bez względu na obywatelstwo",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 255,
                  "code": "RKS",
                  "isoCode": "XK",
                  "country": "Kosowo",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 256,
                  "code": "BGR",
                  "isoCode": "BG",
                  "country": "Bułgaria",
                  "numberCode": 100,
                  "prefix": "359",
                  "zlkId": 22
                },
                {
                  "id": 257,
                  "code": "ROU",
                  "isoCode": "RO",
                  "country": "Rumunia",
                  "numberCode": 642,
                  "prefix": "40",
                  "zlkId": 189
                },
                {
                  "id": 258,
                  "code": "GBR",
                  "isoCode": "GB",
                  "country": "Wielka Brytania",
                  "numberCode": 826,
                  "prefix": "44",
                  "zlkId": 77
                },
                {
                  "id": 259,
                  "code": "IRL",
                  "isoCode": "IE",
                  "country": "Irlandia",
                  "numberCode": 372,
                  "prefix": "353",
                  "zlkId": 102
                },
                {
                  "id": 260,
                  "code": "LIE",
                  "isoCode": "LI",
                  "country": "Liechtenstein",
                  "numberCode": 438,
                  "prefix": "423",
                  "zlkId": 129
                },
                {
                  "id": 261,
                  "code": "FRM",
                  "isoCode": "XF",
                  "country": "UDSC (FRM) - import",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 262,
                  "code": "PAL",
                  "isoCode": "XA",
                  "country": "UDSC (PAL) - import",
                  "numberCode": 0,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 263,
                  "code": "ROM",
                  "isoCode": "XR",
                  "country": "UDSC (ROM) - import",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 264,
                  "code": "XXA",
                  "isoCode": "BP",
                  "country": "Bez obywatelstwa (Art. 1 Konwencji z 1954)",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 265,
                  "code": "XXS",
                  "isoCode": "XS",
                  "country": "Państwa Schengen",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 266,
                  "code": "BES",
                  "isoCode": "BQ",
                  "country": "Bonaire, Sint Eustatius i Saba",
                  "numberCode": 535,
                  "prefix": "",
                  "zlkId": 30
                },
                {
                  "id": 267,
                  "code": "SSD",
                  "isoCode": "SS",
                  "country": "Sudan Południowy",
                  "numberCode": 728,
                  "prefix": "",
                  "zlkId": 208
                },
                {
                  "id": 268,
                  "code": "SDN",
                  "isoCode": "SD",
                  "country": "Sudan",
                  "numberCode": 729,
                  "prefix": "249",
                  "zlkId": 196
                },
                {
                  "id": 269,
                  "code": "SXM",
                  "isoCode": "SX",
                  "country": "Sint Maarten (część holenderska)",
                  "numberCode": 534,
                  "prefix": "",
                  "zlkId": 211
                },
                {
                  "id": 270,
                  "code": "MAF",
                  "isoCode": "MF",
                  "country": "Saint Martin (część francuska)",
                  "numberCode": 663,
                  "prefix": "",
                  "zlkId": 141
                },
                {
                  "id": 271,
                  "code": "SDN",
                  "isoCode": "SD",
                  "country": "Sudan (sprzed lipca 2011)",
                  "numberCode": 729,
                  "prefix": "",
                  "zlkId": 196
                },
                {
                  "id": 272,
                  "code": "CZE",
                  "isoCode": "CZ",
                  "country": "Czechy",
                  "numberCode": 203,
                  "prefix": "420",
                  "zlkId": 56
                },
                {
                  "id": 273,
                  "code": "DOM",
                  "isoCode": "DO",
                  "country": "Dominikana",
                  "numberCode": 214,
                  "prefix": "",
                  "zlkId": 61
                },
                {
                  "id": 274,
                  "code": "UNO",
                  "isoCode": "ZZ",
                  "country": "Organizacja Narodów Zjednoczonych",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 275,
                  "code": "UNA",
                  "isoCode": "ZZ",
                  "country": "Wyspecjalizowana agenda ONZ",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 276,
                  "code": "UNK",
                  "isoCode": "ZZ",
                  "country": "Rezydent Kosowa (dokument wystawiony przez UNMIK)",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                },
                {
                  "id": 277,
                  "code": "CUW",
                  "isoCode": "CW",
                  "country": "Curacao",
                  "numberCode": 531,
                  "prefix": "",
                  "zlkId": 53
                },
                {
                  "id": 278,
                  "code": "EUE",
                  "isoCode": "EU",
                  "country": "Unia Europejska",
                  "numberCode": 999,
                  "prefix": "",
                  "zlkId": 0
                }
              ]
        }
    },

    methods: {
        /**Metody wywoływania danych dotyczących poszczególnych państw
         * Method for getting countries data
         */
        getCountryBy2(item) {
            let found = this.nationalityMixinArray.find(b => b.isoCode === item)
            if (found) return found.country
            else return ''
        },
        getCountryBy3(item) {
            let found = this.nationalityMixinArray.find(b => b.code === item)
            if (found) return found.country
            else return ''
        },

        getCountryCodeBy2(item) {
            let found = this.nationalityMixinArray.find(b => b.isoCode === item)
            if (found) return found.numberCode
            else return ''
        },
        getCountryCodeBy3(item) {
            let found = this.nationalityMixinArray.find(b => b.code === item)
            if (found) return found.numberCode
            else return ''
        },

        mGetCountryNumberCodeByCountry(item) {
            let found = this.nationalityMixinArray.find(b => b.country === item)
            if (found) return found.numberCode
            else return -1
        },

        mGetZlkIdByCountry(item) {
            let found = this.nationalityMixinArray.find(b => b.country.toUpperCase() == item.toUpperCase())
            if (found) return found.zlkId
            else return -1
        },

        mGetCountryByZlkId(item) {
            let found = this.nationalityMixinArray.find(b => b.zlkId== item)
            if (found) return found.country
            else return -1
        },

        mGetIsoCode(item) {
            if (item != null) {
                let found = this.nationalityMixinArray.find(b => b.country.toUpperCase() == item.toUpperCase())
                if (found) return found.isoCode
                else return ''
            } else return ''

        }
    }
}





