export default {
    data: function () {
        return {
            /**Domeny w systemie
             * System domains
             */
            mDomainsConfig: [
                {
                    id: 1,
                    name: "CMD",
                    desc: "Centrum Medyczno Diagnostyczne",
                    color: 'is-primary'
                },
                {
                    id: 2,
                    name: "MAG",
                    desc: "Mag Sp. z o.o. (Narbutta)",
                    color: 'is-kin'
                },
                {
                    id: 3,
                    name: "OPZ",
                    desc: "Ośrodek Pomocy Zdrowiu (B1)",
                    color: 'is-secondary'
                },
                {
                    id: 4,
                    name: "CMJ",
                    desc: "Centrum Medyczne Józefów",
                    color: 'is-forest'
                },
                {
                    id: 5,
                    name: "PHC",
                    desc: "Pharma Centrum",
                    color: 'is-violet'
                },
                {
                    id: 6,
                    name: "VIS",
                    desc: "VIS Medycyna Pracy",
                    color: 'is-primary'
                },
                {
                    id: 7,
                    name: "Jadów",
                    desc: "Centrum Medyczne Jadów",
                    color: 'is-orange'
                },
                {
                    id: 100,
                    name: "CYTOMED",
                    desc: "MLD CYTOMED Anna Miś",
                    color: 'is-dark'
                },
                {
                    id: 101,
                    name: "ISPLPG MS",
                    desc: "ISPLPG Marlena Sobieszczuk",
                    color: 'is-dark'
                },
                {
                    id: 102,
                    name: "ZPiPO KD",
                    desc: "ZPiPO Krzysztof Dach",
                    color: 'is-dark'
                },
                {
                    id: 103,
                    name: "SW iKSW",
                    desc: "Szpital Wojewódzki im. kard. S. Wyszyńskiego",
                    color: 'is-orange'
                },
                {
                    id: 999,
                    name: "TEST",
                    desc: "SZOK Testy",
                    color: 'is-danger'
                },
                {
                    id: 1001,
                    name: "Poligon Narbutta",
                    desc: "Poligon Narbutta",
                    color: 'is-danger'
                }
            ]
        }
    },

    methods: {
        mGetDomain(id) {
            if (id) {
                let type = this.mDomainsConfig.find(c => c.id == id)
                if (type) return type
                else return `Brak [${id}]`
            }
            else return "Brak"
        },
        mGetDomainName(id) {
            let type = this.mDomainsConfig.find(c => c.id == id)
            if (type) return type.name
            else return `Brak[${id}]`
        },
        mGetDomainDesc(id) {
            let type = this.mDomainsConfig.find(c => c.id == id)
            if (type) return type.desc
            else return `Brak [${id}]`
        },
        mGetDomainColor(id) {
            let type = this.mDomainsConfig.find(c => c.id == id)
            if (type) return type.color
            else return `is-dark`
        },
        mGetDomainTextColor(id) {
            let val = 'is-dark'
            let type = this.mDomainsConfig.find(c => c.id == id)
            if (type) val = type.color

            return val.replace('is-', 'has-text-')
        }
    }
}