import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {
    
        /** Attempt to send request to miscs. Uses POST request and requires data object (check config_apis.js) */
        [Action.MISC_P1EDEKL_ACTION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MISC_P1EDeklaracja_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get result from misc. Uses GET request with request id */
        [Action.MISC_P1EDEKL_GET_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MISC_P1EDeklaracja_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to send request to miscs. Uses POST request and requires data object (check config_apis.js) */
        [Action.MISC_P1ZM_ACTION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MISC_P1ZM_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get result from misc. Uses GET request with request id */
        [Action.MISC_P1ZM_GET_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MISC_P1ZM_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to send request to miscs. Uses POST request and requires data object (check config_apis.js) */
        [Action.MISC_P1ZLK_ACTION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MISC_P1ZLK_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get result from misc. Uses GET request with request id */
        [Action.MISC_P1ZLK_GET_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MISC_P1ZLK_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to send request to miscs. Uses POST request and requires data object (check config_apis.js) */
        [Action.MISC_P1EREJESTRACJA_CLOSE_VISIT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MISC_P1EREJESTRACJA_CLOSE_VISIT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get workerCreds from misc. Uses POST request with workerGuid */
        [Action.MISC_WORKERCREDS_GET](context, workerGuid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MISC_WORKERCREDS_GET,
                        headers: { Authorization: "Bearer " + token },
                        params:{
                            workerGuid:workerGuid
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    
    }
}