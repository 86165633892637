import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        contracts: null,
        subjects: null,
        subjectsInMyDomain: null,
        invoices: null,
        downloadedFiles: null,
        specialSubjects: null,
        ikz: [],
        nfzServices: [],
        nfzRules: [],
        selectedWorkerId: -1,
        selectedWorker: null,
        workerGroups: [],
        medServicesForQueue: [],
        loadMedsetsInQueue: false,
        selectGroup: null,
        umxId: null,
        selectedUmx: null,
        selectedUmxRange: null,
        selectedUmxMonth: null,
        selectedUmxRoute: null,
        demoGroup: {
            name: "Testowy projekt",
            type: 1,
            domainId: 1,
            workerId: 88,
            workers: [88, 195],
            moderators: [88],
        }
    },

    mutations: {
        [Mutation.FINANCE_SUBJECTS_SET_ALL](currentState, subjects) {
            currentState.subjects = subjects
        },

        [Mutation.FINANCE_SUBJECTS_SET_ALL_IN_MY_DOMAIN](currentState, subjects) {
            currentState.subjectsInMyDomain = subjects
        },

        [Mutation.MEDSETS_SET_QUEUE_LOAD](currentState, val) {
            currentState.loadMedsetsInQueue = val
        },

        [Mutation.MEDSETS_SET_ITEMS_FOR_QUEUE](currentState, items) {
            currentState.medServicesForQueue = items
            currentState.loadMedsetsInQueue = true
        },

        [Mutation.MEDSETS_SET_CLEAR_ITEMS_FOR_QUEUE](currentState) {
            currentState.medServicesForQueue = []
            currentState.loadMedsetsInQueue = false
        },

        [Mutation.FINANCE_SUBJECTS_SET_WITH_MEDSPEC_TYPE](currentState, subjects) {
            currentState.specialSubjects = subjects
        },

        [Mutation.FINANCE_IKZ_SET_ALL](currentState, subjects) {

            subjects.forEach(e => {
                e.pharse = `${e.name} ${e.code} ${e.icd9}`
            });

            currentState.ikz = subjects
        },

        [Mutation.FINANCE_CONTRACTS_SET_ALL](currentState, contracts) {
            currentState.contracts = contracts
        },

        [Mutation.FINANCE_INVOICES_SET_ALL](currentState, invoices) {
            currentState.invoices = invoices
        },

        [Mutation.FINANCE_SUBJECTS_PUSH](currentState, subject) {
            if (!currentState.subjects) {
                currentState.subjects = []
            }
            currentState.subjects.push(subject)
        },

        [Mutation.FINANCE_CONTRACTS_PUSH](currentState, contract) {
            if (!currentState.contracts) {
                currentState.contracts = [];
            }
            currentState.contracts.push(contract)
        },

        [Mutation.FINANCE_INVOICES_PUSH](currentState, invoice) {
            if (!currentState.invoices) {
                currentState.invoices = [];
            }
            currentState.invoices.push(invoice)
        },

        [Mutation.FINANCE_UPLOAD_DOWNLOAD_PUSH](currentState, file) {
            if (!currentState.downloadedFiles) {
                currentState.downloadedFiles = [];
            }
            currentState.downloadedFiles.push(file)
        },

        [Mutation.FINANCE_SUBJECTS_SET_UPDATE](currentState, subject) {
            if (currentState.subjects) {
                var index = currentState.subjects.findIndex(function (item) {
                    return item.id === subject.id;
                });

                currentState.subjects = [
                    ...currentState.subjects.slice(0, index),
                    subject,
                    ...currentState.subjects.slice(index + 1)
                ]
            }
        },

        [Mutation.FINANCE_SUBJECTS_IN_MY_DOMAIN_SET_UPDATE](currentState, subject) {

            let array = JSON.parse(JSON.stringify(currentState.subjectsInMyDomain))

            let index = array.find(s => s.id == subject.id)

            if (index > -1) {
                array[index] = subject
            }

            currentState.subjectsInMyDomain = array
        },

        //update contracts when one contract is updated
        [Mutation.FINANCE_CONTRACTS_SET_UPDATE](currentState, contract) {
            var index = currentState.contracts.findIndex(function (item) {
                return item.id === contract.id;
            });

            currentState.contracts = [
                ...currentState.contracts.slice(0, index),
                contract,
                ...currentState.contracts.slice(index + 1)
            ]
        },

        [Mutation.FINANCE_IKZ_SET_SELECTEDWORKER_ID](currentState, id) {
            currentState.selectedWorkerId = id
        },

        [Mutation.FINANCE_IKZ_SET_SELECTEDWORKER](currentState, worker) {
            currentState.selectedWorker = worker
        },

        [Mutation.FINANCE_WORKER_GROUP_PUSH](currentState, group) {
            currentState.workerGroups.push(group)
        },

        [Mutation.FINANCE_WORKER_GROUP_SELECTED](currentState, group) {
            currentState.selectGroup = group
        },

        [Mutation.FINANCE_UMXID_SET](currentState, id) {
            currentState.umxId = id
        },

        [Mutation.FINANCE_UMXID_RESET](currentState) {
            currentState.umxId = null
        },

        [Mutation.FINANCE_UMXRANGE_SET](currentState, range) {
            currentState.selectedUmxRange = JSON.parse(JSON.stringify(range));
        },

        [Mutation.FINANCE_UMXRANGE_RESET](currentState) {
            currentState.selectedUmxRange = null
        },

        [Mutation.FINANCE_UMXMONTH_SET](currentState, range) {
            currentState.selectedUmxMonth = JSON.parse(JSON.stringify(range));
        },

        [Mutation.FINANCE_UMXMONTH_RESET](currentState) {
            currentState.selectedUmxMonth = null
        },

        [Mutation.FINANCE_UMXROUTE_SET](currentState, route) {
            currentState.selectedUmxRoute = route
        },

        [Mutation.FINANCE_UMX_SET](currentState, umx) {
            currentState.selectedUmx = JSON.parse(JSON.stringify(umx));
        },

        [Mutation.FINANCE_UMX_RESET](currentState) {
            currentState.slelectedUmx = null
        },

        setNfzServices(currentState, payload) {
            currentState.nfzServices = payload
        },

        setNfzRules(currentState, payload) {
            currentState.nfzRules = payload
        },

    },

    actions: {

        [Action.JGP2_CALCULATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.JGP2_CALCULATE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.JGP2_INFO](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.JGP2_INFO.replace('{code}', code),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.JGP2_GET_ALL_CODES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.JGP2_GET_ALL_CODES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.JGP2_GET_ALL_ITEMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.JGP2_GET_ALL_ITEMS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.NFZ_DICTIONARY_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_DICTIONARY_GENERAL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.NFZ_DICTIONARY_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_DICTIONARY_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.NFZ_DICTIONARY_CREATE](context, contract) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.NFZ_DICTIONARY_GENERAL,
                        data: contract,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.NFZ_DICTIONARY_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.NFZ_DICTIONARY_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.NFZ_DICTIONARY_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.NFZ_DICTIONARY_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },





        ///api/Stats/MedSetStatsExcel/{domain}/{year}/{month}/{cat}
        [Action.FINANCE_REPORTS_MONTHLY_MEDSETS](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.FINANCE_REPORTS_MONTHLY_MEDSETS.replace('{domain}', model.domain).replace('{year}', model.year).replace('{month}', model.month).replace('{cat}', model.cat),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },




        [Action.NFZ_GRUPER_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_GRUPER_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.NFZ_GRUPER_UPDATE_LIST](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.NFZ_GRUPER_UPDATE_LIST.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.NFZ_GRUPER_UPDATE_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.NFZ_GRUPER_UPDATE_GROUP.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },






        [Action.NFZ_SERVICE_RULES_CHECK_CODE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_SERVICE_RULES_CHECK_CODE.replace('{clinic}', payload.clinic).replace('{code}', payload.code).replace('{age}', payload.age),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.NFZ_SERVICE_RULES_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_SERVICE_RULES_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit("setNfzRules", response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.NFZ_SERVICE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_SERVICE_GENERAL,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit("setNfzServices", response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.NFZ_SERVICE_EDIT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.NFZ_SERVICE_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.UMX_GET_SERVICES_FOR_OFFICE](context, office) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.UMX_GET_SERVICES_FOR_OFFICE,
                        data: office,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //UMX_GET_SERVICES_FOR_ENDAPP
        [Action.UMX_GET_SERVICES_FOR_ENDAPP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.UMX_GET_SERVICES_FOR_ENDAPP.replace('{idUmx}', payload.id),
                        data: payload.office,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.UMX_GET_FOR_DOMAIN](context, domain) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_GET_FOR_DOMAIN.replace('{domain}', domain),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.UMX_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //UMX_GET_BY_ID
        [Action.UMX_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.FINANCE_GET_NFZ_CONTRACT_BY_NIP](context, nip) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_GET_NFZ_CONTRACT_BY_NIP.replace('{nip}', nip),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_GET_NFZ_CONTRACT_BY_SWD](context, swd) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_GET_NFZ_CONTRACT_BY_SWD.replace('{swd}', swd),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_GET_NFZ_CONTRACTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_GET_NFZ_CONTRACTS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_GET_NFZ_MUS_ITEMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_GET_MUS_ITEMS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //CONTRACTS CRUD
        [Action.FINANCE_CONTRACTS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CONTRACTS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_CONTRACTS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.FINANCE_CONTRACTS_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CONTRACTS_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.FINANCE_CONTRACTS_CREATE](context, contract) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CONTRACTS_CREATE,
                        data: contract,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.FINANCE_CONTRACTS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_CONTRACTS_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_CONTRACTS_SET_UPDATE, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.FINANCE_CONTRACTS_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_CONTRACTS_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.FINANCE_CONTRACTS_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CONTRACTS_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //SUBJECTS CRUD
        [Action.FINANCE_SUBJECTS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_SUBJECTS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_GET_ALL_IN_MY_DOMAIN](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_GET_ALL_IN_MY_DOMAIN,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_SUBJECTS_SET_ALL_IN_MY_DOMAIN, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_MAIN_WITH_DOMAINS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECTS_MAIN_WITH_DOMAINS.replace('{mode}', payload.mode),
                        headers: { Authorization: "Bearer " + token },
                        data: payload.domains
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UNIREPORTS_GENERAL](context, config) {

            var url = Apis.FINANCE_UNIREPORTS_GENERAL

            if (config.type == 1) {
                url = Apis.FINANCE_UNIREPORTS_GENERAL_P2
            }

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: url,
                        data: config,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.FINANCE_UNIREPORTS_DOWNLOAD](context, id) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.FINANCE_UNIREPORTS_DOWNLOAD.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        fileDownload(response.data, id + '.xlsx');
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_CREATE](context, subject) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECTS_CREATE,
                        data: subject,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_SUBJECTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE](context, medspecTypes) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE,
                        data: medspecTypes,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_SUBJECTS_SET_WITH_MEDSPEC_TYPE, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_SUBJECTS_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_SUBJECTS_SET_UPDATE, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_SUBJECTS_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECTS_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //INVOICES CRUD
        [Action.FINANCE_INVOICE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_INVOICE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_INVOICES_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_INVOICE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_INVOICE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_INVOICE_CREATE](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_INVOICE_CREATE,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_INVOICE_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_INVOICE_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_INVOICE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_INVOICE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_GET_MY](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_USER_IKZ_GET_MY,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_GET_FOR_EMPLOYEE](context, workerId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_USER_IKZ_GET_FOR_EMPLOYEE.replace('{id}', workerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_GET_FOR_APPOINTMENT](context, appointmentId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_USER_IKZ_GET_FOR_APPOINTMENT.replace('{appointmentId}', appointmentId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.FINANCE_USER_IKZ_SAVE_IN_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_USER_IKZ_SAVE_IN_APPOINTMENT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_USER_IKZ_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_ADD](context, ikz) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_USER_IKZ_ADD,
                        data: ikz,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_FILTER](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_USER_IKZ_FILTER,
                        data: filter,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_USER_IKZ_FILTER_VER2](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_USER_IKZ_FILTER_VER2,
                        data: filter,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
















        //MED SERVICES CRUD
        [Action.FINANCE_MEDICAL_SERVICES_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MEDICAL_SERVICES_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_IKZ_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_MEDICAL_SERVICES_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MEDICAL_SERVICES_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_MEDICAL_SERVICES_GET_BY_TYPE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MEDICAL_SERVICES_GET_BY_TYPE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_MEDICAL_SERVICES_CREATE](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MEDICAL_SERVICES_CREATE,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_MEDICAL_SERVICES_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_MEDICAL_SERVICES_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_MEDICAL_SERVICES_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_MEDICAL_SERVICES_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },














        //IKZ CRUD
        [Action.FINANCE_IKZ_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_IKZ_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_IKZ_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_GET_BY_TYPE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_IKZ_GET_BY_TYPE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_CREATE](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_IKZ_CREATE,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_IKZ_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_IKZ_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_IKZ_FILTER](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_IKZ_FILTER,
                        data: filter,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },














        //Upload CRUD
        [Action.FINANCE_UPLOAD_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_UPLOAD_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_INVOICES_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_UPLOAD_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_CREATE](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_UPLOAD_CREATE,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_UPLOAD_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_UPLOAD_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('type', form.type)
                    payload.append('name', form.name)
                    payload.append('description', form.description)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.FINANCE_UPLOAD_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.UMX_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('subjectId', form.subjectId)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.UMX_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.UMX_VALIDATE_SERVICES](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_VALIDATE_SERVICES.replace('{umxId}', data.umxId).replace('{add}', data.add),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_UPLOAD_DOWNLOAD](context, fileId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.FINANCE_UPLOAD_DOWNLOAD.replace('{id}', fileId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_UPLOAD_DOWNLOAD_PUSH, { id: fileId, blob: response.data });
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // FINANCE SUBJECTS WORKERS NEW !!!!!

        [Action.FINANCE_SUBJECTS_WORKERS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_WORKERS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },



        [Action.FINANCE_SUBJECTS_WORKERS_GET_BY_ID](context, subjectId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_WORKERS_GET_BY_ID.replace('{id}', subjectId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_WORKERS_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_SUBJECTS_WORKERS_ADD.replace('{subjectId}', payload.subjectId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },



        [Action.FINANCE_SUBJECTS_WORKERS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_SUBJECTS_WORKERS_UPDATE.replace('{subjectId}', payload.subjectId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_WORKERS_DELETE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_SUBJECTS_WORKERS_DELETE.replace('{subjectId}', payload.subjectId).replace('{workerId}', payload.workerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        //// new


        [Action.FINANCE_GET_EXTERNAL_WORKERS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_GET_EXTERNAL_WORKERS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_EXTERNAL_EMPLOYEE_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_EXTERNAL_EMPLOYEE_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_EXTERNAL_WORKER_GET_BY_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_EXTERNAL_WORKER_GET_BY_ID.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_EXTERNAL_WORKER_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_EXTERNAL_WORKER_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_EXTERNAL_WORKER_DELETE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_EXTERNAL_WORKER_DELETE.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_ADD_WORKER_TO_SUBJECT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECTS_ADD_WORKER_TO_SUBJECT.replace('{subjectId}', payload.subjectId).replace('{externalWorkerId}', payload.externalWorkerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_SUBJECTS_REMOVE_WORKER_FROM_SUBJECT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_SUBJECTS_REMOVE_WORKER_FROM_SUBJECT.replace('{subjectId}', payload.subjectId).replace('{externalWorkerId}', payload.externalWorkerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_REPORTED_BY_OFFICE_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_REPORTED_BY_OFFICE_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }
    }
}