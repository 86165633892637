import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        /** Cache of workers. */
        workers: null,
        myWorkerData: null,
        pretender: null,
        doctors: [],

        pretenders: [],

        medicalTeams: [],
        myAvailablePaperGroup: null,
    },

    mutations: {

        [Mutation.WORKERS_CLEAR_MODULE](currentState) {
            currentState.workers = null
            currentState.myWorkerData = null
        },

        /** Load all workers to app cache */
        [Mutation.WORKER_PUSH_ALL](state, workers) {
            state.workers = workers
        },

        [Mutation.WORKER_PRETEND](state, payload) {
            state.pretender = payload
        },

        /** Add worker to app cache */
        [Mutation.WORKER_PUSH](state, worker) {
            if (state.workers != null) {
                state.workers.push(worker)
            }
        },

        /** Load all workers to app cache */
        [Mutation.WORKER_PUSH_ALL_DOCTORS](state, doctors) {

            let docs = doctors.sort((a, b) => {
                return a.lastName < b.lastName ? -1 : 1
            })

            state.doctors = docs
            state.pretenders = []

            for (let index = 0; index < docs.length; index++) {
                const doc = docs[index];

                state.pretenders.push({
                    id: doc.id,
                    name: doc.lastName + ' ' + doc.firstName,
                    specs: [
                        {
                            id: doc.specialization,
                            name: 'blablalbla'
                        }
                    ]
                })
            }
        },

        /** Modify worker (Actually replace worker info with updated) */
        [Mutation.WORKER_PUT](state, worker) {
            let indexOfWorker = state.workers.findIndex(u => u.id === worker.id)
            if (indexOfWorker > -1) state.workers[indexOfWorker] = worker
        },

        /** Remove worker from cache */
        [Mutation.WORKER_DELETE](state, workerId) {
            let indexOfWorker = state.workers.findIndex(u => u.id === workerId)
            if (indexOfWorker > -1) state.workers.splice(indexOfWorker, 1)
        },

        /** Modify worker (Actually replace worker info with updated) */
        [Mutation.WORKER_SET_MY_DATA](state, worker) {
            state.myWorkerData = worker
        },

        /** Load all workers to app cache */
        [Mutation.WORKER_SET_MEDICAL_TEAMS](state, payload) {
            state.medicalTeams = payload
        },

        [Mutation.WORKER_PUSH_MEDICAL_TEAM](state, medicalTeam) {
            if (state.medicalTeams != null) {
                state.medicalTeams.push(medicalTeam)
            }
        },

        /** Remove medical team from cache */
        [Mutation.WORKER_DELETE_MEDICAL_TEAM](state, teamId) {
            let indexOfTeam = state.medicalTeams.findIndex(u => u.id === teamId)
            if (indexOfTeam > -1) state.medicalTeams.splice(indexOfTeam, 1)
        },

        /** Modify medical team */
        [Mutation.WORKER_MODIFY_MEDICAL_TEAM](state, team) {
            let indexOfTeam = state.medicalTeams.findIndex(u => u.id == team.id)
            if (indexOfTeam > -1) state.medicalTeams[indexOfTeam] = team
        },

        [Mutation.WORKER_SET_AVAILABLE_PAPER_GROUP](state,payload){
            state.myAvailablePaperGroup = payload
        }
    },

    actions: {

        [Action.EMPLOYEE_UNIREPORT_PROFILE_POST](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_UNIREPORT_PROFILE_POST,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.EMPLOYEE_UNIREPORT_PROFILE_BY_EMPLOYEE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_UNIREPORT_PROFILE_BY_EMPLOYEE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_UNIREPORT_PROFILE_DELETE_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_UNIREPORT_PROFILE_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },














        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.WORKER_ADD_WORKER](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.WORKER_ADD,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.WORKER_PUSH, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.WORKER_GET_ALL_WORKERS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.WORKER_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.WORKER_PUSH_ALL, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all doctors. Uses GET request without parameter */
        [Action.WORKER_GET_ALL_DOCTORS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.WORKER_GET_ALL_DOCTORS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.WORKER_PUSH_ALL_DOCTORS, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get own worker data. Uses GET request without parameter */
        [Action.WORKER_GET_MY_DATA](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.WORKER_GET_ME,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.WORKER_SET_MY_DATA, response.data)
                        context.dispatch(Action.REGISTRATION_RESET)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.WORKER_GET_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.WORKER_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.WORKER_DELETE_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.WORKER_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.WORKER_DELETE, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.WORKER_MODIFY_WORKER](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.WORKER_PUT.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.WORKER_GET_ALL_MEDICAL_TEAMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.WORKER_GET_ALL_MEDICAL_TEAMS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                        context.commit(Mutation.WORKER_SET_MEDICAL_TEAMS, response.data)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.WORKER_ADD_MEDICAL_TEAM](context, medicalTeam) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.WORKER_ADD_MEDICAL_TEAM,
                        data: medicalTeam,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        // context.commit(Mutation.WORKER_PUSH_MEDICAL_TEAM, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.WORKER_DELETE_MEDICAL_TEAM](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.WORKER_DELETE_MEDICAL_TEAM.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.WORKER_DELETE_MEDICAL_TEAM, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.WORKER_MODIFY_MEDICAL_TEAM](context, team) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.WORKER_MODIFY_MEDICAL_TEAM.replace('{id}', team.id),
                        data: team,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        // context.commit(Mutation.WORKER_MODIFY_MEDICAL_TEAM, team)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.OFFICES_GET_BY_FACILITY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.OFFICES_GET_BY_FACILITY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_EXAMSET_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_EXAMSET_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_EXAMSET_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_EXAMSET_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_EXAMSET_PUT_BY_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_EXAMSET_PUT_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_EXAMSET_DELETE_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_EXAMSET_DELETE_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_EXAMSET_GET_BY_WORKER_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_EXAMSET_GET_BY_WORKER_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //

        [Action.EMPLOYEE_PROJECT_GOAL_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_ADD_TASK_GROUPS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_ADD_TASK_GROUPS.replace('{id}', payload.goalId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_ADD_TASKS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_ADD_TASKS.replace('{id}', payload.goalId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_POST](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_GET_BY_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_GET_BY_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_GET_FULL_INFO](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_GET_FULL_INFO.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_GET_CHILDREN](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_GET_CHILDREN.replace('{masterId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GOAL_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_GOAL_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.EMPLOYEE_PROGRAMME_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROGRAMME_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROGRAMME_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROGRAMME_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PROGRAMME_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PROGRAMME_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_CREATE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROGRAMME_CREATE_GOAL.replace('{programmeId}', payload.programmeId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROGRAMME_DELETE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PROGRAMME_DELETE_GOAL.replace('{programmeId}', payload.programmeId).replace('{goalId}', payload.goalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PROJECT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PROJECT_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PROJECT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_CREATE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PROJECT_CREATE_GOAL.replace('{projectId}', payload.projectId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PROJECT_DELETE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PROJECT_DELETE_GOAL.replace('{projectId}', payload.projectId).replace('{goalId}', payload.goalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TEAM_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TEAM_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_GET_BY_WORKER_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TEAM_GET_BY_WORKER_ID.replace('{employeeId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TEAM_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_TEAM_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_TEAM_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_UPLOAD_ATTACHMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TEAM_UPLOAD_ATTACHMENT.replace('{teamId}', payload.teamId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_UPLOAD_VERSION_ATTACHMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TEAM_UPLOAD_VERSION_ATTACHMENT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_CHECK_EXIST_ATTACHMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TEAM_CHECK_EXIST_ATTACHMENT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_ATTACHMENT_GET_BY_TEAM](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TEAM_ATTACHMENT_GET_BY_TEAM.replace('{teamInternalId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_CREATE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TEAM_CREATE_GOAL.replace('{teamId}', payload.teamId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_DELETE_GOAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_TEAM_DELETE_GOAL.replace('{teamId}', payload.teamId).replace('{goalId}', payload.goalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TEAM_GET_BY_INTERNAL_ID](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TEAM_GET_BY_INTERNAL_ID.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TASK_INFO_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_TASK_INFO_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_TASK_INFO_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_BY_TEAM](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_BY_TEAM.replace('{internalId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_BY_PROJECT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_BY_PROJECT.replace('{internalId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_BY_PROGRAMME](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_BY_PROGRAMME.replace('{internalId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GROUPS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GROUPS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GROUP_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GROUP_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GROUP_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_TASK_INFO_GROUP_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GROUP_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_TASK_INFO_GROUP_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GROUP_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_TASK_INFO_GROUP_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_TASK_INFO_GET_BY_WORKER_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_TASK_INFO_GET_BY_WORKER_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //PaperNumberGroup
        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_WORKER.replace('{workerId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_STATUS.replace('{id}', payload.id).replace('{status}', payload.status),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_NUMBER_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_NUMBER_STATUS.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload,
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_GET_PRESCRIPTION_DATA](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_GET_PRESCRIPTION_DATA,
                        headers: { Authorization: "Bearer " + token },
                        data: payload,
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('workerId', form.workerId)
                    payload.append('file', form.file)
                    payload.append('pwz', form.pwz)
                    payload.append('domainId', form.domainId)
                    payload.append('regon', form.regon)

                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_PAPER_NUMBER_GROUP_GET_AVAILABLE_FOR_WORKER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PAPER_NUMBER_GROUP_GET_AVAILABLE_FOR_WORKER.replace('{domain}', payload.domain).replace('{workerId}', payload.workerId),
                        headers: { Authorization: "Bearer " + token },
                        data: payload,
                    }).then(response => {
                        context.commit(Mutation.WORKER_SET_AVAILABLE_PAPER_GROUP, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}