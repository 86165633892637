import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        slots: []
    },

    mutations: {

        [Mutation.SCHEDULE_SET_SLOTS](currentState, payload) {
            currentState.slots = payload
        },
    },

    actions: {

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        // [Action.OFFICE_ADD_OFFICE](context, worker) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         let cid = context.rootState.clinic.activeClinic.clinicId;
        //         if (token != null) {
        //             axios({
        //                 method: 'post',
        //                 url: Apis.OFFICE_ADD.replace('{clinicId}', cid),
        //                 data: worker,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then((response) => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        /** Attempt get all workers. Uses GET request without parameter */
        // [Action.OFFICE_GET_ALL](context) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         let cid = context.rootState.clinic.activeClinic.clinicId;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 url: Apis.OFFICE_GET_ALL.replace('{clinicId}', cid),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        /** Attempt to get worker. Uses GET request */
        // [Action.OFFICE_GET_OFFICE](context, id) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         let cid = context.rootState.clinic.activeClinic.clinicId;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 url: Apis.OFFICE_GET.replace('{id}', id).replace('{clinicId}', cid),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        /** Attempt worker delete. Uses DELETE request */
        // [Action.OFFICE_DELETE_OFFICE](context, id) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         let cid = context.rootState.clinic.activeClinic.clinicId;
        //         if (token != null) {
        //             axios({
        //                 method: 'delete',
        //                 url: Apis.OFFICE_DELETE.replace('{id}', id).replace('{clinicId}', cid),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        // [Action.OFFICE_MODIFY_OFFICE](context, worker) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         let cid = context.rootState.clinic.activeClinic.clinicId;
        //         if (token != null) {
        //             axios({
        //                 method: 'put',
        //                 url: Apis.OFFICE_PUT.replace('{clinicId}', cid),
        //                 data: worker,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        // api/ERegistration/SetErState/{domain}/{id}/{erState}/{erReason}
        [Action.ER_END_APP_08](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ER_END_APP_08.replace('{id}', payload.id).replace('{erState}', 8).replace('{domain}', payload.domainId).replace('{erReason}', payload.reason),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_MARK_PATIENT_WAITING](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_MARK_PATIENT_WAITING.replace('{id}', payload.id).replace('{value}', payload.patientWaiting).replace('{domain}', payload.domainId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.S3_GET_UNFINISHED_APPOINTMENTS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_GET_UNFINISHED_APPOINTMENTS.replace('{id}', payload.id).replace('{monthsBack}', payload.monthsBack).replace('{domain}', payload.domainId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_ADD_TO_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_ADD_TO_GROUP.replace('{clinicId}', cid).replace('{id}', payload.id).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_REMOVE_FROM_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_REMOVE_FROM_GROUP.replace('{clinicId}', cid).replace('{id}', payload.id).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_SPLIT_GROUP](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_SPLIT_GROUP.replace('{clinicId}', cid).replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },






        // SCHEDULE

        /** Attempt to get worker. Uses GET request */
        [Action.S3_MODIFY_WORKTIME](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;

                payload.start = moment(payload.start).toDate()
                payload.stop = moment(payload.stop).toDate()

                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.SCHEDULE3_MODIFY_WORKTIME.replace('{clinicId}', cid).replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_FILL_WORKTIMES](context, payload) {

            payload.forEach(x => {
                x.start = moment(x.start).toDate()
                x.stop = moment(x.stop).toDate()
            })

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_FILL_WORKTIMES.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_OVERWRITE_WORKTIMES](context, payload) {

            payload.forEach(x => {
                x.start = moment(x.start).toDate()
                x.stop = moment(x.stop).toDate()
            })

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_OVERWRITE_WORKTIMES.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        /** Attempt to get worker. Uses GET request */
        [Action.SCHEDULE4_ADD_AREA](context, payload) {

            payload.forEach(x => {
                x.start = moment(x.start).toDate()
                x.stop = moment(x.stop).toDate()
            })

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE4_ADD_AREA.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.SCHEDULE4_ADD_AREA_EXPANDED](context, data) {

            var payload = data.items
            var mode = data.mode

            payload.forEach(x => {
                x.start = moment(x.start).toDate()
                x.stop = moment(x.stop).toDate()
            })

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;

                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE4_ADD_AREA_EXPANDED.replace('{mode}', mode).replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt to get worker. Uses GET request */
        [Action.S3_MOVE_PRO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.SCHEDULE3_MOVE_WORKTIME_PRO.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_WORKTIME_MULTI](context, payload) {

            payload.forEach(x => {
                x.start = moment(x.start).toDate()
                x.stop = moment(x.stop).toDate()
            })

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                //let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_WORKTIMEAREA_MULTIPLE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_DELETE_WORKTIMES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_DELETE_WORKTIMES.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S3_GET_SCHEDULE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_SEARCH.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S4_GET_SCHEDULE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE4_SEARCH.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_WORKTIMES_CYCLICAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_WORKTIMES_CYCLICAL.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_GET_APPOINTMENTS_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_FILTER_BY_PATIENT.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.TOOLS_GET_FILTERED_APPOINTMENTS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TOOLS_FILTER_APPS.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.CGATE_EMPLOYEES_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CGATE_EMPLOYEES_STATS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S2_GET_SCHEDULE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_SEARCH.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S2_GET_SCHEDULE_WORKER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_SCHEDULE.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.S4_GET_MANY_APPOINTMENTS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.S4_GET_MANY_APPOINTMENTS.replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },




        /** Attempt to get worker. Uses GET request */
        [Action.S2_SET_STATUS](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_SET_STATUS.replace('{id}', data.id).replace('{workerId}', data.workerId).replace('{status}', data.status).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S2_POST_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_POST_STATUS.replace('{id}', payload.id).replace('{clinicId}', cid),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S2_SEND_APPOINTMENT](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            payload.start = moment(payload.start).toDate()
            payload.stop = moment(payload.stop).toDate()
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE2_APPOINTMENT_POZ.replace('{clinicId}', cid),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.S2_SEND_APPOINTMENT_VEHICLE](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            payload.start = moment(payload.start).toDate()
            payload.stop = moment(payload.stop).toDate()

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE2_APPOINTMENT.replace('{clinicId}', cid),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.S2_EDIT_APPOINTMENT](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            payload.start = moment(payload.start).toDate()
            payload.stop = moment(payload.stop).toDate()

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE2_EDIT_APPOINTMENT_POZ.replace('{clinicId}', cid),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.S2_EDIT_APPOINTMENT_VEHICLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.SCHEDULE2_APPOINTMENT_EDIT.replace('{id}', payload.id).replace('{clinicId}', cid),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S2_SEND_WORKTIME](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            payload.start = moment(payload.start).toDate()
            payload.stop = moment(payload.stop).toDate()

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE2_WORKTIME.replace('{clinicId}', cid),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response);
                }).catch(error => { reject(error) });
            })
        },

        [Action.S2_DELETE_WORKTIME](context, id) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.SCHEDULE2_WORKTIME_DELETE.replace('{id}', id).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S2_DELETE_APPOINTMENT](context, id) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.SCHEDULE2_APPOINTMENT_DELETE.replace('{id}', id).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //
        [Action.S2_GET_APPOINTMENT](context, id) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_APPOINTMENT_GET.replace('{id}', id).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S2_RESCHEDULE_APPOINTMENT](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            payload.start = moment(payload.start).toDate()
            payload.stop = moment(payload.stop).toDate()

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE2_RESCHEDULE_APPOINTMENT.replace('{clinicId}', cid),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response);
                }).catch(error => { reject(error) });
            })
        },

        /** Attempt to cancel appointment. Uses GET request */
        [Action.S2_CANCEL_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_APPOINTMENT_CANCEL.replace('{id}', payload.id).replace('{reason}', payload.reason).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to cancel appointment. 
         * EXTENDED MEANS - ALLOWS FOR NEARLY ALL APPOINTMENT STATUSES EXCEPT 'Ongoing' (1), 'Completed' (5), Cancelled (6)
         * Uses GET request 
        */
        [Action.S2_APPOINTMENT_CANCEL_EXTENDED](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_APPOINTMENT_CANCEL_EXTENDED.replace('{id}', payload.id).replace('{reason}', payload.reason).replace('{domain}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S2_REFERRAL_PROBLEMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_REFERRAL_PROBLEMS.replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.S2_REFERRAL_PROBLEMS_FOR_PATIENT](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_REFERRAL_PROBLEMS_FOR_PATIENT.replace('{patientId}', patientId).replace('{clinicId}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_GET_APPOINTMENTS_FOR_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE3_GET_APPOINTMENTS_FOR_PATIENT.replace('{patientId}', payload.patientId).replace('{monthsBack}', payload.monthsBack).replace('{domain}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.SCHEDULE_SET_PATIENT_VISITS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_GET_APPOINTMENTS_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_FILTERV2.replace('{domain}', payload.domainId),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S2_GET_APPOINTMENTS_BY_TICKET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_GET_APPOINTMENTS_BY_TICKET.replace('{id}', payload.id).replace('{domain}', payload.domainId),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Function returns appointments for array of tickets
         * @param {Object} payload
         * @param {integer} domain - Worker domain id 
         * @param {integer} mode - mode of data fetch - 1 - exclude cancelled or historic apps, 0 or else - fetch all 
         * @returns {Array} Appointments for array of tickets
         */
        [Action.S2_GET_APPOINTMENTS_BY_MANY_TICKETS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        data: payload.tickets,
                        url: Apis.SCHEDULE2_GET_APPOINTMENTS_BY_MANY_TICKETS.replace('{domain}', payload.domainId).replace('{mode}', payload.mode),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Function adds ticket id value to schedule appointment's "tickets" array 
         * @param {Object} payload
         * @param {integer} appointmentId - Schedule appointment id
         * @param {string} ticketId - Ticket id
         * @param {integer} domain - Worker domain id 
         * @returns {Object} Modified schedule appointment object
         */
        [Action.S2_APPOINTMENT_ADD_TICKET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_APPOINTMENT_ADD_TICKET.replace('{appointmentId}', payload.appointmentId).replace('{ticketId}', payload.ticketId).replace('{domain}', payload.domain),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Function removes ticket id value to schedule appointment's "tickets" array 
         * @param {Object} payload
         * @param {integer} appointmentId - Schedule appointment id
         * @param {string} ticketId - Ticket id
         * @param {integer} domain - Worker domain id 
         * @returns {Object} Modified schedule appointment object
         */
        [Action.S2_APPOINTMENT_REMOVE_TICKET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE2_APPOINTMENT_REMOVE_TICKET.replace('{appointmentId}', payload.appointmentId).replace('{ticketId}', payload.ticketId).replace('{domain}', payload.domain),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Function adds ticket id value to schedule appointment's "tickets" array 
         * @param {Object} payload
         * @param {integer} appointmentId - Schedule appointment id
         * @param {integer} domain - Worker domain id 
         * @returns {String} schedule appointment version
         */
        [Action.S2_GET_APPOINTMENT_VERSION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE2_GET_APPOINTMENT_VERSION.replace('{id}', payload.appointmentId).replace('{domain}', payload.domain),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_APPOINTMENT_PATIENT_ABSENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_APPOINTMENT_PATIENT_ABSENT.replace('{id}', payload.id).replace('{value}', payload.value).replace('{domain}', payload.domain),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_APPOINTMENT_PATIENT_LATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.S3_APPOINTMENT_PATIENT_LATE.replace('{id}', payload.id).replace('{value}', payload.value).replace('{domain}', payload.domain),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}