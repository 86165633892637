import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        ewusReservation: null,
        ewusReservationGuid: null,
        ewusReservationCheckPending: false,
        triggerReservation: 0,
        
        ewus: null,
        ewusGuid: null,
        ewusCheckPending: false,
        trigger: 0,
        triggerCheckAppointmentsDashboard: 0,

        ewusArray: []
    },

    mutations: {
        [Mutation.EWUS_FLUSH_DATA](currentState) {
            currentState.ewusArray = []
            currentState.ewusCheckPending = false
        },
        [Mutation.EWUS_TRIGGER_UP](currentState) {
            currentState.trigger++
        },
        [Mutation.EWUS_APPS_DASHBOARD_TRIGGER_UP](currentState) {
            currentState.triggerCheckAppointmentsDashboard++
        },
        [Mutation.EWUS_SET_EWUS](currentState, payload) {
            currentState.ewus = payload
        },
        [Mutation.EWUS_SET_EWUS_PENDING](currentState, payload) {
            currentState.ewusCheckPending = payload
        },
        [Mutation.EWUS_SET_EWUS_GUID](currentState, payload) {
            currentState.ewusGuid = payload
        },
        [Mutation.EWUS_RESET](currentState) {
            currentState.ewus = null
            currentState.ewusCheckPending = false
        },
        [Mutation.EWUS_ADD_PATIENT](currentState, payload) {
            payload.numberOfRequests = 0
            currentState.ewusArray.push(payload)
        },

        // EWUS RESERVATION 
        [Mutation.EWUS_SET_EWUS_RESERVATION](currentState, payload) {
            currentState.ewusReservation = payload
        },
        [Mutation.EWUS_SET_EWUS_PENDING_RESERVATION](currentState, payload) {
            currentState.ewusReservationCheckPending = payload
        },
        [Mutation.EWUS_SET_EWUS_GUID_RESERVATION](currentState, payload) {
            currentState.ewusReservationGuid = payload
        },
        [Mutation.EWUS_RESET_RESERVATION](currentState) {
            currentState.ewusReservation = null
            currentState.ewusReservationCheckPending = false
        },
        [Mutation.EWUS_TRIGGER_UP_RESERVATION](currentState) {
            currentState.triggerReservation++
        },
        // EWUS RESERVATION END

        [Mutation.EWUS_EDIT_PATIENT](currentState, payload) {

            let patientObject = currentState.ewusArray.find(p => p.patientId === payload.patientId)

            let index = currentState.ewusArray.indexOf(patientObject);

            if (index > -1) {
                currentState.ewusArray[index] = payload
            }
        },
        [Mutation.EWUS_EDIT_PATIENT_BY_PESEL](currentState, payload) {

            let patientObject = currentState.ewusArray.find(p => p.pesel === payload.pesel)

            let index = currentState.ewusArray.indexOf(patientObject);

            if (index > -1) {
                currentState.ewusArray[index] = payload
            }
        },
        [Mutation.EWUS_RESET_NUMBER_OF_REQUESTS](currentState, patientId) {

            let patientObject = currentState.ewusArray.find(p => p.patientId === patientId)

            let index = currentState.ewusArray.indexOf(patientObject);

            if (index > -1) {
                if (currentState.ewusArray[index].numberOfRequests) {
                    if (currentState.ewusArray[index].ewusState && currentState.ewusArray[index].ewusState.ewusStatus != 6) {
                        currentState.ewusArray[index].ewusState = null
                    }
                    currentState.ewusArray[index].numberOfRequests = 0
                }
            }
        }
    },

    actions: {
        [Action.EWUS_CHECK_PESEL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',//only for string value
                        transformResponse: [data => data],//only for string value
                        url: Apis.EWUS_CHECK_PESEL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EWUS_CHECK_RESPONSE](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EWUS_CHECK_RESPONSE.replace('{id}', guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EWUS_GET_HISTORY](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EWUS_GET_HISTORY,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.EWUS_GET_HISTORY_BY_PESEL](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EWUS_GET_HISTORY_BY_PESEL.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Update Ewus info for appointment, used in context of:
         * -> Update Ewus if already saved Ewus has 'error' codes
         * -> Update Ewus if already saved Ewus showed lack of insurance, but it changed on the day of appointment
         */
        [Action.EWUS_UPDATE_APPOINTMENT_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EWUS_UPDATE_APPOINTMENT_INFO,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //EWUS CONfiGURATION WORKER - START

        [Action.EWUS_CONFIG_GET_BY_WORKER_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EWUS_CONFIG_GET_BY_WORKER_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EWUS_CONFIG_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EWUS_CONFIG_CREATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EWUS_CONFIG_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EWUS_CONFIG_UPDATE.replace('{id}', payload.workerId),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //EWUŚ CONFIGURATION WORKER - END
    }
}