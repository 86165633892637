<template>
  <div>
    <b-field :label="labelText" label-position="on-border">
      <b-autocomplete
        ref="icdsField"
        :data="dataFiltered"
        placeholder="Nazwa lub kod ICD10, min. 2 znaki"
        field="view"
        :loading="isFetching"
        @typing="queueSearch"
        @select="(option) => icdChoosen(option)"
        :clear-on-select="true"
      >
        <template slot-scope="props">
          <div class="level">
            <div class="level-left">
              <div
                class="level-item has-text-weight-bold"
                :class="{ 'has-text-primary': props.option.main }"
              >
                <div style="width: 3rem;">{{ props.option.code }}</div>
              </div>
              <div
                class="level-item has-text-weight-semibold"
                :class="{ 'has-text-primary has-text-weight-bold': props.option.main }"
              >{{ props.option.desc }}</div>
            </div>
            <div class="level-right">
              <div
                class="pl-3 level-item has-text-grey"
              >{{ getCategory(props.option.code.charAt(0)) }}</div>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import { Action } from '../../store/config_actions';

export default {
  name: 'Icd10SearchBar',

  data: function () {
    return {
      data: [],
      selected: null,
      isFetching: false,
      currentName: '',

    }
  },

  props: {
    icdKeyTrigger: { type: Number, required: false },
    referralIcd10Code: { type: String, required: false },
    limit: { type: Array, required: false },
    labelText: { type: String, required: false, default: "Szukaj rozpoznania" }
  },

  mounted() {
    if (this.referralIcd10Code) {
      this.currentName = this.referralIcd10Code
      this.getAsyncData(this.referralIcd10Code)
    }
  },

  watch: {
    icdKeyTrigger() {
      if (this.$refs.icdsField) {
        this.$refs.icdsField.focus()
      }
    },

    referralIcd10Code() {
      if (this.referralIcd10Code) {
        this.currentName = this.referralIcd10Code
        this.getAsyncData(this.referralIcd10Code)
      }
    }
  },

  computed: {

    dataFiltered() {
      if (this.limit) {
        return this.data.filter(x => this.limit.includes(x.code))
      }
      else {
        return this.data
      }
    },

    myRoles() {
      if (this.$store.state.employee.me) {
        return this.$store.state.employee.me.roles
      } else return []
    },

    amINurse() {
      let k = 0
      if (this.myRoles.length > 0) {
        this.myRoles.forEach(el => {
          if (el == 40 || el == 41) {
            k++
          }
        });
      }
      if (k == 0) {
        return false
      } else {
        return true
      }
    },
  },

  methods: {

    icdChoosen(item) {
      if (item) this.$emit('selected', item)
    },

    queueSearch(name) {
      this.currentName = name
      this.getAsyncData(name)
    },

    getAsyncData(name) {
      if (name.length > 1 /*&& !this.isFetching*/) {
        this.isFetching = true

        this.$store.dispatch(Action.PHARMA_SEARCH_ICD10, name)
          .then((response) => {
            if (this.currentName === name) {
              this.isFetching = false
              this.data = response

              if (this.referralIcd10Code) {
                if (this.data && this.data.length > 0) {
                  this.icdChoosen(this.data[0])
                }
              }
            }
          })
          .catch(error => {
            this.apiProblem(error);
            this.data = []
            this.isFetching = false
          });
      }
      else {
        this.data = []
      }
    },

    getCategory(category) {
      switch (category) {
        case 'A': return 'Wybrane choroby zakaźne i pasożytnicze'
        case 'B': return 'Niektóre choroby zakaźne i pasożytnicze'
        case 'C': return 'Nowotwory'
        case 'D': return 'Nowotwory in situ'
        case 'E': return 'Zaburzenia wydzielania wewnętrznego, stanu odżywienia i przemiany metabolicznej'
        case 'F': return 'Zaburzenia psychiczne i zaburzenia zachowania'
        case 'G': return 'Choroby układu nerwowego'
        case 'H': return 'Choroby oka i przydatków oka, ucha i wyrostka sutkowatego'
        case 'I': return 'Choroby układu krążenia'
        case 'J': return 'Choroby układu oddechowego'
        case 'K': return 'Choroby układu pokarmowego'
        case 'L': return 'Choroby skóry i tkanki podskórnej'
        case 'M': return 'Choroby układu mięśniowo-szkieletowego i tkanki łącznej'
        case 'N': return 'Choroby układu moczowo-płciowego'
        case 'O': return 'Ciąża, poród i połóg'
        case 'P': return 'Wybrane stany rozpoczynające się w okresie okołoporodowym'
        case 'Q': return 'Wady rozwojowe wrodzone, zniekształcenia i aberracje chromosomowe'
        case 'R': return 'Objawy, cechy chorobowe oraz nieprawidłowe wyniki badań klinicznych i laboratoryjnych niesklasyfikowane gdzie indziej'
        case 'S': return 'Urazy, zatrucia i inne określone skutki działania czynników zewnętrznych'
        case 'T': return 'Urazy obejmujące liczne okolice ciała'
        case 'U': return 'Kody do celów specjalnych'
        case 'V': return 'Zewnętrzne przyczyny zachowania i zgonu'
        case 'W': return 'Zewnętrzne przyczyny zachowania i zgonu'
        case 'X': return 'Zewnętrzne przyczyny zachowania i zgonu'
        case 'Y': return 'Zewnętrzne przyczyny zachowania i zgonu'
        case 'Z': return 'Czynniki wpływające na stan zdrowia i kontakt ze służbą zdrowia'
        default: return 'Inna'
      }

    }
  }
}
</script>

<style scoped lang="scss">
</style>
