// import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
// import { Apis } from '../config_apis'
// import axios from 'axios'
// import moment from 'moment'

export default {
    namespaced: false,

    state: {

        // CYTOLOGY APPOINTMENT
        ongoingAppointment: null,
        focusAppointment: null,

        //WORKING PLACE
        selectedFacility: null,
        selectedRoom: null,
        selectedUnit: null,
        selectedSubunit: null,
        selectedOffice: null,

        // STANDS
        activeStand: null,
        stands: [],

        //CONTAINERS
        activeContainer: null,
        containers: [],

        // SAMPLES
        cytologySamples: [],

        // TRIGGERS
        trigger: 0,
        trigger2: 0,

        // USER ACTIVE STANDS
        activeStands: [],
        activeStandsTrigger: 0,

        // LAB EXAMS
        labExams: [],

        // LAB BASES
        labExamBases: [],

        // SOLAB
        solabExams: [],

        // MIDWIFE COLLECTION POINT
        orders: [],
        tmpOrders: [],
        collectedDate: null,
        collectedBy: -1,
        referringPhysician: ""
    },

    mutations: {

        // [Mutation.CYTOLOGY_SET_ONGOING_APPOINTMENT](state, payload) {
        //     state.ongoingAppointment = payload
        // },

        // [Mutation.CYTOLOGY_SET_FOCUS_APPOINTMENT](state, payload) {
        //     state.focusAppointment = payload
        // },

        // [Mutation.CYTOLOGY_CLEAR_ONGOING_APPOINTMENT](state) {
        //     state.ongoingAppointment = null
        // },

        // [Mutation.CYTOLOGY_RESET](state) {
        //     state.ongoingAppointment = null
        //     state.focusAppointment = null
        // },

        [Mutation.CYTOLOGY_SET_LAB_EXAMS](currentState, payload) {
            currentState.labExams = payload
        },

        [Mutation.CYTOLOGY_SET_LAB_EXAM_BASES](currentState, payload) {
            currentState.labExamBases = payload
        },

        [Mutation.CYTOLOGY_SET_ACTIVE_STAND](currentState, payload) {
            currentState.activeStand = payload
            currentState.trigger++
        },

        [Mutation.CYTOLOGY_RESET_ACTIVE_STAND](currentState) {
            currentState.activeStand = null
            currentState.trigger++
        },

        [Mutation.CYTOLOGY_SEND_SAMPLE](currentState, payload) {
            currentState.cytologySamples.push(payload)
        },

        [Mutation.CYTOLOGY_TRIGGER_UP](currentState) {
            currentState.trigger++
        },

        [Mutation.CYTOLOGY_TRIGGER_2_UP](currentState) {
            currentState.trigger2++
        },

        [Mutation.CYTOLOGY_ADD_ACTIVE_STAND](currentState, payload) {
            currentState.activeStands.push(payload)
            currentState.activeStandsTrigger++
        },

        [Mutation.CYTOLOGY_SET_WOKPLACE_FACILITY](currentState, facility) {
            currentState.selectedFacility = facility
        },

        [Mutation.CYTOLOGY_SET_WOKPLACE_ROOM](currentState, room) {
            currentState.selectedRoom = room
        },

        [Mutation.CYTOLOGY_SET_WOKPLACE_OFFICE](currentState, office) {
            currentState.selectedOffice = office
        },

        [Mutation.CYTOLOGY_ADD_SOLAB_EXAM](currentState, payload) {
            currentState.solabExams.push(payload)
        },

        [Mutation.CYTOLOGY_SET_ORDER](currentState, payload) {
            currentState.orders.push(payload)
        },

        [Mutation.CYTOLOGY_UPDATE_ORDERS](currentState, payload) {
            currentState.orders = payload
        },

        [Mutation.CYTOLOGY_SET_TMP_ORDERS](currentState, payload) {
            currentState.tmpOrders = payload
        },

        [Mutation.CYTOLOGY_RESET_ORDERS_LIST](currentState) {
            currentState.orders = []
            currentState.tmpOrders = []
            currentState.collectedDate = null
            currentState.collectedBy = null
            currentState.referringPhysician = ""
        },

        [Mutation.CYTOLOGY_REMOVE_ORDER](currentState, payload) {
            let index = currentState.orders.indexOf(payload)
            if (index > -1) {
                currentState.orders.splice(index, 1)
            } else {
                let found = currentState.orders.find(order => order.id == payload.id)
                if (found) {
                    let index2 = currentState.orders.indexOf(found)
                    if (index2 > -1) {
                        currentState.orders.splice(index, 1)
                    }
                }
            }
        },

        [Mutation.CYTOLOGY_SET_COLLECTED_DATE](currentState, payload) {
            currentState.collectedDate = payload
        },

        [Mutation.CYTOLOGY_SET_COLLECTED_BY](currentState, payload) {
            currentState.collectedBy = payload
        },

        [Mutation.CYTOLOGY_SET_REFERRING_PHYSICIAN](currentState, payload) {
            currentState.referringPhysician = payload
        }
    },

    actions: {}
}