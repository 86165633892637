import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
// import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        [Action.FINANCE_MED_SERVICE_RECALCULATE_ITEM](context, medServiceSet) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_RECALCULATE_ITEM,
                        data: medServiceSet,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add med service set. Uses POST request and requires med service set data object (check config_apis.js) */
        [Action.FINANCE_MED_SERVICE_SET_CREATE](context, medServiceSet) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_SET_GET_ALL,
                        data: medServiceSet,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_MED_SERVICE_SET_MED_POINTS](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_SET_MED_POINTS,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_MED_SERVICE_SET_ERRORS](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_SET_ERRORS,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to filter med service set. Uses POST request and requires med service set data filter (check config_apis.js) */
        [Action.FINANCE_MED_SERVICE_SET_FILTER](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_SET_FILTER,
                        data: filter,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt get all med service set. Uses GET request without parameter */
        [Action.FINANCE_MED_SERVICE_SET_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MED_SERVICE_SET_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med service set. Uses GET request */
        [Action.FINANCE_MED_SERVICE_SET_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MED_SERVICE_SET_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med service set delete. Uses DELETE request */
        [Action.FINANCE_MED_SERVICE_SET_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_MED_SERVICE_SET_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med service set edit. Uses PUT request with id and requires med service set data object (check config_apis.js)*/
        [Action.FINANCE_MED_SERVICE_SET_UPDATE](context, medServiceSet) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_MED_SERVICE_SET_GET.replace('{id}', medServiceSet.id),
                        data: medServiceSet,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med service set history. Uses GET request */
        [Action.FINANCE_MED_SERVICE_SET_HISTORY](context, setId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MED_SERVICE_SET_HISTORY.replace('{setId}', setId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to revert med service set changes. Uses GET request */
        [Action.FINANCE_MED_SERVICE_SET_REVERT](context, revert) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_MED_SERVICE_SET_REVERT.replace('{id}', revert.setId).replace('{historyId}', revert.historyId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to count med service set. Uses POST request and requires med service set data count (check config_apis.js) */
        [Action.FINANCE_MED_SERVICE_SET_COUNT_PROD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_SET_COUNT_PROD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_MED_SERVICE_GET_BY_BILL_IDS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_MED_SERVICE_GET_BY_BILL_IDS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        

    }
}