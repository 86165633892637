<template>
  <div>
    <b-button
      size="is-small"
      type="is-danger"
      class="mb-3"
      icon-left="magnify"
      :disabled="!patient"
      @click="isPatientEreferralModalActive = true"
    >
      Podgląd e-skierowań pacjenta dla nieaktywnych wizyt
    </b-button>

    <b-modal
      :active.sync="isPatientEreferralModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      class="ereferral-modal"
      full-screen
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">
            Podgląd e-skierowań pacjenta dla nieaktywnych wizyt
          </p>
        </header>
        <section class="modal-card-body">
          <div>
            <b-field grouped style="padding-top: 0.8rem">
              <b-tooltip label="Odśwież">
                <b-button
                  icon-left="download"
                  type="is-primary"
                  :disabled="requestInProgress"
                  @click="getAppointments()"
                  >Pobierz dane</b-button
                >
              </b-tooltip>
            </b-field>
            <b-table
              detail-key="id"
              :data="appointmentsFiltered"
              :loading="requestInProgress"
              narrowed
              hoverable
              bordered
              paginated
              per-page="20"
              style="font-size: 0.9rem"
            >
              <b-table-column v-slot="props" label="ID">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column
                field="officeName"
                label="Gabinet"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="officeNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.officeName }}</template>
              </b-table-column>
              <b-table-column
                field="facilityName"
                label="Przychodnia"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="facilityNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.facilityName }}</template>
              </b-table-column>
              <b-table-column field="date" label="Data" centered searchable>
                <template #searchable>
                  <b-input
                    expanded
                    v-model="dateFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.date }}</template>
              </b-table-column>
              <b-table-column field="time" label="Godzina" centered searchable>
                <template #searchable>
                  <b-input
                    expanded
                    v-model="timeFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.time }}</template>
              </b-table-column>
              <b-table-column
                field="serviceName"
                label="Sposób przeprowadzenia"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="serviceNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.serviceName }}</template>
              </b-table-column>
              <b-table-column
                field="payerTypeName"
                label="Płatnik"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="payerTypeNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{
                  props.row.payerTypeName
                }}</template>
              </b-table-column>
              <b-table-column
                field="stateName"
                label="Status"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="stateNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.stateName }}</template>
              </b-table-column>
              <b-table-column
                field="visitTypeName"
                label="Typ wizyty"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="visitTypeNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{
                  props.row.visitTypeName
                }}</template>
              </b-table-column>
              <b-table-column
                field="clinicName"
                label="Typ poradni"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="clinicNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.clinicName }}</template>
              </b-table-column>
              <b-table-column
                field="patientName"
                label="Pacjent"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="patientNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.patientName }}</template>
              </b-table-column>
              <b-table-column
                field="employeeName"
                label="Pracownik wynkonujący"
                centered
                searchable
              >
                <template #searchable>
                  <b-input
                    expanded
                    v-model="employeeNameFilter"
                    placeholder="Szukaj..."
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.employeeName }}</template>
              </b-table-column>
              <b-table-column label="Akcje" centered>
                <template v-slot="props">
                  <div
                    v-id="
                      props.row.ticket &&
                      props.row.ticket.referral &&
                      props.row.ticket.referral.eReferral &&
                      props.row.ticket.referral.eReferral.id != null
                    "
                  >
                    <b-button
                      class="mb-3"
                      size="is-small"
                      icon-left="magnify"
                      @click="
                        appointmentReferralView =
                          props.row.ticket.referral.eReferral;
                        isViewReferralModalActive = true;
                      "
                    >
                      Podgląd skierowania
                    </b-button>
                    <b-button
                      class="mb-3"
                      size="is-small"
                      type="is-danger"
                      icon-left="delete"
                      @click="
                        appointmentTicket = props.row.ticket;
                        triggerCancelReferral += 1;
                      "
                      >Anuluj skierowanie
                    </b-button>
                  </div>
                </template>
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="emoticon-sad-outline"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>Brak wizyt.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </section>
        <footer class="modal-card-foot has-background-kin">
          <b-button @click="isPatientEreferralModalActive = false"
            >Zamknij</b-button
          >
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isViewReferralModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
    >
      <div class="modal-card" v-if="appointmentReferralView">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">Podgląd skierowania</p>
        </header>
        <section class="modal-card-body" style="width: auto; min-height: 400px">
          <div style="color: black;" v-html="appointmentReferralView.eSkierowanie"></div>
        </section>
        <footer class="modal-card-foot has-background-kin">
          <b-button @click="isViewReferralModalActive = false"
            >Zamknij</b-button
          >
        </footer>
      </div>
    </b-modal>

    <AppointmentCancelReferralP1
      :trigger="triggerCancelReferral"
      :ticket="appointmentTicket"
    />
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import DateMixins from "@/mixins/date_helpers";
import { Action } from "@/store/config_actions";
import moment from "moment";
// import PatientSearch from '@/components/patient/PatientPanelSearch2';
import AppointmentCancelReferralP1 from "@/components/appointments/AppointmentCancelReferralP1";

export default {
  name: "AppointmentEreferralPatient",
  mixins: [CommonHelpers, DateMixins],
  components: {
    // PatientSearch,
    AppointmentCancelReferralP1,
  },

  props: {
    patient: { type: Object, required: true }
  },

  data: function () {
    return {
      isPatientEreferralModalActive: false,
      appointments: [],
      appointmentReferralView: null,
      isViewReferralModalActive: false,

      appointmentTicket: null,
      triggerCancelReferral: 0,

      payerTypeObject: [
        {
          id: 0,
          name: "Komercja",
        },
        {
          id: 1,
          name: "NFZ",
        },
        {
          id: 2,
          name: "MZ",
        },
      ],

      servicesObject: [
        {
          id: 0,
          name: "Wizyta w przychodni",
        },
        {
          id: 1,
          name: "Teleporada",
        },
        {
          id: 2,
          name: "Wizyta domowa",
        },
      ],

      stateObject: [
        {
          id: 0,
          name: "Wizyta nie została rozpoczęta",
        },
        {
          id: 1,
          name: "Wizyta rozpoczęta wcześniej, ale nie zakończona",
        },
        {
          id: 2,
          name: "Wizyta rozpoczęta wcześniej, współdzielona",
        },
        // {
        //   id: 3,
        //   name: "Wizyta wstrzymana"
        // },
        // {
        //   id: 4,
        //   name: "Wizyta odrzucona (?)"
        // },
        {
          id: 5,
          name: "Wizyta zakończona",
        },
        {
          id: 6,
          name: "Wizyta odwołana",
        },
        {
          id: 7,
          name: "Wizyta przełożona",
        },
        {
          id: 8,
          name: "Wizyta nie odbyła się. Termin wizyty minął.",
        },
      ],

      dateFrom: new Date(moment().add(-7, "days").startOf("day")),
      dateTo: new Date(moment().endOf("day")),

      requestInProgress: false,

      officeNameFilter: "",
      facilityNameFilter: "",
      dateFilter: "",
      timeFilter: "",
      serviceNameFilter: "",
      stateNameFilter: "",
      payerTypeNameFilter: "",
      patientNameFilter: "",
      employeeNameFilter: "",
      clinicNameFilter: "",
      visitTypeNameFilter: "",

      filterOffice: "",
      filterOfficeId: null,

      filterFacility: "",
      filterFacilityId: null,

      filterEmployee: "",
      filterEmployeeId: null,

      filterConfigAppointment: "",
      filterConfigAppointmentId: null,

      filterConfigClinic: "",
      filterConfigClinicId: null,

      payerTypeFilter: [],
      servicesFilter: [],
      stateFilter: [],
    };
  },

  mounted() {},

  watch: {
    isPatientEreferralModalActive() {
      if (this.isPatientEreferralModalActive) {
        this.getAppointments();
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },
    employAll() {
      return this.$store.state.employee.all ?? [];
    },

    employeeFiltered() {
      return this.employAll.filter((option) => {
        let id = (option.lastName + " " + option.firstName)
          .toString()
          .toLowerCase();
        if (this.filterEmployee !== null) {
          return id.indexOf(this.filterEmployee.toLowerCase()) >= 0;
        } else return false;
      });
    },

    contractors() {
      return this.$store.state.contractors.all ?? [];
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? [];
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.filterFacility.toLowerCase()) >= 0
          );
        } else return false;
      });
    },

    units() {
      return this.$store.state.orgUnit.all ?? [];
    },

    subunits() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    perms() {
      return this.$store.state.employee.permits ?? [];
    },

    // filterAppointemnts() {
    //   let filtered = this.appointments;

    //   if (!this.filterDevices) {
    //     return filtered;
    //   } else {
    //     return filtered.filter(
    //       (appo) => appo.deviceId == this.filterDevices.id
    //     );
    //   }
    // },

    subjects() {
      return this.$store.state.finance.subjects ?? [];
    },

    // subjectsFiltered() {
    //   return this.subjects.filter((option) => {
    //     return (
    //       option.nameFull
    //         .toLowerCase()
    //         .indexOf(this.subjectsFilter.toLowerCase()) >= 0
    //     );
    //   });
    // },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    configClinicsFiltered() {
      return this.configClinics.filter((option) => {
        return (
          option.name
            .toLowerCase()
            .indexOf(this.filterConfigClinic.toLowerCase()) >= 0
        );
      });
    },

    configAppointments() {
      return this.$store.state.config.appointments;
    },

    configAppointmentsFiltered() {
      return this.configAppointments.filter((option) => {
        return (
          option.name
            .toLowerCase()
            .indexOf(this.filterConfigAppointment.toLowerCase()) >= 0
        );
      });
    },

    medicalDevices() {
      return this.$store.state.devices.medDevices ?? [];
    },

    allOffices() {
      return this.$store.state.offices.offices ?? [];
    },

    filteredOffices() {
      return this.allOffices.filter((option) => {
        let id = "";

        if (option.pharse) {
          id = option.pharse.toLowerCase();
        } else {
          id = option.name.toLowerCase();
        }

        return id.indexOf(this.filterOffice.toLowerCase()) >= 0;
      });
    },

    appointmentsFiltered() {
      let appointmentsFiltered = this.appointments;

      if (this.filterOfficeId) {
        appointmentsFiltered = appointmentsFiltered.filter(
          (app) => app.officeId == this.filterOfficeId
        );
      }

      if (this.filterFacilityId) {
        appointmentsFiltered = appointmentsFiltered.filter(
          (app) =>
            app.officeObject &&
            app.officeObject.facilityId == this.filterFacilityId
        );
      }

      if (this.filterEmployeeId) {
        appointmentsFiltered = appointmentsFiltered.filter(
          (app) => app.employee == this.filterEmployeeId
        );
      }

      if (this.filterConfigAppointmentId) {
        appointmentsFiltered = appointmentsFiltered.filter(
          (app) => app.type == this.filterConfigAppointmentId
        );
      }

      if (this.filterConfigClinicId) {
        appointmentsFiltered = appointmentsFiltered.filter(
          (app) => app.clinic == this.filterConfigClinicId
        );
      }

      if (this.payerTypeFilter.length > 0) {
        appointmentsFiltered = appointmentsFiltered.filter((app) =>
          this.payerTypeFilter.includes(app.payerType)
        );
      }

      if (this.servicesFilter.length > 0) {
        appointmentsFiltered = appointmentsFiltered.filter((app) =>
          this.servicesFilter.includes(app.service)
        );
      }

      if (this.stateFilter.length > 0) {
        appointmentsFiltered = appointmentsFiltered.filter((app) =>
          this.stateFilter.includes(app.state)
        );
      }

      appointmentsFiltered = appointmentsFiltered.filter((option) => {
        return (
          option.officeName
            .toLowerCase()
            .indexOf(this.officeNameFilter.toLowerCase()) >= 0
        );
      });

      if (this.facilityNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.facilityName
              .toLowerCase()
              .indexOf(this.facilityNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.dateFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.date.toLowerCase().indexOf(this.dateFilter.toLowerCase()) >=
            0
          );
        });
      }

      if (this.timeFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.time.toLowerCase().indexOf(this.timeFilter.toLowerCase()) >=
            0
          );
        });
      }

      if (this.serviceNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.serviceName
              .toLowerCase()
              .indexOf(this.serviceNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.stateNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.stateName
              .toLowerCase()
              .indexOf(this.stateNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.payerTypeNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.payerTypeName
              .toLowerCase()
              .indexOf(this.payerTypeNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.patientNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.patientName
              .toLowerCase()
              .indexOf(this.patientNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.employeeNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.employeeName
              .toLowerCase()
              .indexOf(this.employeeNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.clinicNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.clinicName
              .toLowerCase()
              .indexOf(this.clinicNameFilter.toLowerCase()) >= 0
          );
        });
      }

      if (this.visitTypeNameFilter) {
        appointmentsFiltered = appointmentsFiltered.filter((option) => {
          return (
            option.visitTypeName
              .toLowerCase()
              .indexOf(this.visitTypeNameFilter.toLowerCase()) >= 0
          );
        });
      }

      return appointmentsFiltered;
    },
  },

  methods: {
    translateAppointments(appointments, patients, tickets) {
      appointments.forEach((a) => {
        let patient = patients.find((p) => p.id === a.patientId);

        if (patient) {
          patient.age = moment().diff(moment(patient.dateOfBirth), "years");

          a.patient = patient;
          a.patientName = `${patient.firstName} ${patient.lastName}`;
        }

        let ticket = tickets.find((tick) => tick.id == a.ticket);

        if (ticket) {
          a.ticket = ticket;
        }

        a.stateName = this.getStateName(a.state);
        a.payerTypeName = this.getPayerTypeName(a.payerType);
        a.serviceName = this.getServiceName(a.service);
        a.deviceName = this.getMedicalDevice(a.device);
        a.deviceObject = this.getMedicalDeviceObject(a.device);
        a.officeName = this.getOffice(a.officeId);
        a.officeObject = this.getOfficeObject(a.officeId);
        a.date = this.mDate(a.start);
        a.time = this.mTime(a.start);

        a.employeeName = this.getWorker(a.employee);
        a.clinicName = this.getClinic(a.clinic);
        a.visitTypeName = `${this.getAppName(a.type)}`;

        let subcategoryName = this.getAppSubCategory(a.type, a.subtype);
        if (subcategoryName) {
          a.visitTypeName += `, ${subcategoryName}`;
        }

        if (a.officeObject) {
          a.facilityName = this.getFacility(a.officeObject.facilityId);
        } else {
          a.facilityName = "Brak informacji";
        }
      });

      this.appointments = appointments;
    },

    getAppointments() {
      this.requestInProgress = true;

      this.$store
        .dispatch(Action.S2_REFERRAL_PROBLEMS_FOR_PATIENT, this.patient.id)
        .then((response) => {
          let patients = [];
          let ticketsToGet = [];
          let tickets = [];
          let appointments = [];

          response.forEach((appo) => {
            if (!patients.includes(appo.patientId)) {
              patients.push(appo.patientId);
            }

            if (!ticketsToGet.includes(appo.ticket)) {
              ticketsToGet.push(appo.ticket);
            }

            appointments.push(appo);
          });

          this.$store
            .dispatch(Action.STATS_GET_TICKETS_MULTIPLE, ticketsToGet)
            .then((resp) => {
              tickets = resp;
              if (patients.length > 0) {
                this.$store
                  .dispatch(Action.PATIENT_GET_MANY, patients)
                  .then((resp) => {
                    this.translateAppointments(
                      appointments,
                      resp.data,
                      tickets
                    );
                    this.requestInProgress = false;
                  })
                  .catch((error) => {
                    this.apiProblem(error);
                    this.requestInProgress = false;
                  });
              } else {
                this.translateAppointments(appointments, [], tickets);
                this.requestInProgress = false;
              }
            })
            .catch((error) => {
              this.apiProblem(error);
            });
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },

    getWorker(id) {
      if (this.employAll && id) {
        let emp = this.employAll.find((e) => e.id === id);

        if (emp) {
          return emp.lastName + " " + emp.firstName;
        } else return "Brak informacji";
      } else return "Brak informacji";
    },

    getContractor(id) {
      if (id) {
        let pos = this.contractors.find((s) => s.id === id);
        if (pos) return pos.nameFull;
        else return `??? [${id}]`;
      } else return "Brak informacji";
    },

    getFacility(id) {
      if (this.facilities && id) {
        let found = this.facilities.find((s) => s.id === id);
        if (found) {
          return found.name;
        }
      }
      return "Brak informacji";
    },

    getUnitOrganization(id) {
      if (this.subunits) {
        let found = this.subunits.find((un) => un.id === id);
        if (found && found.organizationalUnitId && this.units) {
          let foundUnit = this.units.find(
            (un) => un.id === found.organizationalUnitId
          );
          if (foundUnit) {
            return foundUnit.name;
          }
        }
      }
      return "Brak informacji";
    },

    getSubunit(id) {
      if (this.subunits) {
        let found = this.subunits.find((un) => un.id === id);
        if (found) {
          return found.name;
        }
      }
      return "Brak informacji";
    },

    getSubjects() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_ALL)
        .then(() => {
          //this.subjects = response;
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    getMedicalDevice(id) {
      if (this.medicalDevices && id) {
        let medDev = this.medicalDevices.find((e) => e.id === id);

        if (medDev) {
          return `${medDev.name} (${medDev.shortName})`;
        } else return "Brak informacji";
      } else return "Brak informacji";
    },

    getMedicalDeviceObject(id) {
      if (this.medicalDevices && id) {
        let medDev = this.medicalDevices.find((e) => e.id === id);

        if (medDev) {
          return medDev;
        } else return null;
      } else return null;
    },

    getOffice(id) {
      let category = this.allOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `Brak informacji`;
    },

    getOfficeObject(id) {
      if (this.allOffices && id) {
        let office = this.allOffices.find((c) => c.id == id);

        if (office) {
          return office;
        } else return null;
      } else return null;
    },

    dateFromChange(value) {
      this.dateFrom = new Date(moment(value).startOf("day"));
    },

    dateToChange(value) {
      this.dateTo = new Date(moment(value).endOf("day"));
    },

    prefab(item) {
      this.dates = [];
      switch (item) {
        case 0:
          this.dateFrom = new Date(moment().add(-7, "days").startOf("day"));
          this.dateTo = new Date(moment().endOf("day"));
          break;
        case 1:
          this.dateFrom = new Date(moment().startOf("week").add(1, "days"));
          this.dateTo = new Date(moment().endOf("week").add(1, "days"));
          break;
        case 2:
          this.dateFrom = new Date(
            moment().add(-1, "week").startOf("week").add(1, "days")
          );
          this.dateTo = new Date(
            moment().add(-1, "week").endOf("week").add(1, "days")
          );
          break;
        case 3:
          this.dateFrom = new Date(moment().startOf("month"));
          this.dateTo = new Date(moment().endOf("month"));
          break;
        case 4:
          this.dateFrom = new Date(moment().add(-1, "months").startOf("month"));
          this.dateTo = new Date(moment().add(-1, "months").endOf("month"));
          break;
        case 5:
          this.dateFrom = new Date(moment().startOf("year"));
          this.dateTo = new Date(moment().endOf("year"));
          break;
        case 6:
          this.dateFrom = new Date(moment().add(-1, "year").startOf("year"));
          this.dateTo = new Date(moment().add(-1, "year").endOf("year"));
          break;
      }
    },

    getStateName(state) {
      if (state == -1) {
        return "Brak informacji o statusie wizyty";
      } else if (state == 0) {
        return "Wizyta nie została rozpoczęta";
      } else if (state == 1) {
        return "Wizyta rozpoczęta wcześniej, ale nie zakończona";
      } else if (state == 2) {
        return "Wizyta rozpoczęta wcześniej, współdzielona";
      } else if (state == 5) {
        return "Wizyta zakończona";
      } else if (state == 6) {
        return "Wizyta odwołana";
      } else if (state == 7) {
        return "Wizyta przełożona";
      } else if (state == 8) {
        return "Wizyta nie odbyła się. Termin wizyty minął.";
      }

      return "Nieznany status wizyty";
    },

    getPayerTypeName(payerType) {
      if (payerType == 0) {
        return "Komercja";
      } else if (payerType == 1) {
        return "NFZ";
      } else if (payerType == 2) {
        return "MZ";
      }

      return "Brak informacji";
    },

    getServiceName(service) {
      if (service == 0) {
        return "Wizyta w przychodni";
      } else if (service == 1) {
        return "Teleporada";
      } else if (service == 2) {
        return "Wizyta domowa";
      }

      return "Brak informacji";
    },

    getClinic(id) {
      if (this.configClinics && id) {
        let clinic = this.configClinics.find((option) => option.id === id);
        if (clinic) {
          return clinic.shortName;
        }
      }

      return "Brak informacji";
    },

    getAppName(id) {
      let category = this.configAppointments.find((c) => c.id == id);
      if (category) return category.name;
      else return "Brak informacji";
    },

    getAppSubCategory(id, sid) {
      if (sid === null || sid < 0) return "";

      let category = this.configAppointments.find((c) => c.id == id);

      if (category) {
        if (category.subcategories) {
          let subcategory = category.subcategories.find((s) => s.id == sid);
          if (subcategory) {
            return subcategory.name;
          }
        }
      }

      return "Brak informacji";
    },
  },
};
</script>

<style scoped lang="scss">
.vam {
  vertical-align: middle;
}

.ereferral-modal {
  max-width: 100% !important;
  width: 100% !important;
}
</style>
