import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        examImageType: [],
        examLabType: [],
        examLabTypeReduced: [],
        examOtherType: [],
        examDentalType: [],
        examPsycheType: [],
        examPhysioType: [],
        sampleBase: [],
    },

    getters: {},

    mutations: {

        [Mutation.RESULTS_LAB_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examLabType = payload;

            state.examLabTypeReduced = []
            payload.forEach((exam) => {
                let object = {
                    id: exam.id,
                    internalId: exam.internalId,
                    name: exam.name ?? "",
                    codeICD9: exam.codeICD9 ?? "",
                    isRefundedPOZ: exam.isRefundedPOZ,
                    isRefundedAOS: exam.isRefundedAOS,
                    solabName: exam.solabName ?? "",
                    isIntergratedCare: exam.isIntergratedCare,
                    isIntergratedCareDiagnostic: exam.isIntergratedCareDiagnostic,
                    requiredPreparation: exam.requiredPreparation ?? "",
                    nameSearch: `${exam.name} ${exam.codeICD9} ${exam.solabName}`,
                    isAdded: false,
                    isDisabled: exam.isDisabled,
                    additionalGroups: exam.additionalGroups
                }
                state.examLabTypeReduced.push(object)
            })
            state.examLabTypeReduced = state.examLabTypeReduced.sort((a, b) => a.name.localeCompare(b.name))
        },

        [Mutation.RESULTS_LAB_EXAMINATION_TYPE_ADD](state, payload) {
            state.examLabType.push(payload)
        },

        [Mutation.RESULTS_LAB_EXAMINATION_TYPE_REMOVE](state, payload) {
            let index = state.examLabType.findIndex(u => u.id === payload)

            if (index > -1) {
                state.examLabType.splice(index, 1)
            }
        },

        [Mutation.RESULTS_LAB_EXAMINATION_TYPE_EDIT](state, payload) {
            let examLabType = state.examLabType.find(u => u.id === payload.id)

            if (examLabType) {
                examLabType = payload
            }
        },



        [Mutation.RESULTS_SAMPLE_BASE_SET_ALL](state, payload) {
            state.sampleBase = payload;
        },

        [Mutation.RESULTS_SAMPLE_BASE_ADD](state, payload) {
            state.sampleBase.push(payload)
        },

        [Mutation.RESULTS_SAMPLE_BASE_REMOVE](state, payload) {
            let index = state.sampleBase.findIndex(u => u.id === payload)

            if (index > -1) {
                state.sampleBase.splice(index, 1)
            }
        },

        [Mutation.RESULTS_SAMPLE_BASE_EDIT](state, payload) {
            let sampleBase = state.sampleBase.find(u => u.id === payload.id)

            if (sampleBase) {
                sampleBase = payload
            }
        },

        [Mutation.RESULTS_OTHER_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examOtherType = payload;
        },

        [Mutation.RESULTS_OTHER_EXAMINATION_TYPE_ADD](state, payload) {
            state.examOtherType.push(payload)
        },

        [Mutation.RESULTS_OTHER_EXAMINATION_TYPE_REMOVE](state, payload) {
            let index = state.examOtherType.findIndex(u => u.id === payload)

            if (index > -1) {
                state.examOtherType.splice(index, 1)
            }
        },

        [Mutation.RESULTS_OTHER_EXAMINATION_TYPE_EDIT](state, payload) {
            let examOtherType = state.examOtherType.find(u => u.id === payload.id)

            if (examOtherType) {
                examOtherType = payload
            }
        },


        [Mutation.RESULTS_IMAGE_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examImageType = payload;
        },

        //Dental
        [Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examDentalType = payload;
        },

        [Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_ADD](state, payload) {
            state.examDentalType.push(payload)
        },

        [Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_REMOVE](state, payload) {
            let index = state.examDentalType.findIndex(u => u.id === payload)

            if (index > -1) {
                state.examDentalType.splice(index, 1)
            }
        },

        [Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_EDIT](state, payload) {
            let examDentalType = state.examDentalType.find(u => u.id === payload.id)

            if (examDentalType) {
                examDentalType = payload
            }
        },

        //Psyche
        [Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examPsycheType = payload;
        },

        [Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_ADD](state, payload) {
            state.examPsycheType.push(payload)
        },

        [Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_REMOVE](state, payload) {
            let index = state.examPsycheType.findIndex(u => u.id === payload)

            if (index > -1) {
                state.examPsycheType.splice(index, 1)
            }
        },

        [Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_EDIT](state, payload) {
            let examPsycheType = state.examPsycheType.find(u => u.id === payload.id)

            if (examPsycheType) {
                examPsycheType = payload
            }
        },

        //Physio
        [Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_SET_ALL](state, payload) {
            state.examPhysioType = payload;
        },

        [Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_ADD](state, payload) {
            state.examPhysioType.push(payload)
        },

        [Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_REMOVE](state, payload) {
            let index = state.examPhysioType.findIndex(u => u.id === payload)

            if (index > -1) {
                state.examPhysioType.splice(index, 1)
            }
        },

        [Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_EDIT](state, payload) {
            let examPhysioType = state.examPhysioType.find(u => u.id === payload.id)

            if (examPhysioType) {
                examPhysioType = payload
            }
        },

    },

    actions: {


        [Action.RESULTS_UPLOAD_EXTERNAL](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();

                    payload.append('domainId', form.domainId);
                    payload.append('file', form.file);
                    payload.append('name', form.name);
                    payload.append('description', form.description);
                    payload.append('type', form.type);
                    payload.append('patientId', form.patientId);

                    axios({
                        method: 'post',
                        url: Apis.RESULTS_UPLOAD_EXTERNAL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RESULTS_ADD_EXTERNAL](context, result) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_ADD_EXTERNAL,
                        data: result,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.RESULTS_MEDICAL_PROCEDURE_ADD](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_MEDICAL_PROCEDURE_GET_ALL,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.RESULTS_MEDICAL_PROCEDURE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_MEDICAL_PROCEDURE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.RESULTS_MEDICAL_PROCEDURE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_MEDICAL_PROCEDURE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.RESULTS_MEDICAL_PROCEDURE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_MEDICAL_PROCEDURE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.RESULTS_MEDICAL_PROCEDURE_PUT](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_MEDICAL_PROCEDURE_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.RESULTS_LAB_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_LAB_EXAMINATION_TYPE_CREATE,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_LAB_EXAMINATION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_LAB_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_LAB_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_LAB_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_LAB_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_LAB_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_LAB_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_LAB_EXAMINATION_TYPE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_LAB_EXAMINATION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_LAB_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_LAB_EXAMINATION_TYPE_UPDATE.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_LAB_EXAMINATION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.RESULTS_IMAGE_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMAGE_EXAMINATION_TYPE_CREATE,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_IMAGE_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMAGE_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMAGE_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_IMAGE_EXAMINATION_TYPE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMAGE_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_IMAGE_EXAMINATION_TYPE_UPDATE.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SAMPLE_BASE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_SAMPLE_BASE_GET_ALL,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_SAMPLE_BASE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SAMPLE_BASE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_SAMPLE_BASE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_SAMPLE_BASE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SAMPLE_BASE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_SAMPLE_BASE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SAMPLE_BASE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_SAMPLE_BASE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_SAMPLE_BASE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SAMPLE_BASE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_SAMPLE_BASE_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_SAMPLE_BASE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_GET_RESULTS_BY_PATIENT](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_GET_RESULTS_BY_PATIENT.replace('{id}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_GET_FULL_RESULTS_BY_PATIENT](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_GET_FULL_RESULTS_BY_PATIENT.replace('{id}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_GET_RESULTS_BY_PATIENT_PROGRAM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_GET_RESULTS_BY_PATIENT_PROGRAM.replace('{programId}', payload.programId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_GET_RESULT_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_GET_RESULT_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_GET_RESULT_FILE_BY_ID](context, sample) {
            let fileNum = 1;

            if (sample.fileNum) {
                fileNum = sample.fileNum
            }
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.RESULTS_GET_RESULT_FILE_BY_ID.replace('{id}', sample.id).replace('{num}', fileNum),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        // [Action.RESULTS_GET_RESULT_FILE_BY_ID](context, sample) {
        //     let fileNum = 1;

        //     if (sample.fileNum) {
        //         fileNum = sample.fileNum
        //     }
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 responseType: 'blob',
        //                 url: Apis.RESULTS_GET_RESULT_FILE_BY_ID.replace('{id}', sample.id).replace('{num}', fileNum),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        [Action.RESULTS_DOWNLOAD_RESULT_FILE_BY_ID](context, sample) {
            var fileDownload = require('js-file-download');
            let fileNum = 1;

            if (sample.fileNum) {
                fileNum = sample.fileNum
            }
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.RESULTS_GET_RESULT_FILE_BY_ID.replace('{id}', sample.id).replace('{num}', fileNum),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        var fileName = sample.internalCode
                        fileDownload(response.data, fileName + '.pdf');
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_DOWNLOAD_RESULT_BY_CODE](context, sample) {
            //var fileDownload = require('js-file-download');
            let fileNum = 1;

            if (sample.fileNum) {
                fileNum = sample.fileNum
            }
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.RESULTS_DOWNLOAD_RESULT_BY_CODE.replace('{id}', sample.id).replace('{num}', fileNum),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        //var fileName = sample.internalCode
                        //fileDownload(response.data, fileName + '.pdf');
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RESULTS_OTHER_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_OTHER_EXAMINATION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_OTHER_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_OTHER_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_OTHER_EXAMINATION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_OTHER_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_OTHER_EXAMINATION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //RESULTRS_GET_DEEPZOOM_FILE
        [Action.RESULTRS_GET_DEEPZOOM_FILE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_DEEPZOOM_GET_FILE.replace('{id}/{file}', `${payload.id}/${payload.file}`),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_CYTO_HISTORY_DOMAIN_100_BY_PESEL](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_CYTO_HISTORY_DOMAIN_100_BY_PESEL.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_FILTER_RESULTS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_FILTER_BY_EXAM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_FILTER_BY_EXAM,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_USE_IN_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_USE_IN_APPOINTMENT.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload.data
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },



        //EXAMINATION TYPES - DENTAL
        [Action.RESULTS_DENTAL_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_DENTAL_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_DENTAL_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_DENTAL_EXAMINATION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //EXAMINATION TYPES - PSYCHE
        [Action.RESULTS_PSYCHE_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PSYCHE_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PSYCHE_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PSYCHE_EXAMINATION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //EXAMINATION TYPES - PHYSIO
        [Action.RESULTS_PHYSIO_EXAMINATION_TYPE_CREATE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL,
                        data: type,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PHYSIO_EXAMINATION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_PHYSIO_EXAMINATION_TYPE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RESULTS_PHYSIO_EXAMINATION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RESULTS_EDIT_EXAM_TYPE_IDS](context, result) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_EDIT_EXAM_TYPE_IDS.replace('{id}', result.id),
                        data: result.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_ADD_STATUS](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_ADD_STATUS.replace('{id}', object.id),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_REMOVE](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_REMOVE.replace('{id}', object.id),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_REMOVE](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_REMOVE.replace('{id}', object.id),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE.replace('{domain}', payload.id).replace('{type}', payload.type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_GET_BY_KLUCZ](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_GET_BY_KLUCZ.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{klucz}', payload.klucz),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_UPLOAD_P1_RESULT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_UPLOAD_P1_RESULT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{klucz}', payload.klucz),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_HTML_DOWNLOAD](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_HTML_DOWNLOAD.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_CREATE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_REMOVE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_REMOVE.replace('{id}', payload.id),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RESULTS_P1_REFERRAL_RESTORE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_RESTORE.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE_SHORT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE_SHORT.replace('{domain}', payload.domain).replace('{type}', payload.type),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_CLINIC](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_REFERRAL_GET_BY_DESTINATION_CLINIC.replace('{domain}', payload.domain).replace('{clinic}', payload.clinic),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_USED_IN_AOS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_USED_IN_AOS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_REFERRAL_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_P1_REFERRAL_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //Results ZM
        [Action.RESULTS_P1_RESULT_ZM_GET_BY_KLUCZ](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_RESULT_ZM_GET_BY_KLUCZ.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{klucz}', payload.klucz),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_RESULT_ZM_GET_BY_APP_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_RESULT_ZM_GET_BY_APP_ID.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_P1_RESULT_ZM_GET_STATUS_BY_APP_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_P1_RESULT_ZM_GET_STATUS_BY_APP_ID.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //Results Immunization
        [Action.RESULTS_IMMUNIZATION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_IMMUNIZATION_GET.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_POST,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_RESTORE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //Results Immunization QUALIFICATION
        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_EXTENDED_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_EXTENDED_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_GET_BY_APPOINTMENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_FILTER,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // QUALIFICATION

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_EXTENDED_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_EXTENDED_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_APPOINTMENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_POST,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_UPDATE.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_RESTORE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_JOIN_OBSERVATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_JOIN_OBSERVATION.replace('{id}', payload.id).replace('{observationId}', payload.observationId),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_CREATE_WITH_QUALIFICATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_CREATE_WITH_QUALIFICATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_GET_COMPLETED_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_GET_COMPLETED_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_POST_COMPLETED_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_POST_COMPLETED_BY_PATIENT,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_IMMUNIZATION_QUALIFICATION_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_IMMUNIZATION_QUALIFICATION_FILTER,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_TEST_RESULT_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_TEST_RESULT_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SOLAB_EXAM_GET_BY_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_SOLAB_EXAM_GET_BY_ID.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SOLAB_EXAM_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_SOLAB_EXAM_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_GROUPED](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_GROUPED.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_ICD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_ICD.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token },
                        data: payload.data
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_DATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_DATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }
    }
}